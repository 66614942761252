import React, { useEffect, useState, Fragment, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IntlCurrencyInput from "react-intl-currency-input"
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { LineScalePulseOut } from 'react-pure-loaders';
import { Tooltip } from '@material-ui/core';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ptBr from "date-fns/locale/pt-BR"; // the locale you want
registerLocale("pt-BR", ptBr); // register it with the name you want

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: '25ch',
  },
  avatarLg: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    boxShadow: '0px 1px 11px -1px #555',
    marginBottom: 20,
    marginTop: 20,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: 700
  },
  input: {
    backgroundColor: '#fff',
  },
  input_image: {
    display: 'none',
  },
  removeConfigInput: {
    height: '100%',
    width: '100%',
    minWidth: '100%',
    border: 'none',
    fontFamily: 'Montserrat',
    color: '#44444',
    padding: '13.5px 10px',
  },
  fieldsetInput: {
    minWidth: '100%',
    backgroundColor: '#fff',
    animationName: 'onAutoFillCancel',
    border: '1px solid #c4c4c4',
    borderRadius: '4px',
    padding: '0 8px',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    },
  },
  fieldsetCustom: {
    border: '2px solid #30bcb6 !important'
  },
  textPattern: {
    color: '#000000'
  },
  legendPattern: {
    color: '#000000',
    fontSize: '11px',
    padding: '0 30px 0 3px'
  },
  divRoot: {
    padding: 10
  },
  wrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'row',
  },
  textArea: {
    minHeight: `90%`,
    minWidth: `100%`,
    height: `95%!important`,
    width: `100%`,
    maxHeight: `100%`,
    maxWidth: `100%`,
    padding: 5,
    marginTop: theme.spacing(2),
    overflow: "inherit!important"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
    maxWidth: "100%",
  },
  valueTotal: {
    color: '#576B77',
    fontWeight: 600,
    fontSize: '16px',
    margin: 'auto'
  }
}));

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}

let schema = yup.object().shape({
  maximumResponseTime: yup.number().required(),
  publishedUntil: yup.date().required(),
  payAmount: yup.number().required(),
  numRespondents: yup.number().required(),
  totalPayAmount: yup.number().required()
});

export default function FinancialTab(props) {
  const classes = useStyles();
  const [submitting] = useState(false);
  const [research, setResearch] = useState(props.newResearchFinance);
  const [date, setDate] = useState(research?.publishedUntil);
  const [value, setValue] = useState(research?.totalPayAmount);
  const [estimatedValue, setEstimatedValue] = useState(research?.totalPayAmount - props.funds < 0
    ? research?.totalPayAmount
    : research?.totalPayAmount - props.funds)

  const loaded = useRef(false)

  useEffect(() => {
    if (!!loaded?.current === false && props.newResearchFinance) {
      setResearch(props.newResearchFinance)
      handleEstimatedValue('numResp', props.newResearchFinance.numRespondents)
      handleEstimatedValue('money', props.newResearchFinance.payAmount)
      loaded.current = true
    }
  }, [loaded, props.newResearchFinance])

  useEffect(() => {
    if (value !== undefined && value !== '') {
      let obj = { totalPayAmount: value }
      setResearch({ ...research, ...obj })
    }
  }, [value])

  useEffect(() => {
    if (date !== undefined) {
      let obj = { publishedUntil: date }
      setResearch({ ...research, ...obj })
    }
  }, [date, setResearch])

  useEffect(() => {
    handleNewResearchFinanceChange(research)
  }, [research])

  const handleChange = (event, newValue) => {
    handleNewResearchFinanceChange(research)
    props.handleChange(newValue);
  };

  const handleNewResearchFinanceChange = (research) => {
    props.handleNewResearchFinanceChange(research);
  };

  const inputChangeHandler = (event) => {
    let obj = { [event.target.name]: event.target.value }
    setResearch({ ...research, ...obj })
    if (event.target.name === 'numRespondents') {
      handleEstimatedValue('numResp', event.target.value)
    }
  };

  const inputChangeHandlerMoney = (event, value) => {
    let obj = { [event.target.name]: value }
    setResearch({ ...research, ...obj })
    handleEstimatedValue('money', value)
  };

  const handleSubmit = async (event) => {
    //Make a network call somewhere
    event.preventDefault();
    const data = {
      maximumResponseTime: research?.maximumResponseTime,
      publishedUntil: research?.publishedUntil,
      payAmount: research?.payAmount,
      numRespondents: research?.numRespondents,
      totalPayAmount: research?.totalPayAmount
    }
    const isValidForm = await schema.isValid(data)
    if (isValidForm) {
      props.handleCanPublishTabChange()
      handleChange(event, 2)
    } else {
      toast.error('Campos não podem ser vazios!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const handleFocus = (event) => {
    event.preventDefault();
    event.target.parentElement.parentElement.parentElement.classList.add(classes.fieldsetCustom)
  }

  const handleBlur = (event) => {
    event.preventDefault();
    event.target.parentElement.parentElement.parentElement.classList.remove(classes.fieldsetCustom)
  }

  const handleFocusMoney = (event) => {
    event.preventDefault();
    event.target.parentElement.classList.add(classes.fieldsetCustom)
  }

  const handleBlurMoney = (event) => {
    event.preventDefault();
    event.target.parentElement.classList.remove(classes.fieldsetCustom)
  }


  const roundUpValue = (value, decimalpoint) => {
    var result = parseFloat(value.toFixed(2))
    if (result < value) {
      return value + Math.pow(10, -decimalpoint)
    }
    return result
  }

  const handleEstimatedValue = (type, valueParam) => {
    let value = 0;
    if (type === 'numResp') {
      value = parseFloat((parseFloat(research.payAmount || 0) * parseFloat(valueParam)) + ((parseFloat(research.payAmount || 0) * parseFloat(valueParam)) * 0.4))
    } else if (type === 'money') {
      value = parseFloat((parseFloat(valueParam) * parseFloat(research.numRespondents || 0)) + ((parseFloat(valueParam) * parseFloat(research.numRespondents || 0)) * 0.4))
    }
    value = roundUpValue(value, 3)
    setValue(value)

    let valorEstimado = value - props.funds;
    valorEstimado = valorEstimado < 0 ? value : valorEstimado;
    setEstimatedValue(valorEstimado)
  }

  const handleDate = (date) => {
    setDate(date)
    document.getElementById("fieldsetDate").classList.remove(classes.fieldsetCustom)
  }

  return (
    <Grid container>
      <form onSubmit={handleSubmit} style={{ minWidth: '100%' }}>
        <Grid item xs={12} md={12} lg={12} className={`cardFormCustomRadius paddingTabs`}>
          <Grid container spacing={3} style={{ alignItems: "center" }}>
            <Grid item xs={12} md={12} lg={12} sm={12} >
              <Tooltip title="Tempo máximo que um respondente tem para completar a pesquisa. Seja generoso para que os respondentes tenham tempo suficiente para realizar a tarefa." placement="top-end">
                <TextField
                  className={classes.input}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={research?.maximumResponseTime}
                  id="maximumResponseTime"
                  label={<Typography className={classes.textPattern}>Tempo estimado(minuto(s))</Typography>}
                  name="maximumResponseTime"
                  type='number'
                  inputProps={{
                    min: 1,
                  }}
                  onChange={inputChangeHandler}
                />
              </Tooltip>
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ alignItems: "center", marginTop: '9.6px' }}>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <Tooltip title="Defina o prazo em que sua pesquisa estará ativa para os respondentes." placement="top-end">
                <fieldset id="fieldsetDate" className={classes.fieldsetInput}>
                  <legend className={classes.legendPattern}>Disponível até</legend>
                  <DatePicker
                    className={classes.removeConfigInput}
                    selected={date}
                    locale="pt-BR"
                    dateFormat="dd/MM/yyyy"
                    popperPlacement="top-start"
                    onFocus={(e) => handleFocus(e)}
                    onBlur={(e) => handleBlur(e)}
                    isClearable
                    minDate={new Date()}
                    defaultValue={research?.publishedUntil}
                    onChange={(date) => handleDate(date)}
                  />
                </fieldset>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ alignItems: "center", marginTop: '9.6px' }}>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <Tooltip title="Defina qual o valor que você pagará por cada questionário obtido com sucesso." placement="top-end">
                <fieldset className={classes.fieldsetInput}>
                  <legend className={classes.legendPattern}>Recompensa - R$</legend>
                  <IntlCurrencyInput
                    className={classes.removeConfigInput}
                    currency="BRL"
                    config={currencyConfig}
                    value={research?.payAmount}
                    onFocus={(e) => handleFocusMoney(e)}
                    onBlur={(e) => handleBlurMoney(e)}
                    id="payAmount"
                    name="payAmount"
                    onChange={inputChangeHandlerMoney}
                  />
                </fieldset>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ alignItems: "center" }}>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <Tooltip title="Diga o número mínimo de respondentes que vc precisa para sua pesquisa." placement="top-end">
                <TextField
                  autoComplete='off'
                  className={classes.input}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={research?.numRespondents}
                  id="numRespondents"
                  label={<Typography className={classes.textPattern}>Respondentes</Typography>}
                  name="numRespondents"
                  type='number'
                  onKeyDown={(evt) => ['+', '-', '.', 'e'].includes(evt.key) && evt.preventDefault()}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }}
                  onChange={inputChangeHandler}
                />
              </Tooltip>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container direction="row" justify="center" alignItems="center" spacing={3} xs={12} md={12} lg={12} sm={12} style={{ margin: "32px 0" }}>
              <Typography component="h3" className={classes.valueTotal} >
                Valor total estimado: {isNaN(value) ? '' : new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(research?.totalPayAmount)}
              </Typography>
            </Grid>

            {props.funds && props.funds > 0 && <Fragment><Grid container direction="row" justify="center" alignItems="center" spacing={3} xs={12} md={12} lg={12} sm={12} style={{ margin: "0 0 15px 0" }}>
              <Typography component="h3" className={classes.valueTotal} >
                Saldo disponíveis em carteira: {isNaN(props.funds) ? '' : new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(props.funds)}
              </Typography>
            </Grid>

              <Grid container direction="row" justify="center" alignItems="center" spacing={3} xs={12} md={12} lg={12} sm={12} style={{ margin: "0 0 32px 0" }}>
                <Typography component="h3" className={classes.valueTotal} >
                  Total a pagar: {isNaN(estimatedValue) ? '' : new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(estimatedValue)}
                </Typography>
              </Grid>
            </Fragment>}
          </Grid>
        </Grid>


        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          xs={12} md={12} lg={12} sm={12}
          className={'footerButtonsTabs'}
        >
          <Grid item >
            <Button
              type="button"
              size='large'
              color="secondary"
              className={'buttonCancel'}
              onClick={(event) => handleChange(event, 0)}
              startIcon={<NavigateBeforeIcon />}
            >
              Voltar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              size='large'
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              endIcon={<NavigateNextIcon />}
            >
              {submitting ? (
                <LineScalePulseOut
                  color={'#BA7100'}
                  loading={submitting}
                />
              ) : (
                "Revisar"
              )}
            </Button>

          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}
