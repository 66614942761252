import React, { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import logoBrand from '../../../assets/logo-text-color.png';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import { Grid, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { headerReasearch, headerResponser } from '../../../utils/utils';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../../providers/UserProvider';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  logo: {
    width: '150px',
    margin: 'auto'
  },
  linkHeader: {
    color: "#111640 !important",
    fontWeight: 300,
    padding: 5
  },
});


export default function Burguer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const location = useLocation();
  const { userState } = useContext(UserContext);
  const user = userState

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <Box component="div" display='flex' >
          <img src={logoBrand} className={classes.logo} alt="Sistema de Pesquisa" />
        </Box>
      </List>
      <Divider />
      <List>
        {user?.isResearcher ? <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <ListItem>
            <Link to="/dashboard/ALL" className={`${classes.linkHeader} ${headerReasearch.includes(location.pathname) && location.pathname === '/dashboard/ALL' ? 'activated' : ''}`} >
              PESQUISAS
            </Link>
          </ListItem>

          <ListItem>
            <Link to="/financial-info" className={`${classes.linkHeader} ${headerReasearch.includes(location.pathname) && location.pathname === '/financial-info' ? 'activated' : ''}`} >
              FINANCEIRO
            </Link>
          </ListItem>
        </Grid> :
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <ListItem>
              <Link to="/dashboard/PUBLISHED" className={`${classes.linkHeader} ${headerResponser.includes(location.pathname) && location.pathname === '/dashboard/PUBLISHED' ? 'activated' : ''}`} >
                PUBLICADAS
              </Link>
            </ListItem>

            <ListItem>
              <Link to="/dashboard/ALL" className={`${classes.linkHeader} ${headerResponser.includes(location.pathname) && location.pathname === '/dashboard/ALL' ? 'activated' : ''}`} >
                RESPONDIDAS
              </Link>
            </ListItem>

            <ListItem>
              <Link to="/financial-info" className={`${classes.linkHeader} ${headerResponser.includes(location.pathname) && location.pathname === '/financial-info' ? 'activated' : ''}`} >
                FINANCEIRO
              </Link>
            </ListItem>
          </Grid>}
      </List>
    </div>
  );

  return (
    <React.Fragment key={'left'}>
      <IconButton onClick={toggleDrawer('left', true)} >
        <MenuIcon />
      </IconButton>
      <Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>
        {list('left')}
      </Drawer>
    </React.Fragment>
  );
}
