import axios from "axios";
import { getAccesstoken } from "../utils/token";
import { toast } from 'react-toastify';

export const apiAuthURL = axios.create(
  {
    baseURL: process.env.REACT_APP_BACK_AUTH,
  });


export const apiResearch = axios.create(
  {
    baseURL: process.env.REACT_APP_BACK_RESEARCH_SERVICE,
  });

export const apiResponse = axios.create(
  {
    baseURL: process.env.REACT_APP_BACK_RESPONSE_SERVICE
  });

// declare a request interceptor
apiResponse.interceptors.request.use(async config => {
  // perform a task before the request is sent
  let token = await getAccesstoken()
  // const options = {
  //     headers: { Authorization: `Bearer ${token}`}
  // };
  if (token) {
    config.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return config;
}, error => {
  // handle the error
  return Promise.reject(error);
});


apiResponse.interceptors.response.use(function (response) {

  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  const { response } = error

  if (response?.data?.status === 400 && response?.data?.message) {
    const message = response.data.message

    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  }

  return Promise.reject(error);
});

// declare a request interceptor
apiResearch.interceptors.request.use(async config => {
  // perform a task before the request is sent
  let token = await getAccesstoken()
  // const options = {
  //     headers: { Authorization: `Bearer ${token}`}
  // };
  if (token) {
    config.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return config;
}, error => {

  // handle the error
  return Promise.reject(error);
});


apiResearch.interceptors.response.use(function (response) {

  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  const { response } = error

  if (response?.data?.status === 400 && response?.data?.message) {
    const message = response.data.message

    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  }

  return Promise.reject(error);
});

apiAuthURL.interceptors.request.use(async config => {
  // perform a task before the request is sent
  let token = await getAccesstoken()
  // const options = {
  //     headers: { Authorization: `Bearer ${token}`}
  // };
  if (token) {
    config.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return config;
}, error => {
  // handle the error
  return Promise.reject(error);
});


apiAuthURL.interceptors.response.use(async function (response) {

  return await response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});