import { createMuiTheme } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import palette from './palette';
import typography from './typography';

const theme = createMuiTheme({
  typography,
  palette
}, ptBR);

export default theme;
