import React, { useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { LineScalePulseOut } from 'react-pure-loaders';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import TextField from '@material-ui/core/TextField';
import { Tooltip } from '@material-ui/core';
import * as yup from 'yup';
import Select from '@material-ui/core/Select';
import { insertTransaction } from '../../../services/research';
import IntlCurrencyInput from "react-intl-currency-input"

let schema = yup.object().shape({
  documento: yup.string().required(),
  nome: yup.string().required(),
  numero: yup.number().required(),
  codigo: yup.number().required(),
  venc_ano: yup.number().required(),
  venc_mes: yup.number().required(),
  parcelas: yup.number().required(),
});

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: 600,
    color: '#111640',
    marginTop: '1em',
    marginBottom: '1em',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    alignSelf: 'center',
    width: '100%'
  },
  alertCustom: {
    minWidth: '100%',
    marginBottom: '35px',
    textAlign: 'justify',

    '& .MuiAlert-message': {
      fontSize: '14px',
      fontWeight: 500
    },
    '& .MuiAlert-icon': {
      margin: 'auto 10px auto 0'
    }
  },
  button: {
    margin: '0 auto',
    fontWeight: 600,
    width: 350
  },
  textPattern: {
    color: '#576B77',
    fontSize: '16px',
    maxWidth: '100%',
    wordWrap: 'break-word',
  },
  fieldsetInput: {
    minWidth: '100%',
    backgroundColor: '#fff',
    animationName: 'onAutoFillCancel',
    border: '1px solid #c4c4c4',
    borderRadius: '4px',
    padding: '0 8px',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    },
    margin: '15px 0'
  },
  fieldsetCustom: {
    border: '2px solid #30bcb6 !important'
  },
  removeConfigInput: {
    height: '100%',
    width: '100%',
    minWidth: '100%',
    border: 'none',
    fontFamily: 'Montserrat',
    color: '#44444',
    padding: '13.5px 10px',
  },
  legendPattern: {
    color: '#576B77',
    fontSize: '15px',
    padding: '0 30px 0 3px',
    fontWeight: 400,
  },
}));

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}


export default function CreditCardTab(props) {
  const classes = useStyles();

  const inputChangeHandler = (event, value) => {
    let obj = { [event.target.name]: value ? value : event.target.value }
    setPayment({ ...payment, ...obj })
  };

  const [submitting, setSubmitting] = useState(false)
  const [payment, setPayment] = useState({
    documento: '',
    nome: '',
    numero: '',
    codigo: '',
    venc_mes: '',
    venc_ano: '',
    valor_total: '',
    parcelas: null,
  })
  const history = useHistory();
  const meses = [
    { key: "01", label: "Janeiro" },
    { key: "02", label: 'Fevereio' },
    { key: "03", label: 'Março' },
    { key: "04", label: 'Abril' },
    { key: "05", label: 'Maio' },
    { key: "06", label: 'Junho' },
    { key: "07", label: 'Julho' },
    { key: "08", label: 'Agosto' },
    { key: "09", label: 'Setembro' },
    { key: "10", label: 'Outubro' },
    { key: "11", label: 'Novembro' },
    { key: "12", label: 'Dezembro' }
  ]

  const years = () => {
    var currentYear = new Date().getFullYear(), years = [];
    const endYear = currentYear + 50;
    while (currentYear <= endYear) {
      const ano = currentYear++;
      years.push({ key: ano, label: ano });
    }
    return years;
  }
  const anos = years()

  const [parcelas, setParcelas] = useState([])

  const calculaParcelamento = (e) => {

    if (e.target.value) {
      setParcelas([{
        key: '1',
        label: `1X de ${e.target.value}`
      }])
    }
    else {
      setParcelas([])
    }

    setPayment({
      ...payment,
      valor_total: e.target.value,
      parcelas: e.target.value ? 1 : 0
    })

  }

  const handlePayment = async (event) => {
    event.preventDefault();
    setSubmitting(true)

    const isValidForm = await schema.isValid(payment)
    if (!isValidForm) {
      setSubmitting(false)
      return toast.error('Campos não podem ser vazios!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    // const creditos = payment.valor_total.toLocaleString('en', { minimumFractionDigits: 2 })
    const creditos = payment.valor_total.replace('.', '').replace(',', '.')

    const transaction = {
      amount: parseFloat(creditos),
      name: payment.nome,
      document: payment.documento,
      cardNumber: payment.numero,
      expirationDate: `${payment.venc_mes}/${payment.venc_ano}`,
      securityCode: payment.codigo,
    }

    insertTransaction(transaction)
      .then(result => {

        toast.success('Créditos inseridos com sucesso', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })

        history.push(`/dashboard/ALL`);
      })
      .catch(error => {
        toast.error(error.response?.data?.message
          || 'Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSubmitting(false)
      })
  }

  return (

    <Fragment>

      <Grid container>

        <Grid item xs={12} md={12} lg={12} className={`cardFormCustomRadius paddingTabsCustom`} >

          <Typography component="h3" className={classes.title} color="secondary" >
            Inserir Créditos
          </Typography>

          <form style={{ minWidth: '100%' }}>
            <Grid item xs={12} md={12} lg={12} className={`cardFormCustomRadius paddingTabs`} style={{ border: 'none' }}>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>

                  <Grid item xs={12}>
                    <Tooltip title="Informe o documento do titular do cartão" placement="top-end">
                      <TextField
                        autoFocus
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        inputProps={{ maxLength: 180 }}
                        required
                        fullWidth
                        value={payment?.documento}
                        id="documento"
                        label={<Typography className={classes.textPattern}>CPF / CNPJ</Typography>}
                        name="documento"
                        onChange={inputChangeHandler}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid item xs={12}>
                    <Tooltip title="Informe o nome do titular do cartão" placement="top-end">
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        inputProps={{ maxLength: 180 }}
                        required
                        fullWidth
                        value={payment?.nome}
                        id="nome"
                        label={<Typography className={classes.textPattern}>Nome do Titular do Cartão</Typography>}
                        name="nome"
                        onChange={inputChangeHandler}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid item xs={12}>
                    <Tooltip title="Informe o número do cartão" placement="top-end">
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        inputProps={{ maxLength: 16 }}
                        required
                        fullWidth
                        value={payment?.numero}
                        id="numero"
                        label={<Typography className={classes.textPattern}>Número do Cartão</Typography>}
                        name="numero"
                        onChange={inputChangeHandler}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid item xs={6}>
                    <Tooltip title="Informe o código de segurança" placement="top-end">
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        inputProps={{ maxLength: 3 }}
                        required
                        fullWidth
                        value={payment?.codigo}
                        id="codigo"
                        label={<Typography className={classes.textPattern}>Código de Segurança</Typography>}
                        name="codigo"
                        onChange={inputChangeHandler}
                      />
                    </Tooltip>
                  </Grid>

                  <Typography component="h3" className={classes.textPattern} >
                    Vencimento
                  </Typography>

                  <Grid container>
                    <Grid item xs={6}>
                      <Select
                        native
                        className={classes.input}
                        name="venc_mes"
                        id="venc_mes"
                        variant="outlined"
                        value={payment?.venc_mes}
                        onChange={inputChangeHandler}
                        fullWidth
                      >
                        <option key={0} value={0}>Mês</option>
                        {meses.map(mes => (
                          <option key={mes.key} value={mes.key}>{mes.label}</option>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: 20 }}>
                      <Select
                        className={classes.input}
                        native
                        name="venc_ano"
                        id="venc_ano"
                        variant="outlined"
                        value={payment?.venc_ano}
                        onChange={inputChangeHandler}
                        fullWidth
                      >
                        <option key={0} value={0}>Ano</option>
                        {anos.map(ano => (
                          <option key={ano.key} value={ano.key}>{ano.label}</option>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>

                  <fieldset className={classes.fieldsetInput}>
                    <legend className={classes.legendPattern}>Valor</legend>
                    <IntlCurrencyInput
                      className={classes.removeConfigInput}
                      currency="BRL"
                      config={currencyConfig}
                      value={payment.valor_total}
                      id="valor_total"
                      name="valor_total"
                      onChange={inputChangeHandler}
                      onBlur={(e) => calculaParcelamento(e)}
                    />
                  </fieldset>

                  <Typography component="h3" className={classes.textPattern} >
                    Quantidade de Parcelas
                  </Typography>

                  <Grid item xs={12} style={{ marginBottom: '25px' }}>
                    <Select
                      disabled={parcelas.length > 0 ? false : true}
                      native
                      className={classes.input}
                      name="parcelas"
                      id="parcelas"
                      variant="outlined"
                      value={payment?.venc_mes}
                      onChange={inputChangeHandler}
                      fullWidth
                    >
                      {parcelas.map(mes => (
                        <option key={mes.key} value={mes.key}>{mes.label}</option>
                      ))}
                    </Select>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={12} >
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    type="submit"
                    size='large'
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={(event) => { handlePayment(event) }}
                    className={classes.submit}
                  >
                    {submitting ? (
                      <LineScalePulseOut
                        color={'#BA7100'}
                        loading={submitting}
                      />
                    ) : (
                      "Confirmar"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

          </form>

        </Grid>
      </Grid>

    </Fragment>
  )
}