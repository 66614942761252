import React, { useCallback, useContext, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'
import { Checkbox, Tooltip } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { UserContext } from '../../../../providers/UserProvider'
import {
  getAllResearchesPublished,
  getAllResearchesNotExcluded
} from '../../../../services/research'
import { getAllResponses } from '../../../../services/response'
import { toast } from 'react-toastify'

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 700
  },
  container: {
    display: 'flex',
    padding: theme.spacing(0, 1, 0)
  },
  labelContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    padding: theme.spacing(0, 0.5, 0)
  },
  iconLabel: {
    backgroundColor: '#30B6BC',
    borderRadius: 4,
    marginRight: 5,
    width: 16,
    height: 16,
    fontSize: '1rem'
  },
  iconPendingLabel: {
    backgroundColor: '#515BA0'
  },
  iconStoppedLabel: {
    backgroundColor: '#FFB743'
  },
  iconFinishedLabel: {
    backgroundColor: '#199D65'
  },
  iconRespPendingLabel: {
    backgroundColor: '#8088BA'
  },
  iconRefusedLabel: {
    backgroundColor: '#D16354'
  },
  iconStartedLabel: {
    backgroundColor: '#A3A4A5'
  },
  checkboxNoPadding: {
    padding: 0,
    color: '#fafafa !important'
  },
  checkIcon: {
    fontSize: '1rem',
    fontWeight: 600
  }
}))

export default function Label({
  handleChangeItems,
  items,
  status,
  arrayStatus = [],
  filterProps = {}
}) {
  const classes = useStyles()
  const [filter, setFilter] = useState(filterProps)
  const [itemsLabel, setItemsLabel] = useState(items)
  const { userState } = useContext(UserContext)

  const loadItems = async () => {
    if (userState?.isResearcher) {
      await getAllResearchesNotExcluded()
        .then(async resp => {
          setItemsLabel(resp.data.content)
        })
        .catch(error => {
          if (error.response?.data?.status !== 400) {
            toast.error(
              'Algo inesperado ocorreu. Por favor, tente atualizar a página em alguns minutos.!',
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              }
            )
          }
        })
    } else {
      if (status === 'ALL') {
        await getAllResponses()
          .then(async resp => {
            setItemsLabel(resp.data.content)
          })
          .catch(error => {
            if (error.response?.data?.status !== 400) {
              toast.error(
                'Algo inesperado ocorreu. Por favor, tente atualizar a página em alguns minutos.!',
                {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
                }
              )
            }
          })
      } else {
        if (status === 'PUBLISHED') {
          await getAllResearchesPublished()
            .then(async resp => {
              setItemsLabel(resp.data.content)
            })
            .catch(error => {
              if (error.response?.data?.status !== 400) {
                toast.error(
                  'Algo inesperado ocorreu. Por favor, tente atualizar a página em alguns minutos.!',
                  {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                  }
                )
              }
            })
        }
      }
    }
  }

  useEffect(() => {
    async function loadItemsFilter() {
      await loadItems()

      if (userState.isResearcher) {
        const itemsFilter = itemsLabel.filter(item => {
          return (
            item.status === handleCheckFilter(filter.PUBLISHED, 'PUBLISHED') ||
            item.status === handleCheckFilter(filter.CREATED, 'CREATED') ||
            item.status === handleCheckFilter(filter.STOPPED, 'STOPPED') ||
            item.status === handleCheckFilter(filter.FINISHED, 'FINISHED')
          )
        })
        await handleChangeItems(itemsFilter)
      } else {
        const itemsFilter = itemsLabel.filter(item => {
          return (
            item.responseStatus ===
            handleCheckFilter(filter.PENDING, 'PENDING') ||
            item.responseStatus ===
            handleCheckFilter(filter.APPROVED, 'APPROVED') ||
            item.responseStatus ===
            handleCheckFilter(filter.REFUSED, 'REFUSED') ||
            item.responseStatus === handleCheckFilter(filter.STARTED, 'STARTED')
          )
        })
        handleChangeItems(itemsFilter)
      }
    }
    loadItemsFilter()
  }, [filter])

  const getCheckStatus = statusType => {
    if (statusType === 'PUBLISHED') {
      return filter.PUBLISHED
    }
    if (statusType === 'CREATED') {
      return filter.CREATED
    }
    if (statusType === 'STOPPED') {
      return filter.STOPPED
    }
    if (statusType === 'FINISHED') {
      return filter.FINISHED
    }
    if (statusType === 'PENDING') {
      return filter.PENDING
    }
    if (statusType === 'STARTED') {
      return filter.STARTED
    }
    if (statusType === 'APPROVED') {
      return filter.APPROVED
    }
    if (statusType === 'REFUSED') {
      return filter.REFUSED
    }

    return
  }

  const handleCheckFilter = (check, name) => {
    if (check) {
      return name
    } else {
      return ''
    }
  }

  const handleChange = event => {
    setFilter({ ...filter, [event.target.name]: event.target.checked })
  }

  return (
    <div className={classes.container}>
      {arrayStatus.map((status, index) => {
        return (
          <Tooltip key={index} title={status.title} placement='top'>
            <div className={classes.labelContainer}>
              <div>
                <Checkbox
                  className={classes.checkboxNoPadding}
                  icon={
                    <span
                      className={
                        userState.isResearcher
                          ? `${classes.iconLabel} ${status.type === 'CREATED'
                            ? classes.iconPendingLabel
                            : status.type === 'STOPPED'
                              ? classes.iconStoppedLabel
                              : status.type === 'FINISHED'
                                ? classes.iconFinishedLabel
                                : ''
                          }`
                          : `${classes.iconLabel} ${status.type === 'PENDING'
                            ? classes.iconRespPendingLabel
                            : status.type === 'APPROVED'
                              ? classes.iconFinishedLabel
                              : status.type === 'REFUSED'
                                ? classes.iconRefusedLabel
                                : status.type === 'STARTED'
                                  ? classes.iconStartedLabel
                                  : ''
                          }`
                      }
                    />
                  }
                  checkedIcon={
                    <span
                      className={
                        userState.isResearcher
                          ? `${classes.iconLabel} ${status.type === 'CREATED'
                            ? classes.iconPendingLabel
                            : status.type === 'STOPPED'
                              ? classes.iconStoppedLabel
                              : status.type === 'FINISHED'
                                ? classes.iconFinishedLabel
                                : ''
                          }`
                          : `${classes.iconLabel} ${status.type === 'PENDING'
                            ? classes.iconRespPendingLabel
                            : status.type === 'APPROVED'
                              ? classes.iconFinishedLabel
                              : status.type === 'REFUSED'
                                ? classes.iconRefusedLabel
                                : status.type === 'STARTED'
                                  ? classes.iconStartedLabel
                                  : ''
                          }`
                      }
                    >
                      <CheckIcon className={classes.checkIcon} />
                    </span>
                  }
                  checked={getCheckStatus(status.type)}
                  onChange={handleChange}
                  name={status.type}
                />
              </div>
              <Typography
                variant='subtitle1'
                component='h5'
                color='textSecondary'
                gutterBottom
                className={classes.root}
              >
                {status.text}
              </Typography>
            </div>
          </Tooltip>
        )
      })}
    </div>
  )
}
