import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GoogleImg from '../../assets/google_48px.png'
import {Avatar} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { firebaseAuth,googleAuthProvider } from "../../utils/firebase";

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText('#FFFFFF'),
        backgroundColor: '#FFFFFF',
        '&:hover': {
        backgroundColor: '#ffffff4d',
        },
        height: '2.65rem',
    },
    googleIcon:{
        height:'30px',
        width:'30px' 
    }
}));

export default function ButtonGoogle() {

    const classes = useStyles();
    
    const handleGoogle = () => {
        firebaseAuth.signInWithRedirect(googleAuthProvider);
    }
    return (
        <Button className={classes.root} size='large' 
            fullWidth 
            variant="contained"
            startIcon={<Avatar className={classes.googleIcon} alt="google" src={GoogleImg} />}
            onClick={handleGoogle} >
                Entrar com Google
        </Button>
    );
}
