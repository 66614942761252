import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid,Link } from '@material-ui/core';
import Title from '../Title';
import BlockIcon from '@material-ui/icons/Block';


Warning403.defaultProps = {
    title: 'Acesso negado!! Parece que você não tem acesso a esta área. ',
    message: 'Acesse ou crie um cadastro para acessar a plataforma.'
}

const useStyles = makeStyles(theme => ({
	message: {
       textAlign: 'flex-start'
    },
    text:{
        fontSize:'2rem!important',
        fontWeight:'500!important'
    },
    subText:{
        color:'#707070',
        fontSize:theme.spacing(2.3),
    },
    iconBlock:{
        fontSize:'4rem',
        color:'#02afb8'
    },
    setMargin:{
        margin:'20px 20px'
    },
    link:{
        textDecoration: 'underline'
    }
}));

export default function Warning403(props) {
	const classes = useStyles();
    let auth = (window.localStorage.getItem('isLogged') ? (JSON.parse(window.localStorage.getItem('isLogged')) ) : (false) );

    return !auth ? (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.setMargin}
        >
            <div> <BlockIcon className={`${classes.iconBlock} ${classes.setMargin}`} /> </div>
            <div className={classes.message}>
                <Title classes={`${classes.text} ${classes.setMargin}`} children={props.title} />
               
                <Typography className={`${classes.subText} ${classes.setMargin}`} >
                    {props.message}
                </Typography>

                <Link href="/">
                    <Typography component="h2" variant="subtitle1" className={`${classes.link} ${classes.setMargin}`}>
                        Ir para tela de acesso
                    </Typography>
                </Link>
            </div>
            
        </Grid>
    ) : ''
}