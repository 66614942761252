import React,{useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: `45%`,
    height: `70%`,
    maxHeight: `75%`,
    minHeight: `60%`,
    
  },
  paperMobile: {
    width: `100%`,
    height: `80%`,
    maxHeight: `100%`,
    minHeight: `60%`,
    
  },
  text:{
    fontWeight: 400,
    fontSize: 16
  },
  textArea:{
    minHeight: `90%`,
    minWidth: `100%`,
    height: `95%!important`,
    width: `100%`,
    maxHeight: `100%`,
    maxWidth: `100%`,
    padding: 5,
    marginTop: theme.spacing(2),
    overflow: "inherit!important"
  },
  divButtons:{
    marginTop: `${theme.spacing(2)}px`,
    marginBottom: `${theme.spacing(4)}px`,
    textAlign:"end",
    marginRight: `${theme.spacing(4)}px`
  }, 
  buttonText:{
    fontWeight: 700,
    fontSize: '1.3rem'
  }
}));

export default function ModalBlock(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const matches = useMediaQuery('(max-width:600px)');
  useEffect(() => {
    setOpen(true);
  },[])
  
  const handleClose = (event) => {
    setOpen(false);
    props.disSelected(event)
  };

  const handleSendMessage = (event) => {
    props.sendAction(event)
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style:{ backgroundColor: `rgb(230 218 218 / 62%)`}
        }}
      >
        <Fade in={open}>
          <div className={`${classes.paper} ${matches ? (`${classes.paperMobile}`) : '' } }`}>
            <div>
                <Typography gutterBottom variant="subtitle1" component="span" className={classes.text}>
                Justifique para o respondente por que ele está sendo bloqueado na pesquisa:
                </Typography>
            </div> 
            <div style={{height: `70%`}}>
                <TextareaAutosize
                    aria-label="Instruções"
                    placeholder="Instruções:"
                    className={classes.textArea}
                    />    
            </div> 
            <div className={classes.divButtons}>
                <Button
                    type="button"
                    size='large'
                    className={'buttonCancel'}
                    onClick={handleClose}
                    
                    >
                    
                        Cancelar
                    
                    
                </Button>

                <Button
                type="button"
                size='large'
                variant="contained"
                color="primary"
                className={classes.buttonText}
                onClick={handleSendMessage}
                
                >
                
                    Enviar
                
                
                </Button>
            </div> 
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
