import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box, Paper, AppBar, Tab, Tabs } from '@material-ui/core';
import PaymentTab from './components/reports/payment';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography component={'div'} >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,

};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,

  };
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: 600,
    color: '#111640',
    marginBottom: '1em'
  },
  alertCustom: {
    minWidth: '100%',
    marginBottom: '35px',
    textAlign: 'justify',

    '& .MuiAlert-message': {
      fontSize: '14px',
      fontWeight: 500
    },
    '& .MuiAlert-icon': {
      margin: 'auto 10px auto 0'
    }
  },
  button: {
    margin: '0 auto',
    fontWeight: 600,
    width: 350
  }
}));

export default function AdminInfoPage(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {

  }, [])

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      xs={12} md={12} lg={12} sm={12}
    >
      <Box component="div" className={'relatorioContent'}>
        <Grid direction="row" justify="center" alignItems="center" xs={12} md={12} lg={12} sm={12}>
          <Paper elevate={6} style={{ boxShadow: 'none', maringBottom: '16px', borderRadius: '10px', backgroundColor: 'transparent' }}>
            <AppBar className={'borderAppBar'} style={{ backgroundColor: '#FFFFFF', boxShadow: 'none', padding: '16px 32px 0 32px' }} position="static">
              <Tabs value={value} onChange={handleChange} variant='scrollable' aria-label="profile tabs">
                <Tab label="Pagamento" {...a11yProps(0)} />
              </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
              <PaymentTab />
            </TabPanel>
          </Paper>
        </Grid>
      </Box>
    </Grid>
  );
}
