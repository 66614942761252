import React from 'react';
import {Doughnut} from 'react-chartjs-2';

const state = {
  labels: ['Feito','Não-feito'],
  datasets: [
    {
      label: 'Qty',
      fill: true,
      lineTension: 0.1,
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
      ],
      borderWidth: 2,
      width: 5,
      data: [60, 40]
    }
  ]
}
const a = 50;
const b = 50;


export default function Graph() {
  
    return (
      <div>
        <Doughnut
          data={state}
          width={300}
          height={300}
          options={{
            backgroundColor: 'rgba(75,192,192,1)',
            scales: {
              yAxes: [
                {
                  gridLines: {
                    color: '#aaa',
                    borderDash: [1, 3],
                    display: false
                  },
                  display: false, // this will hide vertical lines
                },
              ],
              xAxes: [
                {
                  gridLines: {
                    color: '#aaa',
                    borderDash: [1, 3],
                    display: false
                  },
                  display: false, // this will hide vertical lines
                },
              ],
            },
            maintainAspectRatio: false,
            title:{
              display:true,
              text:`Total de respondentes: ${a} de ${a + b}`,
              fontSize:20
            },
            legend:{
              display:false,
              position:'top'
            }
          }}
        />
      </div>
    );
 
}