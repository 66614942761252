

export const headerReasearch = [
    `/dashboard/ALL`,
    `/financial-info`,
    `/admin-info` 
]  

export const headerResponser = [
    `/dashboard/ALL`,
    `/dashboard/PUBLISHED`,
    `/financial-info`,
    `/admin-info` 
]  