import React, { useState, useContext, useEffect } from 'react'
// import {
//   handleFirebaseSignOut,
//   updateUserDocument
// } from '../../../../utils/firebase'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { LineScalePulseOut } from 'react-pure-loaders'
import logo from '../../../../assets/logo-text-color.png'
import Divider from '@material-ui/core/Divider'
import ButtonFacebook from '../../../../components/ButtonFacebook'
import ButtonGoogle from '../../../../components/ButtonGoogle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { UserContext } from '../../../../providers/UserProvider'
import { handleFirebaseSignIn } from '../../../../utils/firebase'
import { myProfilePhoto, signInUser } from '../../../../services/user'
import { toast } from 'react-toastify'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '60%'
  },
  paper: {
    backgroundColor: '#E6E6E6',
    height: 'inherit'
  },
  form: {
    width: '100%',
    padding: theme.spacing(2)
  },
  paperMobile: {
    width: '100%',
    padding: theme.spacing(2),
    height: 'inherit'
  },
  input: {
    backgroundColor: '#fff'
  },
  error: {
    marginTop: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  divider: {
    marginBottom: 10
  },
  textPattern: {
    color: '#000000'
  },
  footerPaper: {
    marginBottom: theme.spacing(3)
  }
}))

export default function SignIn(props) {
  const matches = useMediaQuery('(max-width:600px)')
  const classes = useStyles()
  const history = useHistory()

  const { login, setIsLoading, setPicture } = useContext(UserContext)
  const [state, setState] = useState({ checkedA: false })
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [submitting, setSubmitting] = useState(false)

  async function rememberMe() {
    if (state.checkedA === true) {
      if (window.PasswordCredential) {
        const cred = new window.PasswordCredential({
          id: email,
          email: email,
          password: password,
          checkedA: state.checkedA
        })

        await navigator.credentials.store(cred)
        return navigator.credentials.store(cred)
      } else {
        toast.info(
          'Este navegador não possui suporte para salvar suas credenciais.',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          }
        )
      }
    }
  }

  const handleLogin = async (event, email, password) => {
    event.preventDefault()
    setSubmitting(true)
    rememberMe()
    setIsLoading(true)

    let user = {
      login: email,
      password: password
    }
    await signInUser(user)
      .then(async resp => {
        if (resp.status === 200) {
          let userObjEasywork = {
            name: resp.data.name,
            lastName: resp.data.lastName,
            email: resp.data.email,
            photoURL: resp.data.photoURL,
            isResearcher: resp.data.roles.some(x => x.name.includes('RESEARCHER')),
            id: resp.data.id,
            accessToken: resp.data.token,
            viewModeRow: true,
            administrator: resp.data.roles.some(x => x.name.includes('ADMIN'))
          }
          setSubmitting(false)
          setIsLoading(false)
          toast.success('Bem vindo!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })
          if (userObjEasywork.administrator) {
            history.push('./admin-info')
          }
          else if (userObjEasywork.isResearcher) {
            history.push('/dashboard/ALL')
          } else {
            history.push('/dashboard/PUBLISHED')
          }

          login(userObjEasywork)

          // window.location.reload()
        } else {
          setSubmitting(false)
          setIsLoading(false)
          if (resp.status === 400) {
            toast.error('Campos não podem ser vazios!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            })
          } else if (resp.status === 401) {
            toast.error('E-mail e/ou senha incorretos!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            })
          } else if (resp.status === 404) {
            toast.error('Usuário não encontrado!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            })
          } else {
            toast.error(
              'Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!',
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              }
            )
          }
        }
      })
      .catch(error => {
        setSubmitting(false)
        setIsLoading(false)
        toast.error(
          error.response.data.message,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          }
        )
      })
      .finally(() => {
        setSubmitting(false)
        setIsLoading(false)
      })
  }

  const handleChange = async event => {
    setState({ checkedA: !state.checkedA })
  }

  useEffect(() => {
    const AUTOFILLED = 'is-autofilled'
    const onAutoFillStart = el => el.classList.add(AUTOFILLED)
    const onAutoFillCancel = el => el.classList.remove(AUTOFILLED)
    const onAnimationStart = ({ target, animationName }) => {
      switch (animationName) {
        case 'onAutoFillStart':
          setState({ checkedA: true })
          return onAutoFillStart(target)
        case 'onAutoFillCancel':
          setState({ checkedA: false })
          return onAutoFillCancel(target)
        default:
          setState({ checkedA: false })
          return onAutoFillCancel(target)
      }
    }
    document
      .querySelector('input#password')
      .addEventListener('animationstart', onAnimationStart, false)
  }, [])

  return (
    <div>
      <Paper
        className={matches ? classes.paperMobile : classes.paper}
        elevation={matches ? 0 : 3}
      >
        <div className={classes.root}>
          <div>
            <img src={logo} width={'100%'} alt='Sistema de Pesquisa' />
          </div>

          <form
            className={classes.form}
            noValidate
            onSubmit={event => handleLogin(event, email, password)}
          >
            <TextField
              autoComplete='off'
              className={classes.input}
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label={
                <Typography className={classes.textPattern}>E-mail</Typography>
              }
              name='email'
              onChange={event => setEmail(event.target.value)}
            />
            <TextField
              autoComplete='off'
              className={classes.input}
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label={
                <Typography className={classes.textPattern}>Senha</Typography>
              }
              type='password'
              id='password'
              onChange={event => setPassword(event.target.value)}
            />

            <FormControlLabel
              control={
                <Checkbox
                  name='checkedA'
                  checked={state.checkedA}
                  onChange={handleChange}
                  autoComplete='checkedA'
                  color='primary'
                />
              }
              label={
                <Typography className={classes.textPattern}>
                  Lembrar de mim
                </Typography>
              }
            />
            <Button
              type='submit'
              size='large'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
            >
              {submitting ? (
                <LineScalePulseOut color={'#BA7100'} loading={submitting} />
              ) : (
                'Entrar'
              )}
            </Button>
            <Button
              type='button'
              size='large'
              fullWidth
              variant='contained'
              color='secondary'
              className={classes.submit}
              onClick={props.recuperarSenha}
            >
              {submitting ? (
                <LineScalePulseOut color={'#BA7100'} loading={submitting} />
              ) : (
                'Esqueci a senha'
              )}
            </Button>

            <Divider className={classes.divider} variant='middle' />
            {/* <div className={classes.footerPaper}>
                            <ButtonFacebook />
                            <Box mt={1}></Box>
                            <ButtonGoogle />
                        </div> */}
          </form>
        </div>
      </Paper>
    </div>
  )
}
