import React from 'react';  
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table';  
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  

import TableRow from '@material-ui/core/TableRow';  
import { useHistory } from 'react-router-dom';
import axios from 'axios';    
import { useState, useEffect } from 'react'   

import Button from '@material-ui/core/Button';
import Switch from './components/switch';
import ModalBlockage from './components/modal';
import Title from '../../components/Title';


const useStyles = makeStyles((theme) => ({ 

  root: {  
    width: '100%', 
    padding:16,
    overflow: 'auto',/* 'overlay', */
  },  
  container: {  
    minHeight: "fit-content",   
    maxHeight: "fit-content",
  },
  headerTable:{
    backgroundColor:"#19afb8"
  },
  headerText:{
    ...theme.typography.body2,
    color:"#fff",
    backgroundColor:"transparent",
    
  },
  rowText:{
    ...theme.typography.subtitle1,
    fontWeight:700
  },
  paginationBar: {
    paddingRight: `${theme.spacing(3)}px!important`,
  },
  divBackButton:{
    marginTop: `${theme.spacing(2)}px`,
    marginBottom: `${theme.spacing(4)}px`,
    textAlign:"end",
    marginRight: `${theme.spacing(4)}px`
  },
  buttonText:{
    fontWeight: 700,
    fontSize: '1.3rem'
  },
  select: {
    color:'black'
  },
  

}));  
  
export default function MatPaginationTable() {  
  const classes = useStyles();  
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [element, setElement] = useState('');  
  const [data, setData] = useState([]);   
  const [rowsPerPage, setRowsPerPage] = useState(5); 
  const [isOpen, setIsOpen] = useState(false);  
  
  useEffect(() => {    
        const GetData = async () => {    
          const result = await axios('https://jsonplaceholder.typicode.com/users');    
          setData(result.data);    
        }  
        GetData();    
  }, []);   
  const handleChangePage = (event, newPage) => {  
    setPage(newPage);  
  };  
  
  const handleChangeRowsPerPage = event => {  
    setRowsPerPage(+event.target.value);  
    setPage(0);  
  }; 
  
  const handleBack = (event) => {
    event.preventDefault();
    history.goBack();
  }
  const handleModal = (el) => {
    setElement(el)
    setIsOpen(!isOpen)
  }
  const handlePayment = (event) => {
    console.log("pagar");
    
  }
  const handleUnCheck = (event) => {
    element.checked = false;
  }
  const handleBlockUser = (event) => {
      handleModal()
  }
  
  return (  
    <Paper className={classes.root}> 
    <Title children='Avaliar respostas'/> 
      <TableContainer className={classes.container}>  
        <Table aria-label="sticky table">  
        <TableHead className={classes.headerTable}>  
            <TableRow>  
              
              <TableCell className={classes.headerText} align="left">ID Respondente</TableCell>  
              <TableCell className={classes.headerText} align="left">Código</TableCell>  
              <TableCell className={classes.headerText} align="left">Liberar pagamento</TableCell>  
              <TableCell className={classes.headerText} align="left">Bloquear respondente</TableCell>  
              
            </TableRow>  
          </TableHead>  
          <TableBody>  
            {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {  
              return (  
           <TableRow key={row.id}>  
                <TableCell className={classes.rowText} align="left" component="th" scope="row">  
                  {row.id}  
                </TableCell>  
                <TableCell className={classes.rowText} align="left">{row.name}</TableCell>  
                <TableCell className={classes.rowText} align="left">
                  <Switch 
                    id={`pagamento${row.id}`}
                    isChecked={false}
                    name={`pagamento${row.id}`}
                    origin="pagamento"
                    onChange={handlePayment}
                    />
                </TableCell>  
                <TableCell className={classes.rowText} align="left">
                <Switch 
                    id={`bloqueio${row.id}`}
                    isChecked={false}
                    name={`bloqueio${row.id}`}
                    origin="bloqueio"
                    onClick={handleModal}
                  />
                </TableCell>  
               
              </TableRow>  
                 
              );  
            })}  
          </TableBody>  
        </Table>  
      </TableContainer>  
      <TablePagination  
        rowsPerPageOptions={[5, 10, 15]}  
        component="div"
        className={classes.paginationBar}
        count={data.length}  
        rowsPerPage={rowsPerPage}  
        page={page}  
        onChangePage={handleChangePage}  
        onChangeRowsPerPage={handleChangeRowsPerPage} 
        classes={{menuItem:classes.select}}
        
      />  
      <div className={classes.divBackButton}>
        <Button
          type="button"
          size='large'
          variant="contained"
          color="primary"
          className={classes.buttonText}
          onClick={handleBack}
          
        >
          
            Voltar
          
          
          </Button>
      </div> 
      {(isOpen) ? (<ModalBlockage disSelected={handleUnCheck} sendAction={handleBlockUser}/>): ''}
    </Paper>  
  );  
} 