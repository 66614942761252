import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Title from '../Title';
import Check from '@material-ui/icons/CheckCircle';


RegisterSuccessful.defaultProps = {
    title: 'Agora sim, seu cadastro está completo.',
    message: 'Comece a explorar as pesquisas.'
}

const useStyles = makeStyles(theme => ({
	message: {
       textAlign: 'center'
    },
    text:{
        fontSize:'1.3rem!important',
        fontWeight:'500!important',
        color:'#707070',
        
    },
    
    iconCheck:{
        fontSize:'8rem',
        color:'#02afb8'
    },
    setMargin:{
        margin:'20px 20px'
    },
    link:{
        textDecoration: 'underline'
    }
}));

export default function RegisterSuccessful(props) {
    const classes = useStyles();
    setTimeout(()=>{
        window.localStorage.setItem('isNew',false)
    },3000)
    

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.setMargin}
        >
            <div> <Check className={`${classes.iconCheck} ${classes.setMargin}`} /> </div>
            <div className={classes.message}>
                <Title classes={`${classes.text}`} children={props.title} />
               
                <Typography className={`${classes.text}`} >
                    {props.message}
                </Typography>
            </div>
            
        </Grid>
    )
}