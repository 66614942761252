import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import {
  Accordion as ExpansionPanel,
  AccordionSummary as ExpansionPanelSummary,
  AccordionDetails as ExpansionPanelDetails
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { publishResearch } from '../../../../services/research';
import { LineScalePulseOut } from 'react-pure-loaders';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { UserContext } from '../../../../providers/UserProvider';

const useStyles = makeStyles({
  root: {
    width: 'fit-content',
    margin: '0'
  },
  rootCard: {
    width: '100%',
    minWidth: '350px',
    maxWidth: '350px',
    margin: 'auto 30px 30px 0'
  },
  rootCardMargin: {
    width: '100%',
    minWidth: '350px',
    maxWidth: '350px',
    margin: 'auto'
  },
  titleArea: {
    height: 150,
  },
  divTitle: {
    display: 'flex',
    alignItems: 'start',
    marginBottom: '18px',
    minHeight: 55,
    fontWeight: 500,
  },
  titleText: {
    color: '#fff',
    fontSize: '1.2rem',
  },
  valueText: {
    color: '#fff',
    fontSize: 13,
    fontWeight: 400,
    marginLeft: '6px',
  },
  gridCard: {
    marginBottom: '6px',
    maxWidth: 'fit-content'
  },
  paddingCard: {
    padding: '11px'
  },
  statusApproved: {
    backgroundColor: '#30B6BC'
  },
  statusPending: {
    backgroundColor: '#515BA0'
  },
  statusStopped: {
    backgroundColor: '#FFB743'
  },
  statusFinished: {
    backgroundColor: '#199D65'
  },
  statusRespStopped: {
    backgroundColor: '#D16354'
  },
  statusRespPending: {
    backgroundColor: '#8088BA'
  },
  statusStarted: {
    backgroundColor: '#A3A4A5'
  },
  statusDefault: {
    backgroundColor: '#aeaeae'
  }
});

export default function MediaCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const { userState } = useContext(UserContext);
  const [submitting, setSubmitting] = useState(false)

  const [status, setStatus] = useState(props.status)

  const handleQuizSelected = (quiz_id) => {
    if (userState.isResearcher) {
      if (status === 'CREATED') {
        history.push(`/edit-survey/${quiz_id}`, { quiz_id: quiz_id, stepId: 1 });
      }
      else {
        history.push(`/survey/${quiz_id}`, { quiz_id: quiz_id, stepId: 1 });
      }
    } else {
      history.push(`/survey/${quiz_id}`, status === "PUBLISHED" ? { quiz_id: quiz_id, statusResponse: status, stepId: 0, emailResearcher: props.email, reason: props.reason, created_at: props.createdAt } : status === "STARTED" ? { quiz_id: quiz_id, responseId: props.responseId, statusResponse: status, stepId: props.accessed ? 2 : 1, emailResearcher: props.email, reason: props.reason, created_at: props.createdAt } : status === "PENDING" ? { quiz_id: quiz_id, statusResponse: status, stepId: 3, emailResearcher: props.email, reason: props.reason, created_at: props.createdAt } : status === "EXPIRED" ? { quiz_id: quiz_id, statusResponse: status, stepId: 3, emailResearcher: props.email, created_at: props.createdAt } : { quiz_id: quiz_id, statusResponse: status, stepId: 3, emailResearcher: props.email, reason: props.reason, created_at: props.createdAt });
    }
  };

  const handlePublish = (event) => {

    event.preventDefault()
    setSubmitting(true)

    publishResearch(props.id).then(response => {
      setSubmitting(false)
      toast.success(response?.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setStatus('PUBLISHED')
      // history.push(`/dashboard/ALL`, { reload: true });
      // window.location.reload();
    })
      .catch(error => {
        setSubmitting(false)

        if (error.response.data.message.includes('Não há saldo disponível')) {
          history.push('../insert-credits')
        }

        if (error.response?.data?.status !== 400) {
          toast.error(error.response.data ?
            error.response.data.message
            : 'Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })

  }

  return (
    <Grid item className={classes.root} >
      <Card className={props.marginAuto ? classes.rootCardMargin : classes.rootCard}>
        <CardActionArea className={userState?.isResearcher ? (`${classes.titleArea} ${status === "PUBLISHED" ? (`${classes.statusApproved}`) : status === "CREATED" ? (`${classes.statusPending}`) : status === "STOPPED" ? (`${classes.statusStopped}`) : status === "FINISHED" ? (`${classes.statusFinished}`) : (`${classes.statusPending}`)} `) : (`${classes.titleArea} ${status === "PUBLISHED" ? (`${classes.statusApproved}`) : status === "STARTED" ? (`${classes.statusStarted}`) : status === "APPROVED" ? (`${classes.statusFinished}`) : status === "PENDING" ? (`${classes.statusRespPending}`) : status === "REFUSED" ? (`${classes.statusRespStopped}`) : (`${classes.statusRespPending}`)} `)}>
          {status === 'CREATED' && <Button
            className={classes.buttonGreen}
            type="button"
            size='large'
            fullWidth
            variant="contained"
            color="primary"
            style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, }}
            onClick={handlePublish}
          >
            {submitting ? (
              <LineScalePulseOut
                color={'#BA7100'}
                loading={submitting}
                style={{
                  fontSize: 10
                }}
              />
            ) : (
              "PUBLICAR"
            )}
          </Button>}
          <CardContent className={classes.paddingCard} onClick={props.noLink === true ? '' : () => { handleQuizSelected(props.id) }}>
            <div>
              <div className={classes.divTitle}>
                <Typography gutterBottom variant="h5" component="h2" className={classes.titleText}>
                  {props.title}
                </Typography>
              </div>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                  className={classes.gridCard}
                >
                  <UpdateOutlinedIcon className={classes.titleText} />
                  <Typography variant="body2" color="textSecondary" className={classes.valueText}>
                    {props.time} min
                  </Typography>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                  className={classes.gridCard}
                >
                  <TodayOutlinedIcon className={classes.titleText} />
                  <Typography variant="body2" color="textSecondary" className={classes.valueText}>
                    {moment(props.endDate).format('DD/MM/YYYY')}
                  </Typography>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                  className={classes.gridCard}
                >
                  <MonetizationOnOutlinedIcon className={classes.titleText} />
                  <Typography variant="body2" color="textSecondary" className={classes.valueText}>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(props.budget)}
                  </Typography>
                </Grid>
              </Grid>

            </div>
          </CardContent>
        </CardActionArea>


        <ExpansionPanel
          defaultExpanded={props.expanded || false}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid>
              <Typography variant='body2' color='textSecondary'>Solicitante:</Typography>
              <Typography variant='subtitle1'>{props.researcher}</Typography>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid>
              <Typography variant='body2' color='textSecondary'>Instruções</Typography>
              <Typography variant='subtitle1'>{props.instructions}</Typography>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Card>
    </Grid>
  );
}
