import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { LineScalePulseOut } from 'react-pure-loaders';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import { Tooltip } from '@material-ui/core';
import * as yup from 'yup';
import Select from '@material-ui/core/Select';
import { insertTransaction } from '../../../../services/research';
import SafePayment from '../../../safePayment'

let schema = yup.object().shape({
  documento: yup.number().required(),
  nome: yup.string().required(),
  numero: yup.number().required(),
  codigo: yup.number().required(),
  venc_ano: yup.number().required(),
  venc_mes: yup.number().required(),
  parcelas: yup.number().required(),
});

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: 700,
    boxShadow: 'none'
  },
  input: {
    backgroundColor: '#fff',
  },
  titleText: {
    fontWeight: 700,
    fontSize: '2rem',
    color: '#000000'
  },
  margin: {
    margin: theme.spacing(1),
  },
  iconMoney: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 700,
    fontSize: '2rem',
    margin: theme.spacing(1),
  },
  iconTime: {
    fontWeight: 700,
    fontSize: '2rem',
    margin: theme.spacing(1),
  },
  withIcon: {
    display: 'flex',
    alignItems: 'center'
  },
  sizer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '2rem',
    margin: theme.spacing(1)
  },
  iconsPattern: {
    color: '#576B77',
    fontSize: '18px',
    marginRight: 10
  },
  textPattern: {
    color: '#576B77',
    fontSize: '15px',
    maxWidth: '100%',
    wordWrap: 'break-word'
  },
  textNames: {
    color: '#111640',
    fontSize: '18px',
    fontWeight: 600,
    maxWidth: '100%',
    wordWrap: 'break-word'
  },
  textPreview: {
    color: '#111640',
    fontSize: '18px',
    fontWeight: 600,
    maxWidth: '100%',
    margin: '15px auto'
  },
  elementsMargin: {
    marginBottom: '1.5em'
  }
}));

export default function PaymentTab(props) {
  const user = props.user
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [research] = useState(props.newResearch);
  const [researchFinance] = useState(props.newResearchFinance);
  const [paymentResult, setPaymentResult] = useState();

  const valueToPay = researchFinance?.totalPayAmount - props.funds
  const [parcelas, setParcelas] = useState([{
    key: '1',
    label: `1X de ${new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(valueToPay)}`
  }])
  const [payment, setPayment] = useState({
    documento: '',
    nome: '',
    numero: '',
    codigo: '',
    venc_mes: '',
    venc_ano: '',
    valor_total: new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(valueToPay)
    ,
    parcelas: 1,
  })
  const meses = [
    { key: "01", label: "Janeiro" },
    { key: "02", label: 'Fevereio' },
    { key: "03", label: 'Março' },
    { key: "04", label: 'Abril' },
    { key: "05", label: 'Maio' },
    { key: "06", label: 'Junho' },
    { key: "07", label: 'Julho' },
    { key: "08", label: 'Agosto' },
    { key: "09", label: 'Setembro' },
    { key: "10", label: 'Outubro' },
    { key: "11", label: 'Novembro' },
    { key: "12", label: 'Dezembro' }
  ]

  const years = () => {
    var currentYear = new Date().getFullYear(), years = [];
    const endYear = currentYear + 50;
    while (currentYear <= endYear) {
      const ano = currentYear++;
      years.push({ key: ano, label: ano });
    }
    return years;
  }

  const anos = years()

  const inputChangeHandler = (event) => {
    let obj = { [event.target.name]: event.target.value }
    setPayment({ ...payment, ...obj })
  };

  const handlePayment = async (event) => {
    event.preventDefault()
    setSubmitting(true)

    const isValidForm = await schema.isValid(payment)
    if (!isValidForm) {
      setSubmitting(false)
      return toast.error('Campos não podem ser vazios!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    const transaction = {
      amount: valueToPay,
      name: payment.nome,
      document: payment.documento,
      cardNumber: payment.numero,
      expirationDate: `${payment.venc_mes}/${payment.venc_ano}`,
      securityCode: payment.codigo,
    }

    insertTransaction(transaction)
      .then(result => {
        props.handlePublish(event);
        toast.success('Créditos inseridos com sucesso', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })

      })
      .catch(error => {
        toast.error(error.response?.data?.message
          || 'Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(res => setSubmitting(false))
  }

  return (
    <Grid container>

      <SafePayment />

      <form style={{ minWidth: '100%' }}>
        <Grid item xs={12} md={12} lg={12} className={`cardFormCustomRadius paddingTabs`}>
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid item xs={12}>

              <Grid item xs={12}>
                <Tooltip title="Informe o documento (CPF ou CNPJ) do titular do cartão" placement="top-end">
                  <TextField
                    autoFocus
                    className={classes.input}
                    variant="outlined"
                    margin="normal"
                    inputProps={{ maxLength: 180 }}
                    required
                    fullWidth
                    value={payment?.documento}
                    id="documento"
                    label={<Typography className={classes.textPattern}>CPF / CNPJ</Typography>}
                    name="documento"
                    onChange={inputChangeHandler}
                  />
                </Tooltip>
              </Grid>

              <Grid item xs={12}>
                <Tooltip title="Informe o nome do titular do cartão" placement="top-end">
                  <TextField
                    className={classes.input}
                    variant="outlined"
                    margin="normal"
                    inputProps={{ maxLength: 25 }}
                    required
                    fullWidth
                    value={payment?.nome}
                    id="nome"
                    label={<Typography className={classes.textPattern}>Nome do Titular do Cartão</Typography>}
                    name="nome"
                    onChange={inputChangeHandler}
                  />
                </Tooltip>
              </Grid>

              <Grid item xs={12}>
                <Tooltip title="Informe o número do cartão" placement="top-end">
                  <TextField
                    className={classes.input}
                    variant="outlined"
                    margin="normal"
                    inputProps={{ maxLength: 16 }}
                    required
                    fullWidth
                    value={payment?.numero}
                    id="numero"
                    label={<Typography className={classes.textPattern}>Número do Cartão</Typography>}
                    name="numero"
                    onChange={inputChangeHandler}
                  />
                </Tooltip>
              </Grid>

              <Grid item xs={6}>
                <Tooltip title="Informe o código de segurança" placement="top-end">
                  <TextField
                    className={classes.input}
                    variant="outlined"
                    margin="normal"
                    inputProps={{ maxLength: 3 }}
                    required
                    fullWidth
                    value={payment?.codigo}
                    id="codigo"
                    label={<Typography className={classes.textPattern}>Código de Segurança</Typography>}
                    name="codigo"
                    onChange={inputChangeHandler}
                  />
                </Tooltip>
              </Grid>

              <Typography component="h3" className={classes.textPattern} >
                Vencimento
              </Typography>

              <Grid container>
                <Grid item xs={6}>
                  <Select
                    native
                    className={classes.input}
                    name="venc_mes"
                    id="venc_mes"
                    variant="outlined"
                    value={payment?.venc_mes}
                    onChange={inputChangeHandler}
                    fullWidth
                  >
                    <option key={0} value={0}>Mês</option>
                    {meses.map(mes => (
                      <option key={mes.key} value={mes.key}>{mes.label}</option>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 20 }}>
                  <Select
                    className={classes.input}
                    native
                    name="venc_ano"
                    id="venc_ano"
                    variant="outlined"
                    value={payment?.venc_ano}
                    onChange={inputChangeHandler}
                    fullWidth
                  >
                    <option key={0} value={0}>Ano</option>
                    {anos.map(ano => (
                      <option key={ano.key} value={ano.key}>{ano.label}</option>
                    ))}
                  </Select>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  disabled
                  className={classes.input}
                  variant="outlined"
                  margin="normal"
                  inputProps={{ maxLength: 70 }}
                  fullWidth
                  value={payment?.valor_total}
                  id="total"
                  label={<Typography className={classes.textPattern}>Valor Total</Typography>}
                  name="total"
                  style={{ marginTop: 20 }}
                />
              </Grid>

              <Typography component="h3" className={classes.textPattern} >
                Quantidade de Parcelas
              </Typography>

              <Grid item xs={12} style={{ marginBottom: '25px' }}>
                <Select
                  native
                  className={classes.input}
                  name="parcelas"
                  id="parcelas"
                  variant="outlined"
                  value={payment?.venc_mes}
                  onChange={inputChangeHandler}
                  fullWidth
                >
                  {parcelas.map(mes => (
                    <option key={mes.key} value={mes.key}>{mes.label}</option>
                  ))}
                </Select>
              </Grid>

            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} lg={12} className={'footerButtonsTabs'} >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item >
              <Button
                className={'buttonCancel'}
                type="button"
                size='large'
                color="secondary"
                startIcon={<NavigateBeforeIcon />}
                onClick={(event) => props.handleChange(2)}
              >
                Voltar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                size='large'
                fullWidth
                variant="contained"
                color="primary"
                onClick={(event) => { handlePayment(event) }}
                className={classes.submit}
                endIcon={<NavigateNextIcon />}
              >
                {submitting ? (
                  <LineScalePulseOut
                    color={'#BA7100'}
                    loading={submitting}
                  />
                ) : (
                  "Publicar"
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </form>
    </Grid>
  );
}
