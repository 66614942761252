import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { LineScalePulseOut } from 'react-pure-loaders';
import logo from '../../../../assets/logo-text-color.png';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { askSignUp } from '../../../../services/user';
import { toast } from 'react-toastify';
import { Fragment } from 'react';
import axios from 'axios';
import Link from '@material-ui/core/Link';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@material-ui/core';
//TODO: Melhorar as mensagens de validação.


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //height:'90%',
  },
  position: {
    position: "relative",
    fontFamily: theme.typography.fontFamily,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  underline: {
    textDecoration: "underline"

  },
  terms: {
    paddingRight: 10,
    color: '#576B77',
    fontSize: '14px',
    fontWeight: 400,

    '& a': {
      cursor: 'pointer'
    }
  },
  paper: {
    backgroundColor: '#E6E6E6',
    //height: '100%'

  },
  paperMobile: {
    width: '100%', // 
    padding: theme.spacing(2),
    //height:'100%',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  input: {
    backgroundColor: '#fff',
  },
  error: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  divider: {
    marginBottom: 10,
    marginTop: 10
  },
  textPattern: {
    color: '#000000'
  },
  textErrorPattern: {
    color: '#f44336'
  },
  isSelected: {
    border: "2px solid #02afb8",
    borderRadius: 15,
    padding: "0.5em",
  },
  footerPaper: {
    marginBottom: theme.spacing(3)
  },
  nameInput: {
    textTransform: 'capitalize'

  }
}));

export default function SignUp(props) {
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:600px)');
  const roles = {
    RESEARCHER: 'ROLE_RESEARCHER',
    RESPONDENT: 'ROLE_RESPONDENT',
  }
  const registerForm = useRef(null);
  const [isResearcher, setIsResearcher] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [address, setAddress] = useState("")
  const [number, setNumber] = useState("")
  const [district, setDistrict] = useState("")
  const [postcode, setPostcode] = useState("")
  const [CPFCNPJ, setCPFCNPJ] = useState("")
  const [submitting, setSubmitting] = useState(false);

  const [politica, setPolitica] = useState(false)
  const [termos, setTermos] = useState(false)

  const [errors, setErrors] = useState({})

  const userTypes = new Map([["researcher", true], ["respondent", false]]);


  function validateSignup(data) {

    const campoObrigatorio = 'Campo obrigatório'

    var errors = {}

    if (!data.name || data.name.trim().length === 0)
      errors.name = campoObrigatorio

    if (!data.email || data.email.trim().length === 0)
      errors.email = campoObrigatorio

    if (!data.password || data.password.trim().length === 0)
      errors.password = campoObrigatorio

    if (data.role === roles.RESEARCHER) {
      if (!data.cadastroNacional || data.cadastroNacional.trim().length === 0)
        errors.CPFCNPJ = campoObrigatorio

      if (!data.cep || data.cep.trim().length === 0)
        errors.postcode = campoObrigatorio

      if (!data.street || data.street.trim().length === 0)
        errors.street = campoObrigatorio

      if (!data.number || data.number.trim().length === 0)
        errors.number = campoObrigatorio

      if (!data.neighborhood || data.neighborhood.trim().length === 0)
        errors.district = campoObrigatorio
    }

    if (!politica)
      errors.politica = campoObrigatorio

    if (!termos)
      errors.termos = campoObrigatorio

    return errors

  }

  useEffect(() => {
    if (props.query.get("userType") !== null && userTypes.get(props.query.get("userType")) !== undefined) {
      setIsResearcher(userTypes.get(props.query.get("userType")))
    }
  }, [props.query, userTypes]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let user = {
      name: name,
      username: email,
      email: email,
      password: password,
      role: isResearcher ? roles.RESEARCHER : roles.RESPONDENT,
    }

    if (isResearcher) {
      user = {
        ...user,
        cep: postcode,
        street: address,
        number: number,
        neighborhood: district,
        cadastroNacional: CPFCNPJ,
        warned: true
      }
    }

    if (password !== passwordConfirmation) {
      toast.error('Senhas não são iguais.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else {

      const errors = validateSignup(user)
      if (Object.keys(errors).length > 0) {
        setErrors(errors)
      }
      else {
        setSubmitting(true);
        askSignUp(user).then(async (res) => {
          if (res.data.success) {
            setSubmitting(false);
            window.localStorage.setItem('isNew', true)
            toast.success(res.data?.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            registerForm.current.reset();
            props.changeContent()
          } else if (!res.data.success) {
            setSubmitting(false);
            if (res.data?.message) {
              toast.error(res.data.message, {
                position: "top-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.error('Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        }).catch(async (error) => {
          setSubmitting(false);

          if (error.response.data?.errors && error.response.data?.errors.length > 0) {
            const errors = error.response.data.errors

            const result = {}
            for (let err of errors) {
              if (err.field === 'cadastroNacional')
                err.field = 'CPFCNPJ'

              if (err.field === 'cep')
                err.field = 'postcode'

              result[err.field] = err.defaultMessage
            }

            setErrors(result)

            toast.error('Formulário inválido', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          else {

            const message = error.response.data?.message || 'Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.';

            toast.error(message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

          }
        });
      }
    }

    return false

  };


  const handleChange = (event) => {
    setIsResearcher(!isResearcher);
  };

  return (
    /*  <> */
    <Paper
      className={matches ? classes.paperMobile : classes.paper}
      elevation={matches ? 0 : 3}
    >
      <div className={classes.root}>
        <div>
          <img src={logo} width={'100%'} alt="Sistema de Pesquisa" />
        </div>
        <form className={classes.form} noValidate onSubmit={handleSubmit} ref={registerForm} >
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <Typography className={`${classes.textPattern} ${!isResearcher ? classes.isSelected : ""} `} >
              Respondente
            </Typography>

            <Switch
              checked={isResearcher}
              onChange={handleChange}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography className={`${classes.textPattern} ${isResearcher ? classes.isSelected : ""} `}>Pesquisador</Typography>
          </Grid>
          <TextField
            className={`${classes.input} `}
            inputProps={{ className: classes.nameInput }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={errors?.name}
            id="name-register"
            label={<Typography className={classes.textPattern}>Nome</Typography>}
            name="name"
            autoComplete="name"
            onChange={event => setName(event.target.value)}
          />
          {errors.name &&
            <Typography color="error" >
              {errors.name}
            </Typography>
          }

          <TextField
            className={classes.input}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={errors?.email}
            id="email-register"
            label={<Typography className={classes.textPattern}>E-mail</Typography>}
            name="email"
            autoComplete="email"
            onChange={event => setEmail(event.target.value)}
          />
          {errors.email &&
            <Typography color="error" >
              {errors.email}
            </Typography>
          }

          <TextField
            className={classes.input}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={errors?.password}
            name="password"
            label={<Typography className={classes.textPattern}>Senha</Typography>}
            type="password"
            id="register-password"
            autoComplete="current-password"
            onChange={event => setPassword(event.target.value)}
          />
          {errors.password &&
            <Typography color="error" >
              {errors.password}
            </Typography>
          }

          <TextField
            className={classes.input}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirm-password"
            label={<Typography className={classes.textPattern}>Confirmar senha</Typography>}
            type="password"
            id="confirm-password"
            onChange={event => setPasswordConfirmation(event.target.value)}
          />

          {isResearcher &&
            <Fragment>
              <TextField
                type='number'
                className={classes.input}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                error={errors?.CPFCNPJ}
                id="CFPCNPJ"
                label={<Typography className={classes.textPattern}>CPF / CNPJ</Typography>}
                name="CFPCNPJ"
                inputProps={{
                  maxLength: 14
                }}
                onChange={event => setCPFCNPJ(event.target.value)}
              />
              {errors.CPFCNPJ &&
                <Typography color="error" >
                  {errors.CPFCNPJ}
                </Typography>
              }

              <Grid item xs={4} md={4} lg={4}>
                <TextField
                  type='number'
                  className={classes.input}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  error={errors?.postcode}
                  id="postcode"
                  label={<Typography className={classes.textPattern}>CEP</Typography>}
                  name="postcode"
                  autoComplete="postcode"
                  onChange={event => { setPostcode(event.target.value) }}
                  onBlur={() => {
                    var found = false
                    setDistrict('...')
                    setAddress('...')

                    axios.get(`https://viacep.com.br/ws/${postcode}/json/`)
                      .then(res => {
                        if (res.data) {
                          found = true
                          setDistrict(res.data.bairro)
                          setAddress(res.data.logradouro)
                        }
                      })
                      .finally(res => {
                        if (!found) {
                          setDistrict('')
                          setAddress('')
                        }

                      })
                  }}
                />
                {errors.postcode &&
                  <Typography color="error" >
                    {errors.postcode}
                  </Typography>
                }
              </Grid>


              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                error={errors?.street}
                id="address"
                label={<Typography className={classes.textPattern}>Endereço</Typography>}
                name="address"
                onChange={event => setAddress(event.target.value)}
                value={address}
              />
              {errors.street &&
                <Typography color="error" >
                  {errors.street}
                </Typography>
              }

              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={4} md={4} lg={4}>
                  <TextField
                    type='number'
                    className={classes.input}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    error={errors?.number}
                    id="number"
                    label={<Typography className={classes.textPattern}>Número</Typography>}
                    name="number"
                    autoComplete="number"
                    onChange={event => setNumber(event.target.value)}
                  />
                  {errors.number &&
                    <Typography color="error" >
                      {errors.number}
                    </Typography>
                  }
                </Grid>

                <Grid item xs={8} md={8} lg={8}>
                  <TextField
                    className={classes.input}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    error={errors?.district}
                    id="district"
                    label={<Typography className={classes.textPattern}>Bairro</Typography>}
                    name="district"
                    autoComplete="district"
                    onChange={event => setDistrict(event.target.value)}
                    value={district}
                  />
                  {errors.district &&
                    <Typography color="error" >
                      {errors.district}
                    </Typography>
                  }
                </Grid>

              </Grid>
            </Fragment>
          }

          <Divider className={classes.divider} variant="middle" />
          <Grid item xs={12} md={12} lg={12}>

            <FormControl
              error={errors?.politica}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='politica'
                      autoComplete='politica_privacidade'
                      onChange={() => { setPolitica(!politica) }}
                      color='primary'
                    />
                  }
                  label={
                    <Typography className={errors?.politica ? classes.textErrorPattern : classes.textPattern}>
                      Li e concordo com a <Link href='./politica.pdf' target='blank' variant='body1'
                        style={{
                          color: '#02afb8',
                          textAlign: 'left',
                          flex: 1
                        }}>
                        Política de privacidade
                      </Link>
                    </Typography>
                  }
                />
              </FormGroup>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  name='termos'
                  autoComplete='termos_de_uso'
                  onChange={() => { setTermos(!termos) }}
                  color='primary'
                />
              }
              label={
                <Typography className={errors?.politica ? classes.textErrorPattern : classes.textPattern}>
                  Li e concordo com os <Link href={isResearcher ? './termos_pesquisador.pdf' : './termos_respondente.pdf'} target='blank' variant='body1'
                    style={{
                      color: '#02afb8',
                      textAlign: 'left',
                      flex: 1
                    }}>
                    Termos e condições de uso
                  </Link>
                </Typography>
              }
            />

          </Grid>

          <Button
            type="submit"
            size='large'
            fullWidth
            variant="contained"
            color={isResearcher ? 'primary' : 'secondary'}
            className={classes.submit}
          >
            {submitting ? (
              <LineScalePulseOut
                color={'#BA7100'}
                loading={submitting}
              />
            ) : (
              "Cadastrar"
            )}
          </Button>

          {/* <div className={classes.footerPaper}>
                    <ButtonFacebook/>
                    <Box mt={1}></Box>
                    <ButtonGoogle/>
                </div> */}



        </form>
      </div>
      {/*  <Box mt={1}>
            <Copyright />
        </Box> */}
    </Paper>
    /* </> */
  );
}