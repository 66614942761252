import React, { useContext, Fragment } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import LoginPage from './pages/login'
import TrocarSenha from './pages/trocarsenha'
import ConfirmaCadastro from './pages/confirmacadastro'
import DashboardPage from './pages/dashboard'
import ProfilePage from './pages/profile'
import SurveyCreatePage from './pages/surveyCreate'
import QuizPage from './pages/quiz'
import QuizResponsePage from './pages/quizResponse'
import Warning403 from './components/403'
import FinancialInfoPage from './pages/financialInfo'
import AdminInfoPage from './pages/adminInfo'
import RegisterSuccessful from './components/RegisterSuccessful'
import WelcomeWithSurvey from './components/WelcomeWithSurvey'
import ErrorPage from './pages/error'
import { UserContext } from './providers/UserProvider'
/* RESPONDENTES */
import SurveyMemberPage from './pages/surveyForMember'
import Fade from 'react-reveal/Fade'
import Loading from './components/Loading'
import InsertCreditsPage from './pages/insertCredits'
import ConfirmaSaque from './pages/confirmasaque'
//TODO: Implementar transição fade entre as páginas/rotas.
//https://medium.com/@dmitrynozhenko/5-ways-to-animate-a-reactjs-app-in-2019-56eb9af6e3bf

export default function Routes() {
  const { userState, isLoading } = useContext(UserContext)

  let isNew = window.localStorage.getItem('isNew')
    ? JSON.parse(window.localStorage.getItem('isNew'))
    : false

  // function loadRedirect() {
  //   if (userState?.administrator) {
  //     return <Redirect from='/' to='/admin-info' />
  //   } else if (userState?.isResearcher === true) {
  //     return <Redirect from='/' to='/dashboard/ALL' />
  //   } else if (userState?.isResearcher === false) {
  //     return <Redirect from='/' to='/dashboard/PUBLISHED' />
  //   }
  // }

  if (isLoading)
    return <Loading />

  return (
    <>
      <Switch>
        {userState?.isAuthenticated ? (
          <Fragment>
            <Route
              path='/dashboard/:status'
              exact
              protected
              render={props => (
                <Fade right>
                  <DashboardPage {...props} user={userState} />
                </Fade>
              )}
            />

            <Route
              path='/register-complete'
              exact
              protected
              render={props => (
                <Fade right>
                  <RegisterSuccessful {...props} user={userState} />
                </Fade>
              )}
            />

            <Route
              path='/profile'
              exact
              protected
              render={props => (
                <Fade right>
                  <ProfilePage {...props} user={userState} />
                </Fade>
              )}
            />

            <Route
              path={['/acesso/confirmarSaque']}
              render={props => <ConfirmaSaque {...props} />}
            />

            <Route
              path='/financial-info'
              exact
              protected
              render={props => (
                <Fade right>
                  <FinancialInfoPage {...props} user={userState} />
                </Fade>
              )}
            />

            {userState.administrator && <Route
              path='/admin-info'
              exact
              protected
              render={props => (
                <Fade right>
                  <AdminInfoPage {...props} user={userState} />
                </Fade>
              )}
            />}


            {userState.isResearcher && <Route
              path='/insert-credits'
              exact
              protected
              render={props => (
                <Fade right>
                  <InsertCreditsPage {...props} />
                </Fade>
              )}
            />}

            <Route path='/quiz/:id' exact protected component={QuizPage} />
            <Route
              path='/quiz/:id/responses'
              exact
              protected
              component={QuizResponsePage}
            />
            <Route
              path='/create-survey'
              exact
              protected
              render={
                /*component={SurveyCreatePage} */
                props => (
                  <Fade right>
                    <SurveyCreatePage {...props} user={userState} />
                  </Fade>
                )
              }
            />
            <Route
              path='/edit-survey/:id'
              exact
              protected
              render={
                /*component={SurveyCreatePage} */
                props => (
                  <Fade right>
                    <SurveyCreatePage {...props} user={userState} />
                  </Fade>
                )
              }
            />
            <Route path='/error' exact protected component={ErrorPage} />
            {/* RESPONDENTES */}
            <Route path='/do-a-survey' exact component={WelcomeWithSurvey} />
            <Route
              path='/survey/:id'
              exact
              protected
              component={SurveyMemberPage}
            />
            {/* RESPONDENTES */}
            {isNew && <Redirect from='/' to='/register-complete' /> && (
              <Redirect from='/login' to='/register-complete' />
            )}
            {/* {loadRedirect()} */}
            {/* <Redirect from='/login' to='/dashboard/ALL' /> */}
          </Fragment>) : (
          <Fragment>
            <Route
              path={['/login']}
              render={props => <LoginPage {...props} />}
            />
            <Route
              path={['/redefinirSenha']}
              render={props => <TrocarSenha {...props} />}
            />
            <Route
              path={['/acesso/confirmarEmail']}
              render={props => <ConfirmaCadastro {...props} />}
            />
            <Route path="/" exact component={LoginPage} />
            <Route path='/403' exact component={Warning403} />
            {/* <Redirect from='*' to='/403' /> */}
          </Fragment>
        )}
      </Switch>
    </>
  )
}
