import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import PersonalDataTab from './components/personalTab';
import BankDataTab from './components/bankTab';
import { AppBar, Box, Paper, Typography, Grid, Tab, Tabs } from '@material-ui/core';
import PixTab from './components/pixTab';
import { useLocation } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography component={'div'} >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,

};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,

  };
}

export default function ProfilePage(props) {
  const [value, setValue] = useState(0);
  const [user, setUser] = useState(props.user);
  const [isResearcher, setIsResearcher] = useState(props.user.isResearcher);
  //const [tabValue, setTabValue] = useState(0);
  const [blockParams, setBlockParams] = useState(false)

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const params = useQuery()

  useEffect(() => {
    if (!blockParams && params && params.get('pix') === '1') {
      setValue(2)
    }
  }, [params])

  useEffect(() => {
    setUser(props.user)
    setIsResearcher(props.user.isResearcher)
    //setTabValue(isResearcher ? 1 : 2 );
  }, [props])


  const handleChange = (event, newValue) => {
    setBlockParams(true)
    setValue(newValue);
  };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      xs={12} md={12} lg={12} sm={12}
    >
      <Box component="div" className={'divPaperContent'}>
        <Paper elevate={6} style={{ boxShadow: 'none', maringBottom: '16px', borderRadius: '10px', backgroundColor: 'transparent' }}>
          <AppBar className={'borderAppBar'} style={{ backgroundColor: '#FFFFFF', boxShadow: 'none', padding: '16px 32px 0 32px' }} position="static">
            <Tabs value={value} variant='scrollable' onChange={handleChange} aria-label="profile tabs">
              <Tab label="Dados Pessoais" {...a11yProps(0)} />
              {/*!isResearcher ? 
                (
                  <Tab label="Dados Adicionais" {...a11yProps(!isResearcher ? 1 : null )} />
                ) : (null)
              }*/}

              {!isResearcher && <Tab label="Dados Bancários" {...a11yProps(1)} />}
              {!isResearcher && <Tab label="Meu Pix" {...a11yProps(2)} />}

            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0}>
            <PersonalDataTab user={user} />
          </TabPanel>


          {/*!isResearcher ? 
            (
              <TabPanel value={value} index={!isResearcher ? 1 : null }>
                <AdditionalDataTab handleSubmit={handleSubmit} />
              </TabPanel>
            ) : (null)
          */}

          {!isResearcher &&
            <Fragment>
              <TabPanel value={value} index={1}>
                <BankDataTab />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <PixTab />
              </TabPanel>
            </Fragment>}
        </Paper>
      </Box>
    </Grid>
  );
}
