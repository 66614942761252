import React, { useContext, useState, Fragment } from 'react'
import Burguer from './burguer';
import { AppBar, Box, Button, Menu, MenuItem, Typography, Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import logoBrand from '../../assets/logo-text-color.png';
import Toolbar from '@material-ui/core/Toolbar';
import UserAvatar from "../UserAvatar";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import { UserContext } from '../../providers/UserProvider';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

export default function MenuBurguerHeader() {

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,

      position: 'relative',
      top: 0,
      width: '100%',
    },
    appbar: {
      backgroundColor: '#fafafa',
    },
    logo: {
      width: '150px',
      margin: 'auto'
    },
    novaPesquisa: {
      width: '165px',
      fontSize: '12px'
    },
    iconCopy: {
      color: '#aeaeaf',
      padding: 8,
      margin: 'auto 5px auto auto'
    }
  }));

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const { userState, logout, setIsLoading } = useContext(UserContext);
  const user = userState

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInsertCredits = () => {

  }

  const goToSurveyCreate = () => {
    history.push("/create-survey");
  };

  const handleCopyCode = (e) => {
    e.preventDefault()
    navigator.clipboard.writeText("text")
  }

  const handleSignOut = async (event) => {
    setAnchorEl(null);
    setIsLoading(true);
    logout()
    setTimeout(() => {
      setIsLoading(false)
      toast.info('Sessão encerrada com sucesso!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push('/login')
    }, 1000)
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color={"inherit"} className={classes.appbar}>
        <Toolbar>
          <Burguer />


          <Box component="div" display='flex' >
            <img src={logoBrand} className={classes.logo} alt="Sistema de Pesquisa" />
          </Box>


          <Box component="div" display='flex' marginLeft={'auto'} >

            {user?.isResearcher ? (
              <Box margin={'auto 5px auto auto'}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.novaPesquisa}
                  onClick={goToSurveyCreate}
                  startIcon={<AddIcon />}
                >
                  Nova pesquisa
                </Button>
              </Box>)
              :
              (<Box />)
            }
            {!user?.isResearcher ?
              <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={e => handleCopyCode(e)}
                className={classes.iconCopy}
              >
                <FileCopyOutlinedIcon />
              </IconButton> : ''}
            <Box margin={'auto'}>
              <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <UserAvatar user={user} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <Link to="/profile">
                  <MenuItem onClick={handleClose}>
                    <Typography variant='body2'>
                      {user.name} {user.lastName}
                    </Typography>
                  </MenuItem>
                </Link>
                <Divider />
                {userState.isResearcher && <Fragment>
                  <Link to="/insert-credits">
                    <MenuItem onClick={handleInsertCredits}>
                      <Typography variant='body2'>
                        Inserir créditos
                      </Typography>
                    </MenuItem>
                  </Link>
                  <Divider />
                </Fragment>}
                <Link to="/login">
                  <MenuItem onClick={handleSignOut}>
                    <Typography variant='body2'>
                      Sair
                    </Typography>
                  </MenuItem>
                </Link>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  )
}