import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Facebook as FaceIcon } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { firebaseAuth,facebookAuthProvider } from "../../utils/firebase";

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText('#1D3369'),
        backgroundColor: '#1D3369',
        '&:hover': {
        backgroundColor: '#3E5B95',
        },
    marginBottom:10
    },
}));

export default function ButtonFacebook() {

    const classes = useStyles();
    const handleFacebook = async () => {
        firebaseAuth.signInWithRedirect(facebookAuthProvider).then(function(result) {
            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            //var token = result.credential.accessToken;
            // The signed-in user info.
            //var user = result.user;
            
            // ...
          }).catch(function(error) {
            // Handle Errors here.
            //var errorCode = error.code;
            //var errorMessage = error.message;
            // The email of the user's account used.
            //var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            //var credential = error.credential;
            // ...
          });
    }
    return (
        <Button className={classes.root} size='large' 
            fullWidth 
            variant="contained"
            startIcon={<FaceIcon />}
            onClick={handleFacebook}> 
                Entrar com Facebook
        </Button>
    );
}