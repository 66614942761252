import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/firebase-auth'

const prodConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

const devConfig = {
  apiKey: 'AIzaSyCSJwdTh4g-FKCVGrkaBIIzrETorhKRw0Y',
  authDomain: 'easy-work-site.firebaseapp.com',
  //authDomain:"httṕs://www.easywork.com.br",
  databaseURL: 'https://easy-work-site.firebaseio.com',
  projectId: 'easy-work-site',
  storageBucket: 'easy-work-site.appspot.com',
  messagingSenderId: '816792614712',
  appId: '1:816792614712:web:f71a7d49634918b964b3cd',
  measurementId: 'G-TPGZFRPED8'
}

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig

const firebaseApp = firebase.initializeApp(config)
export const firebaseAuth = firebaseApp.auth()
export const firebaseFirestore = firebaseApp.firestore()
export const firebaseStorage = firebaseApp.storage()
export const facebookAuthProvider = new firebase.auth.FacebookAuthProvider()
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
//TODO: Revisar as funções generate.

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return
  const userRef = firebaseFirestore.doc(`users/${user.uid}`)
  const snapshot = await userRef.get()
  if (!snapshot.exists) {
    // replace all null values with ''
    Object.keys(user).forEach(key => user[key] === null && (user[key] = ''))
    try {
      await userRef.set({
        /* displayName,
        email,
        photoURL, */
        ...user,
        ...additionalData
      })
    } catch (error) {
      console.error('Error creating user document', error)
    }
  }
  return getUserDocument(user.uid)
}

export const getUserDocument = async uid => {
  if (!uid) return null
  try {
    const userDocument = await firebaseFirestore.doc(`users/${uid}`).get()
    return {
      uid,
      ...userDocument.data()
    }
  } catch (error) {
    console.error('Error fetching user', error)
  }
}

export const updateUserDocument = async (userData, additionalData) => {
  if (!userData.uid) return null
  const userRef = await firebaseFirestore.doc(`users/${userData.uid}`)
  const snapshot = await userRef.get()
  if (snapshot.exists) {
    try {
      await userRef.update({ ...userData })
    } catch (error) {
      console.error('Error updating user document', error)
    }
  }
}

export const saveUserPhoto = async (user, dataUrl) => {
  if (!user.uid) return null
  let photoURL = ''
  let photoRef = `images/${user.uid}`

  await firebaseStorage
    .ref(photoRef)
    .put(dataUrl)
    .then(async () => {
      photoURL = await firebaseStorage.ref(photoRef).getDownloadURL()
    })

  let userData = { ...user, photoURL: photoURL }
  await updateUserDocument(userData)

  return photoURL
}

export const handleFirebaseSignIn = async (email, password) => {
  let user = {}
  let res = await firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .then(async res => {
      if (res.user) {
        user = await generateUserDocument(res.user)
        user = {
          isAuthenticated: true,
          isResearcher: user.isResearcher,
          uid: user.uid,
          name: user.name,
          lastName: user.lastName,
          email: user.email,
          photoURL: user.photoURL,
          hasSentLicense: user.hasSentLicense,
          hasSentVote: user.hasSentVote
        }

        await updateUserDocument(user)
      } else {
        user = { isAuthenticated: false, accessToken: '' }
      }
      return { success: true, response: user }
    })
    .catch(error => {
      return { success: false, error: error }
    })

  return res
}

export const handleFirebaseSignOut = async () => {
  let user = firebaseAuth.currentUser
  if (user) {
    let userSignOut = {
      uid: user.uid,
      isAuthenticated: false,
      accessToken: ''
    }
    window.localStorage.setItem('isLogged', false)
    await updateUserDocument(userSignOut)

    let res = await firebaseAuth
      .signOut()
      .then(async () => {
        window.localStorage.setItem('isLogged', false)
        return true
      })
      .catch(async err => {
        let userSignOut = {
          ...user,
          isAuthenticated: true
        }
        window.localStorage.setItem('isLogged', true)
        await updateUserDocument(userSignOut)
        return false
      })

    return res
  }
  window.localStorage.setItem('isLogged', false)
  return true
}

export const getAccesstoken = async uid => {
  if (!uid) return null
  try {
    const userDocument = await firebaseFirestore.doc(`users/${uid}`).get()
    let obj = userDocument.data()

    return obj.accessToken
  } catch (error) {
    console.error('Error fetching user', error)
  }
}
