import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Title from '../Title';
import Pages from '@material-ui/icons/InsertDriveFileOutlined';
import Avatar from '@material-ui/core/Avatar';
import avatarImg from '../../assets/avatar.jpg';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { useHistory } from 'react-router-dom';

WelcomeWithSurvey.defaultProps = (userName)=>({
    title: `Bem vindo, ${userName}. Comece a responder pesquisas hoje mesmo.`,
    message: 'Comece por aqui.'
})

const useStyles = makeStyles(theme => ({
    rootCard:{
        display:'flex'
    },
    message: {
       textAlign: 'center'
    },
    text:{
        fontSize:'1.1rem!important',
        fontWeight:'500!important',
        color:'#707070',
        
    },
    avatar: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      boxShadow: '0px 1px 11px -1px #555',
      marginBottom: 20,
      marginTop: 20,
    },
    
    iconCheck:{
        fontSize:'8rem',
        color:'#02afb8'
    },
    setMargin:{
        margin:'20px 0px 20px 0px'
    },
    link:{
        textDecoration: 'underline'
    },
    ajust:{
        display:'flex',
        alignItems: 'center'
    },
    mr:{
        marginRight:theme.spacing(1)
    },
    divIcon:{
        height: '3rem',
        width: '6rem',
        textAlign: 'center',
    },
    iconSize:{
        height: '3rem',
        width: '3rem',
    }
}));

export default function WelcomeWithSurvey(props) {
    let item ={  
        id:1,
		title: 'Título de pesquisa bastante longo',
        time: '8:30',
        budget: '150',
        researcher: 'Maria Lúcia dos Santos',
        instructions: 'Loren ipsum dolor amet',
        status: 0
	}

    let propsLocal={name:'Marcos'}
    const classes = useStyles();
    const welcome = WelcomeWithSurvey.defaultProps(propsLocal.name);
    const history = useHistory();

    const handleQuizSelected = (quiz_id) => {
        history.push(`/survey/${quiz_id}`,{ quiz_id: quiz_id }); 
        
    }

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.setMargin}
        >
            <Grid container justify='center'>
                <Avatar alt={propsLocal.name} src={avatarImg} className={classes.avatar} />
            </Grid>
            <div className={classes.message}>
                <Title classes={`${classes.text}`} children={welcome.title} />
               
                <Typography className={`${classes.text}`} >
                    {welcome.message}
                </Typography>
            </div>
            <Grid container justify='center'>
                <Card elevation={6}>
                <CardActionArea component='div' className={`${classes.titleArea}`}>
                    <CardContent className={classes.ajust}>
                    <div className={classes.divIcon}>
                        <Pages className={classes.iconSize} />
                    </div>

                    <Divider className={classes.mr} orientation="vertical" flexItem />

                    <div>
                        <div className={classes.ajust}>
                            <Typography gutterBottom variant="h5" component="h2" className={classes.titleText}>
                                {item.title}
                            </Typography>
                            {/* <div aria-label="detalhes" onClick={()=>{handleQuizSelected(item.id)}}>
                                <ChevronRightIcon className={classes.titleText}/>
                            </div> */}
                        </div>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"

                        >   
                            <Grid item className={classes.ajust}>
                                <ScheduleIcon className={classes.mr}/>
                                <Typography variant="body2" color="textSecondary" className={classes.titleText}>
                                    {item.time}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.ajust}>
                                <MonetizationOnIcon className={classes.mr}/>
                                <Typography variant="body2" color="textSecondary" className={classes.titleText}>
                                    R$ {item.budget},00
                                </Typography>       
                            </Grid>
                            
                        </Grid>
                        
                        <Grid className={classes.setMargin}>
                            <Typography variant='body2' color='textSecondary'>Instruções</Typography>
                            <Typography variant='subtitle1'>{item.instructions}</Typography>
                        </Grid>

                        <Grid 
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                            >
                            <Grid item ></Grid>
                            <Grid item style={{ marginTop: 16, paddingRight:10 }}>
                                <Button
                                type="submit"
                                size='large'
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={()=>{handleQuizSelected(item.id)}}
                            >
                                
                                Responder
                                
                                </Button>
                                
                            </Grid>
                        </Grid>    
                            
                        
                    </div>
                    </CardContent>
                </CardActionArea>
                </Card> 
            </Grid>
            
        </Grid>
    )
}


