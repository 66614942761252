import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Title from '../Title';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';


ErrorAwarning.defaultProps = {
    title: 'OOPS.. parece que algo deu errado.',
    message: 'Ainda estamos trabalhando aqui.'
}

const useStyles = makeStyles(theme => ({
	message: {
       textAlign: 'flex-start'
    },
    text:{
        fontSize:'2rem!important',
        fontWeight:'500!important'
    },
    subText:{
        color:'#707070',
        fontSize:theme.spacing(2.3),
    },
    iconGear:{
        fontSize:'4rem',
        color:'#02afb8'
    },
    setMargin:{
        margin:'20px 20px'
    }
}));

export default function ErrorAwarning(props) {
	const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.setMargin}
        >
            <div> <SettingsOutlinedIcon className={`${classes.iconGear} ${classes.setMargin}`} /> </div>
            <div className={classes.message}>
                <Title classes={`${classes.text} ${classes.setMargin}`} children={props.title} />
               
                <Typography className={`${classes.subText} ${classes.setMargin}`} >
                    Nosso servidores podem estar passando por um período de instabilidade.<br></br>
                    Tente novamente em alguns instantes.
                </Typography>
            </div>
            
        </Grid>
    )
}