import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Button } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { toast } from 'react-toastify';
import { LineScalePulseOut } from 'react-pure-loaders';
import IntlCurrencyInput from "react-intl-currency-input"
import { Tooltip } from '@material-ui/core';
import { myResearcherReport } from '../../../../services/research';
import { getAllResponses, myWithdraw, askFunds } from '../../../../services/response';
import moment from 'moment'
import { UserContext } from '../../../../providers/UserProvider';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  avatarLg: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    boxShadow: '0px 1px 11px -1px #555',
    marginTop: 20,
  },
  button: {
    margin: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: 700,
  },
  input: {
    backgroundColor: '#fff',
  },
  input_image: {
    display: 'none',
  },
  textPattern: {
    color: '#000000'
  },
  removeConfigInput: {
    border: 'none',
    fontFamily: 'Montserrat',
    color: '#44444',
    padding: '10px',
  },
  fieldsetInput: {
    backgroundColor: '#fff',
    animationName: 'onAutoFillCancel',
    border: '1px solid #c4c4c4',
    borderRadius: '4px',
    padding: '0 8px',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    },
  },
  legendPattern: {
    color: '#000000',
    fontSize: '11px',
    padding: '0 30px 0 3px'
  },
}));

const columns = [
  { field: 'data', align: 'left', headerName: 'Data', headerClassName: 'reasearchDataGridSmall', width: 180 },
  { field: 'descricao', align: 'left', headerName: 'Descrição', headerClassName: 'reasearchDataGridSmall', flex: 1 },
  { field: 'creditos', align: 'left', headerName: 'Créditos', headerClassName: 'reasearchDataGridSmall', width: 120 },
  { field: 'debitos', align: 'left', headerName: 'Débitos', headerClassName: 'reasearchDataGridSmall', width: 120 },
  { field: 'saldo', align: 'left', headerName: 'Saldo', headerClassName: 'reasearchDataGridSmall', width: 120 },
];

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}

export default function StatementTab(props) {
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false)
  const [valor, setValor] = useState(50)
  const [data, setData] = useState([])
  const { userState } = useContext(UserContext);

  const history = useHistory();

  useEffect(() => {

    async function getData() {

      myResearcherReport()
        .then(res => {
          let result = res.data.content.map(x => {
            const data_original = moment.utc(x.financialDate)
            return {
              id: x.id,
              data_original,
              data: data_original.format('DD/MM/YYYY HH:mm:ss'),
              descricao: x.description,
              creditos: x.credit ? `R$ ${x.credit.toLocaleString('pt-br', { minimumFractionDigits: 2 })}` : '',
              debitos: x.debit ? `R$ ${x.debit.toLocaleString('pt-br', { minimumFractionDigits: 2 })}` : '',
              saldo: x.balance ? `R$ ${x.balance.toLocaleString('pt-br', { minimumFractionDigits: 2 })}` : '',
            }
          })

          result = result.sort(function (a, b) {
            return a.data_original.valueOf() - b.data_original.valueOf()
          })

          setData(result)
        })

      // const deposits = await myDeposits().then(response => {
      //   return response.data?.content
      // })
      // var result = deposits.map(item => {
      //   const data_original = moment(item.insertedAt)
      //   return {
      //     id: item.id,
      //     data_original,
      //     data: data_original.format('DD/MM/yyyy HH:mm:ss'),
      //     descricao: 'Inclusão de créditos',
      //     valor_original: item.inserted,
      //     creditado: true,
      //     creditos: `R$ ${item.inserted.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`,
      //     debitos: '',
      //   }
      // })

      // const allresearches = await getAllResearchesNotExcluded().then(response => {
      //   return response.data?.content.map(x => { return { id: x.id, payment: x.payAmount } })
      // })

      // for (let x = 0; x < allresearches.length; x++) {
      //   var responses = await getByIdResearchResponses(allresearches[x].id)
      //   if (responses.data.content && responses.data.content.length > 0) {
      //     var responseslist = responses.data.content.filter(x => x.status == 'APPROVED')
      //     if (responseslist && responseslist.length > 0) {

      //       var respostas = responseslist.map(item => {
      //         const data_original = moment(item.evaluatedAt)
      //         return {
      //           id: `${allresearches[x].id}-${item.id}`,
      //           data_original,
      //           data: data_original.format('DD/MM/yyyy HH:mm:ss'),
      //           descricao: `Pgto respondente ${item.respondentId}`,
      //           valor_original: allresearches[x].payment,
      //           creditos: '',
      //           debitos: `R$ ${allresearches[x].payment.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`,
      //         }
      //       })

      //       result.push(...respostas)
      //     }
      //   }
      // }

      // result = result.sort(function (a, b) {
      //   return a.data_original.valueOf() - b.data_original.valueOf()
      // })
      // result.forEach((item, index) => {
      //   let itemAnterior = index == 0 ? { saldo_original: 0 } : result[index - 1]
      //   let valor = item.creditado ? item.valor_original : -item.valor_original
      //   item.saldo_original = itemAnterior.saldo_original + valor
      //   item.saldo = `R$ ${item.saldo_original.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`
      // });

      // setData(result)
    }

    async function getRespondent() {

      var responses = await getAllResponses()
        .then(res => { return res.data.content })

      responses = responses.filter(x => x.responseStatus === 'APPROVED')

      var result = responses.map(item => {
        const data_original = moment.utc(item.evaluatedAt)
        return {
          id: item.id,
          data_original,
          data: data_original.format('DD/MM/yyyy HH:mm:ss'),
          descricao: `Recebto pesquisa ${item.researchName}`,
          valor_original: item.payAmount,
          creditado: true,
          creditos: `R$ ${item.payAmount.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`,
          debitos: '',
        }
      })

      var withdraw = await myWithdraw()
        .then(res => { return res.data.content })

      withdraw = withdraw.filter(x => x.rescuedAt != null)

      var rescued = withdraw.map(item => {
        const data_original = moment.utc(item.rescuedAt)
        return {
          id: item.id,
          data_original,
          data: data_original.format('DD/MM/yyyy HH:mm:ss'),
          descricao: `Solicitação de Saque`,
          valor_original: item.rescued,
          creditos: '',
          debitos: `R$ ${item.rescued.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`,
        }
      })

      result.push(...rescued)

      result = result.sort(function (a, b) {
        return a.data_original.valueOf() - b.data_original.valueOf()
      })
      result.forEach((item, index) => {
        let itemAnterior = index === 0 ? { saldo_original: 0 } : result[index - 1]
        let valor = item.creditado ? item.valor_original : -item.valor_original
        item.saldo_original = itemAnterior.saldo_original + valor
        item.saldo = `R$ ${item.saldo_original.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`
      });
      setData(result)

    }

    if (data.length === 0 && userState.isResearcher)
      getData()

    else if (data.length === 0 && userState.isResearcher === false)
      getRespondent()

  }, [data, userState])

  const withdrawMoney = async (event) => {

    const saque = parseFloat(valor);
    event.preventDefault();
    setSubmitting(true);

    if (!saque || saque === 0) {
      toast.error('Informe o valor do saque.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else if (saque < 50) {
      toast.error('Valor mínimo para saque é de R$50,00!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    await askFunds(saque).then(res => {
      toast.success('Saque solicitado com sucesso, enviamos um e-mail para você confirmar o saque.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setSubmitting(false);

    }).catch(error => {
      if (error.response?.data?.message.includes('pix')) {
        history.push('/profile?pix=1')
      }

      if (error.response?.data?.status !== 400) {
        let errMessage = 'Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!'
        toast.error(errMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setSubmitting(false);
    })



  }

  const handleFocusMoney = (event) => {
    event.preventDefault();
    event.target.parentElement.classList.add(classes.fieldsetCustom)
  }

  const handleBlurMoney = (event) => {
    event.preventDefault();
    event.target.parentElement.classList.remove(classes.fieldsetCustom)
  }


  return (

    <Grid container>
      <Grid item xs={12} md={12} lg={12} className={`cardFormCustomRadius paddingTabsCustom`} >
        <Box fullWidth>
          <Box className={classes.rootDataGrid}>
            <Box style={{ width: '100%', marginTop: 25 }}>
              <DataGrid
                className={classes.containerTable}
                rows={data}
                columns={columns}
                hideFooterRowCount={true}
                hideFooterPagination={false}
                hideFooterSelectedRowCount={true}
                disableSelectionOnClick
                disableColumnMenu
                autoHeight={true}
                getRowClassName={() =>
                  `row-actions`
                }
                localeText={{
                  noRowsLabel: "Sem respostas."
                }}
              />
            </Box>
            {userState.isResearcher === false &&
              <Box display={'flex'} style={{ alignItems: 'center' }}>
                <Grid container>
                  <Grid xs={12} md={8} lg={8}>
                    <Tooltip title="Informe o valor desejado para saque (mínimo R$ 50,00)." placement="top-end">
                      <fieldset className={classes.fieldsetInput}>
                        <legend className={classes.legendPattern}>Valor</legend>
                        <IntlCurrencyInput
                          className={classes.removeConfigInput}
                          currency="BRL"
                          config={currencyConfig}
                          value={valor}
                          onFocus={(e) => handleFocusMoney(e)}
                          onBlur={(e) => handleBlurMoney(e)}
                          id="payAmount"
                          name="payAmount"
                          onChange={(event, value, maskedValue) => setValor(value)}
                        />
                      </fieldset>
                    </Tooltip>
                  </Grid>
                  <Grid xs={12} md={4} lg={4}>
                    <Button
                      className={[classes.button, 'me-1']}
                      type="button"
                      size='large'
                      fullWidth
                      variant="contained"
                      color="primary"
                      style={{ marginTop: 10 }}
                      onClick={withdrawMoney}
                    >
                      {submitting ? (
                        <LineScalePulseOut
                          color={'#BA7100'}
                          loading={submitting}
                          style={{
                            fontSize: 10
                          }}
                        />
                      ) : (
                        "Solicitar Saque"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Box>}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}