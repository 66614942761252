import React,{useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useMediaQuery } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '13000!important',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: `60%`,
    maxHeight: `90%`,
    height: `90%`,
    minHeight: `70%`,
    
  },
  paperMobile:{
    width: `100%`,
    maxHeight: `85%`,
    height: `85%`,
    minHeight: `85%`,
    padding: theme.spacing(1, 1, 1),
  },
  text:{
    fontWeight: 400,
    fontSize: 16
  },
  textArea:{
    minHeight: `90%`,
    minWidth: `100%`,
    height: `95%!important`,
    width: `100%`,
    maxHeight: `100%`,
    maxWidth: `100%`,
    padding: 5,
    marginTop: theme.spacing(2),
    overflow: "inherit!important"
  },
  divButtons:{
    marginTop: `${theme.spacing(2)}px`,
    marginBottom: `${theme.spacing(4)}px`,
    textAlign:"end",
    marginRight: `${theme.spacing(4)}px`
  }, 
  buttonIcon:{
    margin: '-2% 0 16px 98%'
  },
  buttonIconMob:{
    margin: '-1% 0 0 90%'
  },
  iframeMob:{
    margin:'0!important'
  },
}));

export default function ModalBlock(props) {
  
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [option, setOption] = useState("");
  const sourcePolicy = 'http://www.slideshare.net/slideshow/embed_code/key/78NW2yxg5UZMM3';
  const sourceTerms = 'https://www.slideshare.net/slideshow/embed_code/key/B6SfqHFrWhihpr';
  
  const matches = useMediaQuery('(max-width:600px)');
  
  useEffect(() => {
    setOpen(true);
  },[])

  useEffect(() => {
    let el = (props.selection === 0) ? sourcePolicy : sourceTerms
    setOption(el)
    
  },[option])
  
  const handleClose = (event) => {
    setOpen(false);
    
  };
  
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style:{ backgroundColor: `rgb(230 218 218 / 62%)`}
        }}
      >
        <Fade in={open}>
        <div className={`${classes.paper} ${matches ? (`${classes.paperMobile}`) : '' } }`}>
            {/*    <!-- embed responsive iframe --> 
            <!-- ======================= --> */}
            <IconButton className={`${classes.buttonIcon} ${matches ? (`${classes.buttonIconMob}`) : '' } }`} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <div id="Iframe-Master-CC-and-Rs" className={`set-margin set-padding set-border set-box-shadow center-block-horiz ${matches ? (`${classes.iframeMob}`) : '' }`}>
                <div className="responsive-wrapper 
                    responsive-wrapper-wxh-572x612"
                    style={{overflowScrolling: "touch", overflow: "auto"}}>

                    <iframe title='docs-frame' src={option}> 
                    <p style={{fontSize: '110%'}}><em><strong>ERRO: </strong>  
                    &#105;Seu browser talvez não suporte mostrar este item.</em>Por favor, atualize seu navegador e tente novamente.</p>
                    </iframe>
                    
                </div>
            </div>

                
            </div>    
        
        </Fade>
      </Modal>
    </div>
  );

}
