import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import UserProvider from "./providers/UserProvider";
import { BrowserRouter } from 'react-router-dom';


import "./styles.css";
import theme from "./theme";
import Layout from "./Layout";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {

  return (
    <div className="App">
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <UserProvider>
              <ToastContainer />
              <Layout />
            </UserProvider>
          </BrowserRouter>
        </ThemeProvider>
    </div>
  );
}