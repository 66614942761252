import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import avatarImg from '../../../../assets/avatar.jpg';
import { LineScalePulseOut } from 'react-pure-loaders';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import { saveUserPhoto } from '../../../../utils/firebase';
import { myProfilePhoto, myProfileUser, myProfileUserUpdate, updateProfilePhoto } from '../../../../services/user';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Fragment } from 'react';
import { UserContext } from '../../../../providers/UserProvider';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  avatarLg: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    boxShadow: '0px 1px 11px -1px #555',
    marginTop: 20,
  },
  button: {
    margin: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: 700,
  },
  input: {
    backgroundColor: '#fff',
  },
  input_image: {
    display: 'none',
  },
  textPattern: {
    color: '#000000'
  },
}));

export default function PersonalTab(props) {

  const classes = useStyles();
  const user = props.user;
  const photoURL = user.picture?.length > 0 ? user.picture : avatarImg;
  const [photo, setPhoto] = useState(photoURL);
  const [submitting] = useState(false);
  const [edit, setEdit] = useState(false)
  const [name, setName] = useState(user.name || '');
  const [email, setEmail] = useState(user.email || '');
  const [phone, setPhone] = useState(user.phone || '');
  const [address, setAddress] = useState(user.address || '');
  const [number, setNumber] = useState(user.number || '');
  const [district, setDistrict] = useState(user.district || '');
  const [postcode, setPostcode] = useState(user.postcode || '');
  const [CPFCNPJ, setCPFCNPJ] = useState(user.cadastroNacional || '');
  const [newsletter, setNewsletter] = useState(user.newsletter || false);
  const history = useHistory();

  const [errors, setErrors] = useState({})

  const { userState, setPicture } = useContext(UserContext);

  /* const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormData({
       ...formData,
       [name]: value
    });
  }; */

  useEffect(() => {
    const getMyProfile = async () => {
      await myProfileUser()
        .then(response => {
          setName(response.data.name || '')
          setEmail(response.data.email || '')
          setPhone(response.data.phone || '')
          setAddress(response.data.street || '')
          setNumber(response.data.number || '')
          setDistrict(response.data.neighborhood || '')
          setPostcode(response.data.cep || '')
          setCPFCNPJ(response.data.cadastroNacional || '')
          setNewsletter(response.data.newsletter || false)
        })
    }

    getMyProfile()
  }, [])

  const getPhoto = async () => {

    myProfilePhoto()
      .then(res => {
        setPhoto(res.data)
        setPicture(res.data)
      })

  }

  const handleFileChange = async (event) => {
    let file = event.target.files[0];
    /* let fileReader = new FileReader();
    fileReader.readAsDataURL((file));
    fileReader.onloadend = function (e) {
      let dataUrl = e.target.result;
      var base64Data = dataUrl.substr(dataUrl.indexOf('base64,') + 'base64,'.length);
    }  */

    if (file) {

      let formData = new FormData()
      formData.append('file', file)

      updateProfilePhoto(formData)
        .then(res => {
          getPhoto()
        })
        .catch(res => {
          toast.error('Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })


      let result = await saveUserPhoto(user, file);
      setPhoto(result);

    }

  }
  /*
  const handleSubmit = (event) => {
    //Make a network call somewhere
    event.preventDefault();
    props.handleSubmitPersonal(event);
  }
  */

  function validateSignup(data) {

    var errors = {}

    if (userState.isResearcher) {
      if (!data.cadastroNacional || data.cadastroNacional.trim().length === 0)
        errors.CPFCNPJ = 'Campo obrigatório'

      if (!data.cep || data.cep.trim().length === 0)
        errors.postcode = 'Campo obrigatório'

      if (!data.street || data.street.trim().length === 0)
        errors.street = 'Campo obrigatório'

      if (!data.number)
        errors.number = 'Campo obrigatório'

      if (!data.neighborhood || data.neighborhood.trim().length === 0)
        errors.district = 'Campo obrigatório'
    }

    return errors

  }

  const handleSubmitPersonal = async (event) => {
    //Make a network call somewhere
    event.preventDefault();

    const data = {
      name,
      email,
      street: address,
      number,
      phone,
      cep: postcode,
      neighborhood: district,
      cadastroNacional: CPFCNPJ,
      newsletter: newsletter
    }

    const errors = validateSignup(data)
    if (Object.keys(errors).length > 0)
      setErrors(errors)

    else {
      await myProfileUserUpdate(data)
        .then(response => {
          setEdit(false)
          if (response?.data.success) {
            toast.success(response?.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error('Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch(error => {
          setEdit(false)
          toast.error('Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .finally(() => {
          setEdit(false)
        })
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    history.goBack();
  }

  return (

    <Grid container>
      <form onSubmit={(e) => handleSubmitPersonal(e)} noValidate>
        <Grid item xs={12} md={12} lg={12} className={`cardFormCustomRadius paddingTabsCustom`} >
          <Grid container justify='center'>
            <Avatar alt={user.name} src={photo} className={classes.avatarLg} />
          </Grid>
          <Grid container justify='center'>
            <input
              accept="image/*"
              className={classes.input_image}
              id="contained-button-file"
              name="file"
              onChange={handleFileChange}
              type="file"
            />
            <label htmlFor="contained-button-file">
              <Button
                className={classes.button}
                endIcon={<EditIcon />}
                color="secondary"
                component="span"
              >
                Trocar foto
              </Button>
            </label>
          </Grid>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item >&nbsp;</Grid>

            <Grid item>
              <Button
                type="button"
                startIcon={<EditIcon />}
                onClick={e => setEdit(!edit)}
                color={edit ? `primary` : `secondary`}
              >
                Editar
              </Button>
            </Grid>
          </Grid>

          <Grid container alignItems="flex-start" spacing={2}>

            {userState.isResearcher &&
              <Fragment>
                <Grid item xs={6}>
                  <TextField
                    type='number'
                    className={classes.input}
                    variant="outlined"
                    margin="normal"
                    required
                    disabled={edit ? false : true}
                    fullWidth
                    error={errors?.CPFCNPJ}
                    id="CFPCNPJ"
                    value={CPFCNPJ}
                    label={<Typography className={classes.textPattern}>CPF / CNPJ</Typography>}
                    name="CFPCNPJ"
                    inputProps={{
                      maxLength: 14
                    }}
                    onChange={event => setCPFCNPJ(event.target.value)}
                  />
                </Grid>
              </Fragment>
            }

            <Grid item xs={12}>
              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                required
                disabled={edit ? false : true}
                fullWidth
                id="name"
                label={<Typography classes={{ root: clsx(classes.textPattern) }}>Nome</Typography>}
                name="name"
                value={name}
                autoComplete="name"
                onChange={event => setName(event.target.value)}
              />
            </Grid>

            <Grid item xs={12}  >
              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                disabled={edit ? false : true}
                required
                fullWidth
                id="email"
                label={<Typography className={classes.textPattern}>Email</Typography>}
                name="email"
                value={email}
                autoComplete="email"
                onChange={event => setEmail(event.target.value)}
              />
            </Grid>

            <Grid item xs={12}  >
              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                disabled={edit ? false : true}
                required
                fullWidth
                id="phone"
                label={<Typography className={classes.textPattern}>Telefone</Typography>}
                name="phone"
                value={phone}
                autoComplete="phone"
                onChange={event => setPhone(event.target.value)}
              />
            </Grid>
            <Grid item xs={8} md={8} lg={8} >
              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                disabled={edit ? false : true}
                required
                fullWidth
                error={errors?.street}
                id="address"
                label={<Typography className={classes.textPattern}>Endereço</Typography>}
                name="address"
                value={address}
                autoComplete="address"
                onChange={event => setAddress(event.target.value)}
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <TextField
                type='number'
                className={classes.input}
                variant="outlined"
                margin="normal"
                disabled={edit ? false : true}
                required
                fullWidth
                error={errors?.number}
                id="number"
                label={<Typography className={classes.textPattern}>Número</Typography>}
                name="number"
                value={number}
                autoComplete="number"
                inputProps={{
                  maxLength: 8
                }}
                onChange={event => setNumber(event.target.value)}
              />
            </Grid>

            <Grid item xs={7} md={7} lg={7}>
              <TextField
                className={classes.input}
                variant="outlined"
                margin="normal"
                disabled={edit ? false : true}
                required
                fullWidth
                error={errors?.district}
                id="district"
                label={<Typography className={classes.textPattern}>Bairro</Typography>}
                name="district"
                value={district}
                autoComplete="district"
                onChange={event => setDistrict(event.target.value)}
              />
            </Grid>
            <Grid item xs={5} md={5} lg={5}>
              <TextField
                type='number'
                className={classes.input}
                variant="outlined"
                margin="normal"
                disabled={edit ? false : true}
                required
                fullWidth
                error={errors?.postcode}
                id="postcode"
                label={<Typography className={classes.textPattern}>CEP</Typography>}
                name="postcode"
                value={postcode}
                autoComplete="postcode"
                onChange={event => setPostcode(event.target.value)}
              />
            </Grid>
            <Grid item xs={5} md={5} lg={5}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='checkedA'
                    checked={newsletter}
                    onChange={evt => setNewsletter(!newsletter)}
                    color='primary'
                    disabled={edit ? false : true}
                  />
                }
                label={
                  <Typography className={classes.textPattern}>
                    Receber a newsletter
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>


        <Grid item xs={12} md={12} lg={12} className={'footerButtonsTabs'} >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item >
              <Button
                className={'buttonCancel'}
                type="button"
                size='large'
                color="secondary"
                startIcon={<NavigateBeforeIcon />}
                onClick={handleCancel}
              >
                Voltar
              </Button>
            </Grid>

            <Grid item >
              <Button
                type="submit"
                size='large'
                fullWidth
                disabled={edit ? false : true}
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {submitting ? (
                  <LineScalePulseOut
                    color={'#BA7100'}
                    loading={submitting}
                  />
                ) : (
                  "Concluir"
                )}
              </Button>
            </Grid>

          </Grid>
        </Grid>
      </form>

    </Grid>
  )
}