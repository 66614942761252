import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { allWithdraw, batchBankTransfered } from '../../../../services/response';
import moment from 'moment'
import Button from '@material-ui/core/Button';
import { LineScalePulseOut } from 'react-pure-loaders';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  avatarLg: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    boxShadow: '0px 1px 11px -1px #555',
    marginTop: 20,
  },
  button: {
    margin: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: 700,
  },
  input: {
    backgroundColor: '#fff',
  },
  input_image: {
    display: 'none',
  },
  textPattern: {
    color: '#000000'
  },
}));

const columns = [
  { field: 'data', align: 'left', headerName: 'Data', headerClassName: 'reasearchDataGridSmall', width: 150, },
  { field: 'descricao', align: 'left', headerName: 'Descrição', headerClassName: 'reasearchDataGridSmall', flex: 1 },
  { field: 'valor', align: 'left', headerName: 'valor', headerClassName: 'reasearchDataGridSmall', width: 120 },
  { field: 'total_pagar', align: 'left', headerName: 'Total a Pagar', headerClassName: 'reasearchDataGridSmall', width: 180 },
  { field: 'banco', align: 'left', headerName: 'Banco', headerClassName: 'reasearchDataGridSmall', width: 120 },
  { field: 'agencia', align: 'left', headerName: 'Agencia', headerClassName: 'reasearchDataGridSmall', width: 120 },
  { field: 'conta', align: 'left', headerName: 'Conta', headerClassName: 'reasearchDataGridSmall', width: 120 },
  { field: 'pixKey', align: 'left', headerName: 'Chave Pix', headerClassName: 'reasearchDataGridSmall', width: 120 },
  { field: 'pixType', align: 'left', headerName: 'Tipo', headerClassName: 'reasearchDataGridSmall', width: 120 },
  { field: 'pixName', align: 'left', headerName: 'Nome Pix', headerClassName: 'reasearchDataGridSmall', width: 240 }
];

export default function StatementTab(props) {
  const classes = useStyles();
  const [selections, setSelections] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const [data, setData] = useState([])
  const [reload, setReload] = useState(false)

  async function getData() {

    await allWithdraw()
      .then(res => {
        if (res.data.content && res.data.content.length > 0) {
          let result = res.data.content.filter(x => x.rescued === 0)
          result = result.map(item => {
            const data_original = moment.utc(item.requestedAt)
            return {
              id: item.id,
              descricao: `Respondente ${item.userId}`,
              valor_original: item.requested,
              valor: `R$ ${item.requested.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`,
              data: data_original.format('DD/MM/yyyy HH:mm:ss'),
              data_original,
              banco: item.bank,
              agencia: item.agency,
              conta: item.account,
              pixKey: item.pixKey,
              pixName: item.pixName,
              pixType: item.pixType === 'CPF_CNPJ'
                ? 'CPF/CNPJ'
                : item.pixType === 'PHONE'
                  ? 'Telefone'
                  : item.pixType === 'EMAIL'
                    ? 'E-mail'
                    : item.pixType === 'RANDOM'
                      ? 'Aleatório' : ''
            }
          })

          result = result.sort(function (a, b) {
            return a.data_original.valueOf() - b.data_original.valueOf()
          })

          result.forEach((item, index) => {
            let itemAnterior = index == 0 ? { total_original: 0 } : result[index - 1]
            item.total_original = itemAnterior.total_original + item.valor_original
            item.total_pagar = `R$ ${item.total_original.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`
          });

          setData(result)
        }
      })

  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {

    if (reload) {
      getData()
      setReload(false)
    }

  }, [reload])


  const handleSubmit = async () => {
    setSubmitting(true)
    await batchBankTransfered(selections)
      .then(res => {
        toast.success('Transferências confirmadas!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(error => {
        if (error.response?.data?.status !== 400) {
          toast.error('Algo inesperado ocorreu. Por favor, tente atualizar a página em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .finally(() => {
        setSubmitting(false)
        setReload(true)
      })

  }


  return (

    <Grid container>
      <Grid item xs={12} md={12} lg={12} className={`cardFormCustomRadius paddingTabsCustom`} >
        <Box fullWidth>
          <Box className={classes.rootDataGrid}>
            <Box width='100%' style={{ width: '100%', marginTop: 25 }}>
              <DataGrid
                className={classes.containerTable}
                rows={data || []}
                columns={columns}
                hideFooterRowCount={true}
                hideFooterPagination={false}
                hideFooterSelectedRowCount={true}
                checkboxSelection
                disableSelectionOnClick
                disableColumnMenu
                autoHeight={true}
                getRowClassName={() =>
                  `row-actions`
                }
                localeText={{
                  noRowsLabel: "Sem respostas."
                }}
                onSelectionModelChange={(selections) => {
                  setSelections(selections.selectionModel);
                }}
              />
            </Box>

            <Grid item xs={12} md={12} lg={12} className={'footerButtonsTabs'} >
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    disabled={!selections || selections.length == 0}
                    type="submit"
                    size='large'
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    className={classes.submit}
                  >
                    {submitting ? (
                      <LineScalePulseOut
                        color={'#BA7100'}
                        loading={submitting}
                      />
                    ) : (
                      "Confirmar"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid >
  )
}