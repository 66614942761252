import React, { useState } from 'react';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles'; 
import { grey } from '@material-ui/core/colors';


const CustomSwitch = withStyles({
  switchBase: {
    color: grey[200],
    '&$checked': {
      color: `#19afb8`,
    },
    '&$checked + $track': {
      backgroundColor: `#19afb8`,
    },
  },
  checked: {},
  track: {},
})(Switch);


export default function SwitchComponent(props) {
  const [state, setState] = useState({id:props.id,isChecked: props.isChecked, name:props.name, origin:props.origin,onConfirm:props.onConfirm});

  const handleChange = (event) => {
    if(event.target.checked){
      props.onClick(event)
    } 
    setState({ ...state, isChecked: event.target.checked });
    
  };
 
  return (
    <div>
      <CustomSwitch
        id={state.id}
        checked={state.isChecked}
        onChange={handleChange}
        name={state.name}
        inputProps={{ 'aria-label': `${state.origin} checkbox` }}
      />
    </div>
  );
}
