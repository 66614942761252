import { apiResearch } from './api';

export const myDeposits = async () => {
    return await apiResearch.get(`api/researches/myDeposit/`).then(response => {
        return response
    });
}

export const myResearcherWallet = async () => {
    return await apiResearch.get(`api/researches/myResearcherWallet/`).then(response => {
        return response
    });
}

export const myResearcherReport = async () => {
    return await apiResearch.get(`api/researches/myResearcherReport/`).then(response => {
        return response
    });
}

export const researchReport = async (id) => {
    return await apiResearch.get(`api/researches/researchReport/${id}`).then(response => {
        return response
    });
}

export const insertCredits = async (value, payment) => {
    return await apiResearch.post(`api/researches/insertCredits/${value}`, payment).then(response => {
        return response
    });
}

export const insertTransaction = async (transaction) => {
    return await apiResearch.post(`api/researches/insertTransaction/`, transaction).then(response => {
        return response
    });
}

export const generatePixQRCode = async (transaction) => {
    return await apiResearch.post(`api/researches/generatePixQrCode/`, transaction).then(response => {
        return response
    });
}

export const myTransaction = async (id) => {
    return await apiResearch.get(`api/researches/myTransaction/${id}`).then(response => {
        return response
    });
}


export const publishResearch = async (id) => {
    return await apiResearch.put(`api/researches/publish/${id}`).then(response => {
        return response
    });
}

export const createResearch = async (params) => {
    return await apiResearch.post('api/researches', params.data).then(response => {
        return response
    });

}

export const editResearch = async (params) => {
    return await apiResearch.put(`api/researches/${params.uid}`, params.data).then(response => {
        return response
    });
}

export const deleteResearch = async (uid) => {
    return await apiResearch.delete('api/researches', uid).then(response => {
        return response
    });
}

export const getAllResearches = async () => {
    return await apiResearch.get('api/researches').then(response => {
        return response
    });
}

export const getAllResearchesByStatus = async () => {
    return await apiResearch.get('api/researches').then(response => {
        return response
    });
}

export const getAllResearchesByOwner = async () => {
    return await apiResearch.get('api/researches/myresearches').then(response => {
        return response
    });
}

export const getAllResearchesNotExcluded = async () => {
    return await apiResearch.get('api/researches/myresearchesNotExcluded').then(response => {
        return response
    });
}

export const getAllResearchesPublished = async () => {
    return await apiResearch.get('api/researches/allresearchesavailable').then(response => {
        return response
    });
}

export const getAllResearchesStatus = async (status) => {
    return await apiResearch.get('api/researches/allresearches/' + status).then(response => {
        return response
    });
}

export const getByIdResearch = async (uid) => {
    return await apiResearch.get(`api/researches/${uid}`).then(response => {
        return response
    });
}

export const stopMyResearchByIdResearch = async (id) => {
    return await apiResearch.put(`api/researches/stop/${id}`).then(response => {
        return response
    });
}

export const resetMyResearchByIdResearch = async (id) => {
    return await apiResearch.put(`api/researches/restart/${id}`).then(response => {
        return response
    });
}

export const finishMyResearchByIdResearch = async (id) => {
    return await apiResearch.put(`api/researches/finish/${id}`).then(response => {
        return response
    });
}








/* ## CADASTRO DE PESQUISA
#### POST em [url]/api/researches
(possíveis ROLES: <b>ROLE_RESEARCHER</b>)

<br>


#### No header é retornada o endereço da pesquisa cadastrada
![image](/uploads/d2c591ad1c42c6665c0a4e834a43fa4f/image.png)

<br>
<br>

## OBTENDO PESQUISAS
#### GET em [url]/api/researches/
#### GET em [url]/api/researches/{researchId}
#### GET em [url]/api/researches/allresearches/{status}

<br>
<br>

## ATUALIZANDO PESQUISA
(possíveis ROLES: <b>ROLE_RESEARCHER</b>)
<br>
#### PUT em [url]/api/researches/{researchId}

<br>
<br>
## EXCLUINDO PESQUISA
(possíveis ROLES: <b>ROLE_RESEARCHER</b>, <b>ROLE_ADMIN</b>)
<br>
#### DELETE em [url]/api/researches/{researchId}

<br>
<br>

## OBTENDO PESQUISAS DO PESQUISADOR LOGADO
Necessário usar a autenticação de um Pesquisador <b>ROLE_RESEARCHER</b>
#### GET em [url]/api/researches/myresearches
#### GET em [url]/api/researches/myresearches/{status}

<br>
<br>

## PUBLICANDO PESQUISAS DO PESQUISADOR LOGADO
Necessário usar a autenticação de um Pesquisador <b>ROLE_RESEARCHER</b>
#### PUT em [url]/api/researches/publish/{researchId}

<br>
<br>

## PAUSANDO PESQUISAS DO PESQUISADOR LOGADO
Necessário usar a autenticação de um Pesquisador <b>ROLE_RESEARCHER</b>
#### PUT em [url]/api/researches/stop/{researchId}

<br>
<br>

## ENCERRANDO PESQUISAS DO PESQUISADOR LOGADO
Necessário usar a autenticação de um Pesquisador <b>ROLE_RESEARCHER</b>
#### PUT em [url]/api/researches/finish/{researchId}

<br>
<br>
<br>
<br>
 */