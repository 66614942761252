import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PersonIcon from '@material-ui/icons/Person';
import SubjectIcon from '@material-ui/icons/Subject';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PaymentIcon from '@material-ui/icons/Payment';
import SurveyTab from './components/surveyTab';
import FinancialTab from './components/financialTab';
import PublishTab from './components/publishTab';
import PaymentTab from './components/paymentTab';
import { Grid, Paper } from '@material-ui/core';
import { createResearch, publishResearch, myResearcherWallet, getByIdResearch, editResearch } from '../../services/research';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#F0F0F0",
    marginTop: 10,
    padding: 16,
    overflow: 'auto',/* 'overlay', */
  },
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: '25ch',
  },
  avatarLg: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    boxShadow: '0px 1px 11px -1px #555',
    marginBottom: 20,
    marginTop: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    color: '#111640',
    marginBottom: '1em'
  },
  button: {
    margin: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: 700
  },
  input: {

    backgroundColor: '#fff',
  },
  input_image: {
    display: 'none',
  },
  textPattern: {
    color: '#000000'
  },
  divRoot: {
    padding: 10
  },
  /* Styles applied to the `icon` and `label`'s wrapper element. */
  wrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'row',
  },
  textArea: {
    minHeight: `90%`,
    minWidth: `100%`,
    height: `95%!important`,
    width: `100%`,
    maxHeight: `100%`,
    maxWidth: `100%`,
    padding: 5,
    marginTop: theme.spacing(2),
    overflow: "inherit!important"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
    maxWidth: "100%",
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box width="100%!important" p={0} >
          <Typography component={'div'} >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function SurveyCreatePage(props) {
  const classes = useStyles();

  const { id } = useParams();

  const [user] = useState(props.user);
  const [value, setValue] = useState(0);
  const [submitting] = useState(false);
  const [newResearch, setNewResearch] = useState({});
  const [newResearchFinance, setNewResearchFinance] = useState({});
  const [canFinanceTab, setCanFinanceTab] = useState(true);
  const [canPublishTab, setCanPublishTab] = useState(true);
  const [canPaymentTab, setCanPaymentTab] = useState(true)
  const history = useHistory();

  const [paymentRequired, setPaymentRequired] = useState(false);
  const [funds, setFunds] = useState(0);

  useEffect(() => {

    if (id) {
      getByIdResearch(id)
        .then(async (response) => {
          const { data } = response
          setNewResearch({
            name: data.name,
            description: data.description,
            link: data.link
          })

          setNewResearchFinance({
            numRespondents: data.maxRespondents,
            maximumResponseTime: data.maximumResponseTime,
            payAmount: data.payAmount,
            publishedUntil: new Date(data.endDate)
          })
        })
    }

  }, [id])

  const handleChange = (newValue) => {
    if (typeof newValue !== 'number') {
      let id = newValue.currentTarget.id
      newValue = Number(id[id.length - 1])
    }

    if (!newResearchFinance || // API pode estar indisponível ou com erro, nesse caso o retorno é nulo
      (newResearchFinance &&
        newResearchFinance.totalPayAmount &&
        funds < newResearchFinance.totalPayAmount)) {
      setPaymentRequired(true)
    }
    else if (funds > newResearchFinance.totalPayAmount) {
      setPaymentRequired(false)
    }

    setValue(newValue);
  };

  const handleCanFinanceTabChange = () => {
    setCanFinanceTab(false);
  };

  const handleCanPublishTabChange = () => {
    setCanPublishTab(false);
  };

  const handleCanPaymentTabChange = () => {
    setCanPaymentTab(false);
  };


  const handleNewResearchChange = (obj) => {
    setNewResearch(obj);
  };

  const handleNewResearchFinanceChange = (obj) => {
    setNewResearchFinance(obj);
  };

  const getData = () => {
    return {
      name: newResearch.name,
      description: newResearch.description,
      link: newResearch.link,
      maxRespondents: newResearchFinance.numRespondents,
      maximumResponseTime: newResearchFinance.maximumResponseTime,
      payAmount: newResearchFinance.payAmount,
      endDate: newResearchFinance.publishedUntil
    }
  }

  const handlePublish = (event) => {
    const data = getData()

    const method = !!id === false ? createResearch : editResearch
    const params = !!id === false ? { data } : { uid: id, data }

    return method(params)
      .then(async (response) => {
        if (response?.data.success) {
          await publishResearch(id || response.data.id)
            .then(response => {
              toast.success(response?.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              history.push(`/dashboard/ALL`);
            })
            .catch(err => {
              toast.error('Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
        } else {
          toast.error('Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch(error => {
        if (error.response?.data?.status !== 400) {
          toast.error('Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
  }

  const handleCreate = (event) => {
    const data = getData();

    const method = !!id === false ? createResearch : editResearch
    const params = !!id === false ? { data } : { uid: id, data }

    return method(params)
      .then(response => {
        if (response?.data.success) {
          toast.success(response?.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          history.push(`/dashboard/ALL`);
        } else {
          toast.error('Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch(error => {
        if (error.response?.data?.status !== 400) {
          toast.error('Algo inesperado ocorreu. Por favor, tente novamente em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
  }

  useEffect(() => {

    function myWallet() {
      myResearcherWallet()
        .then(res => {
          setFunds(res.data.balance)
        })
    }

    myWallet()

  }, [])

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      xs={12} md={12} lg={12} sm={12}
    >
      <Box component="div" className={'divPaperContent'}>
        <Grid container direction="row" justify="center" alignItems="center" xs={12} md={12} lg={12} sm={12}>
          <Typography component="h3" className={classes.title} color="secondary" >
            {!!id === false ? 'NOVA PESQUISA' : 'EDITAR PESQUISA'}
          </Typography>
        </Grid>

        <Paper elevate={6} style={{ boxShadow: 'none', borderRadius: '10px', backgroundColor: 'transparent' }}>
          <AppBar className={'borderAppBar'} style={{ backgroundColor: '#FFFFFF', boxShadow: 'none', padding: '16px 32px 0 32px' }} position="static">
            <Tabs className="tabsNewReasearch" value={value} onChange={handleChange} aria-label="profile tabs"
              variant="scrollable"
              scrollButtons="auto">
              <Tab style={{ margin: 'auto' }} label={<div><SubjectIcon style={{ marginRight: 5, color: '#576B77', opacity: 1, verticalAlign: 'bottom', display: "inline-block" }} />  Descrição</div>} {...a11yProps(0)} />
              <Tab style={{ margin: 'auto' }} disabled={canFinanceTab} label={<div><PersonIcon style={{ marginRight: 5, color: '#576B77', opacity: 1, verticalAlign: 'bottom', display: "inline-block" }} />  Dados</div>}  {...a11yProps(1)} />
              <Tab style={{ margin: 'auto' }} disabled={canPublishTab} label={<div><PlayArrowIcon style={{ marginRight: 5, color: '#576B77', opacity: 1, verticalAlign: 'bottom', display: "inline-block" }} />  Publicação</div>} {...a11yProps(2)} />
              {paymentRequired && <Tab style={{ margin: 'auto' }} disabled={canPaymentTab} label={<div><PaymentIcon style={{ marginRight: 5, color: '#576B77', opacity: 1, verticalAlign: 'bottom', display: "inline-block" }} />  Pagamento</div>} {...a11yProps(3)} />}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <SurveyTab newResearch={newResearch} handleChange={handleChange} handleNewResearchChange={handleNewResearchChange} handleCanFinanceTabChange={handleCanFinanceTabChange} />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <FinancialTab funds={funds} newResearchFinance={newResearchFinance} handleChange={handleChange} handleNewResearchFinanceChange={handleNewResearchFinanceChange} handleCanPublishTabChange={handleCanPublishTabChange} />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <PublishTab funds={funds} handleCreate={handleCreate} handleUpdate={handleCreate} handlePublish={handlePublish} paymentRequired={paymentRequired} handleChange={handleChange} submitting={submitting} user={user} newResearch={newResearch} newResearchFinance={newResearchFinance} handleCanPaymentTabChange={handleCanPaymentTabChange} />
          </TabPanel>

          {paymentRequired && <TabPanel value={value} index={3}>
            <PaymentTab funds={funds} handlePublish={handlePublish} handleChange={handleChange} submitting={submitting} user={user} newResearch={newResearch} newResearchFinance={newResearchFinance} />
          </TabPanel>}
        </Paper>
      </Box>
    </Grid>
  );
}
