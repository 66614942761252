import { apiAuthURL } from './api';


export const confirmSignup = async (code) => {
    return await apiAuthURL.put(`api/auth/confirmSignup`, { code }).then(response => {
        return response
    });
}

export const askSignUp = async (user) => {
    return await apiAuthURL.post('api/auth/askSignup', user).then(response => {
        return response
    });
}

export const registerUser = async (user) => {
    return await apiAuthURL.post('api/auth/signup', user).then(response => {
        return response
    });
}

export const forgotPassword = async (email) => {
    return await apiAuthURL.post(`api/auth/forgotPassword/${email}`).then(response => {
        return response
    });
}

export const redefineUserPassword = async (data) => {
    return await apiAuthURL.put('api/auth/redefinePassword', data).then(response => {
        return response
    });
}

export const signInUser = async (user) => {
    return await apiAuthURL.post('api/auth/signin', user).then(response => {
        if (response.status === 200) {
            window.localStorage.setItem('isLogged', true)
        }
        return response
    });
}

export const myProfileUser = async () => {
    return await apiAuthURL.get('api/users/myprofile').then(response => {
        return response
    });
}

export const myProfileUserUpdate = async (data) => {
    return await apiAuthURL.put('api/users/myUpdate', data).then(response => {
        return response
    });
}

export const myProfilePhoto = async () => {
    return await apiAuthURL.get('api/users/picture').then(response => {
        return response
    });
}

export const updateProfilePhoto = async (data) => {
    return await apiAuthURL.put('api/users/updatePicture', data, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    }).then(response => {
        return response
    })
}
