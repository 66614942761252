// ********************************************************************
// IMPORTANTE!
//
// FIREBASE FOI REMOVIDO EM 18/08/2022 E SUBSTITUIDO POR LOCALSTORAGE
// TOKEN FAZ O CONTROLE DE ACESSO E AUTENTICAÇÃO
//
// ********************************************************************

import React, { createContext, useEffect, useState } from 'react';
import { myProfilePhoto } from '../services/user';
import { useHistory, useLocation } from 'react-router-dom';
import { useRef } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {

  let query = useQuery()

  const history = useHistory();

  const [user, setUser] = useState(window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null)
  const [isLoading, setIsLoading] = useState(false)

  const setPicture = async (picture) => {
    const data = { ...userState, picture: picture }
    setUserState(data)
    window.localStorage.setItem('user', JSON.stringify(data))
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const login = async (data) => {

    const user = {
      ...data,
      isAuthenticated: true
    }
    window.localStorage.setItem('isLogged', true)
    window.localStorage.setItem('user', JSON.stringify(user))
    setUserState(user)
  }

  const logout = async () => {
    localStorage.removeItem('tabsOpened')
    window.localStorage.removeItem('isLogged')
    window.localStorage.removeItem('user')
    setUserState({
      isAuthenticated: false,
      accessToken: ''
    })
  }

  let initial = user || {
    isAuthenticated: false,
    accessToken: ''
  }

  const [userState, setUserState] = useState(initial)
  const value = { userState, setUserState, login, logout, isLoading, setIsLoading, setPicture }

  useEffect(() => {

    if (userState && userState.isAuthenticated && userState.picture === undefined) {

      myProfilePhoto()
        .then(res => {
          setPicture(res.data)
        })

    }

  }, [userState])

  useEffect(() => {
    const handleInvalidToken = e => {
      if (e.key === 'isLogged' && e.oldValue && !e.newValue) {
        logout()
        history.push('/login')
      }
    }

    window.addEventListener('storage', handleInvalidToken)
    return function cleanup() {
      window.removeEventListener('storage', handleInvalidToken)
    }

  }, [logout])

  function clearStorage() {
    let tabsOpened = localStorage.getItem('tabsOpened')
    if (tabsOpened === null || tabsOpened <= 0) {
      logout()

      const action = query?.get('action')
      if (action === 'signup') {
        history.push(window.location.pathname)
      } else {
        history.push('/login')
      }
    }
    localStorage.setItem('tabsOpened', 1)
  }

  window.addEventListener('load', clearStorage)

  window.addEventListener("beforeunload", function (e) {
    debugger
    let tabsOpened = localStorage.getItem('tabsOpened')
    this.localStorage.setItem('tabsOpened', tabsOpened - 1)
  })

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider;

