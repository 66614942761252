import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactLoading from "react-loading";

const useStyles = makeStyles(theme => ({
	root:{
        height: '100%!important',
        width: '100%!important',
        transform: 'scale(0.12)',
        fill:'#30B6BC!important'
    }
}));

export default function Loading(props) {
	const classes = useStyles();
	
	return (
		
        <ReactLoading className={classes.root} type={"bars"} />
		
	);
}