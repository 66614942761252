
export default {
    type: 'light',
    primary: {
        light: '#ffd48e',
        main: '#ffb743',
        dark: '#ffa412',
        contrastText: '#121949'
    },
    secondary: {
        light: '',
        main: '#0e1223',
        dark: '#313752',
        contrastText: '#fafafa'
    },
    
}