import { grey } from '@material-ui/core/colors';

export default {
    fontFamily: 'Montserrat',
    h2: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1.5,
      color: '#333333'
    },
    h5:{
      fontSize: '1.3rem'
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      color: '#fff'
    },
    body2: {
      fontSize: 16,
      fontWeight: 700,
      color: '#333333'
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 400,
      color: grey[500],
      
    },
}