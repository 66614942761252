import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Graph from './components/graph';
import { useHistory, useParams } from 'react-router-dom';
import { getByIdResearch } from '../../services/research';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: 10,
    overflow: 'auto',/* 'overlay', */
  },
  button: {
    margin: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: 700
  },
  input: {
    
    backgroundColor: '#fff' ,
  },
  textPattern:{
        color: '#000000'
  },
  titleText:{
    fontWeight: 700,
    fontSize: '2rem'
  },
  subTitleText:{
    fontWeight: 700,
    fontSize: '1.3rem'
  },
  text:{
    fontWeight: 400,
    fontSize: 16
  },
  flexCenter:{
    display:"flex",
    alignItems: 'center'
  },
  gridButtons: {
    marginTop: 16,
    flexDirection: 'row',
    '@media(max-width: 1280px)' : {
      flexDirection: 'column-reverse',
    }
  },
  margin: {
    margin: theme.spacing(1),
  },
  iconMoney:{
    color:'rgba(0, 0, 0, 0.54)',
    fontWeight: 700,
    fontSize: '2rem',
    margin: theme.spacing(1),
  },
  iconTime:{
    fontWeight: 700,
    fontSize: '2rem',
    margin: theme.spacing(1),
  } 
}));

export default function QuizPage(props) {
  const classes = useStyles();
  //const [value, setValue] = useState(0);
  const history = useHistory();
  const params = useParams();
  const quiz_id = JSON.parse(params.id)
  const [item, setItem] = useState({});
  
  /*
  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(value)
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  }
  */

  const handleLeave = (event) => {
    event.preventDefault();
    history.goBack();
  }

  const goToResponses = () => {
    history.push(`/survey/${quiz_id}`); 
  }

  const loadItem = async () => {
    await getByIdResearch(quiz_id).then(async (resp) => {
      setItem(resp.data)
     
    }).catch(error => {
      
    });
};
  useEffect(loadItem,[])

  return (
    <div className={classes.root}>
      <div className={classes.root} style={{display:'flex',alignItems: 'center'}}>
          <Typography gutterBottom variant="h5" component="h2" className={classes.titleText}>
              {item.name}
          </Typography>
          
      </div>
      
      <Grid style={{ padding: 20 }} container spacing={3}>
          <Grid 
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={12} md={8} lg={6} >
              <div className={[classes.root,classes.flexCenter]} >
                <Typography gutterBottom variant="subtitle1" component="h5" className={classes.subTitleText}>
                    INSTRUÇÕES
                </Typography>
                
              </div>
              <div className={[classes.root,classes.flexCenter]} >
                <Typography gutterBottom variant="subtitle1" component="span" className={classes.text}>
                    {item.description}
                </Typography>
                
              </div>
            </Grid>

          
            <Grid item xs={12} md={8} lg={6}>
              
              <Grid container justify='center'>
                <div>
                <div> <Graph/> </div>

                <div className={classes.flexCenter}>
                  <MonetizationOnIcon className={classes.iconMoney}/>
                  <Typography variant="body2" component="h5" color="textSecondary" className={classes.subTitleText}>
                      R$ { item.payAmount }
                  </Typography>     
                </div>        
                <div className={classes.flexCenter}>
                  <ScheduleIcon color="primary" className={classes.iconTime}/>
                  <Typography variant="body2" component="h5" color="primary" className={classes.subTitleText}>
                      {item.maximumResponseTime} minutos
                  </Typography>
                </div>
            </div> 
                  
              </Grid>
            </Grid>

          </Grid>
          <Grid
            className={classes.gridButtons}
            container
            justify="space-between"
            alignItems="baseline">

            <Grid item xs={12} md={8} lg={6}>
              <Button
                type="button"
                startIcon={<NavigateBeforeIcon style={{transform: "rotateY(-180deg)"}} />}
                style={ { textTransform: 'none',margin:8,fontSize:"1.3rem" }}
                onClick={handleLeave}
                >
                  Voltar
              </Button>
            </Grid>

            <Grid style={{ display: 'flex' }} item xs={12} md={8} lg={6}>
              <Button
                type="button"
                size='large'
                variant="contained"
                color="primary"
                style={{ margin: 'auto' }}
                className={classes.subTitleText}
                onClick={goToResponses}
              >
                  Avaliar Respostas
                </Button>
            </Grid>
          </Grid> 
      </Grid>
    </div>
  );
}
