import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from "./components/Header";
import InnerHeader from "./components/HeaderBar";
import Footer from "./components/Footer";
import { makeStyles } from '@material-ui/core/styles';
import ReactRouter from './routes';
import { CssBaseline, useMediaQuery } from '@material-ui/core';
import { UserContext } from './providers/UserProvider';
import MenuBurguerHeader from './components/MenuBurguerHeader';



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    minHeight: '80vh',
    height: '100vh',
    maxHeight: '100vh',
    width: '100vw',
    maxWidth: '100vw',
    position: "absolute",
    overflowX: 'unset',
  },
  main: {
    display: 'flex',
    overflow: 'auto',
    backgroundColor: '#F0F0F0',
    height: `calc(100% - ${theme.spacing(14)}px)`,
    maxHeight: `calc(100% - ${theme.spacing(14)}px)`,
    minHeight: `calc(100% - ${theme.spacing(14)}px)`,
    marginBottom: '25px',
    //overflow:'unset',/* 'overlay', */
  }
}))

export default function Layout(props) {
  const classes = useStyles()
  const matches = useMediaQuery('(max-width: 768px)')
  const { userState } = useContext(UserContext)

  // useEffect(() => {
  //     let auth = (window.localStorage.getItem('isLogged') ? (JSON.parse(window.localStorage.getItem('isLogged'))) : (false));
  //     if (userState && (userState.isAuthenticated === auth)) {
  //         setAuthenticated(userState?.isAuthenticated)
  //     }
  // }, [userState, matches]);

  return (
    <div className={classes.root}>

      <CssBaseline></CssBaseline>
      {matches
        ? ((userState?.isAuthenticated
          ? (<MenuBurguerHeader user={userState} />)
          : (null)))
        : (userState?.isAuthenticated
          ? (<InnerHeader user={userState} />)
          : (<Header />))}

      <main className={classes.main}>
        <ReactRouter />
      </main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};
