import React from 'react';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import avatarImg from '../../assets/avatar.jpg';
//TODO: Adcionar ícone dinâmico, linha 68

const StyledBadge = withStyles(theme => ({
	badge: {
		backgroundColor: '#44b700',
		color: '#44b700',
		boxShadow: `0 0 0 1px ${theme.palette.background.default}`,
		'&::after': {
		  position: 'absolute',
		  top: -1,
		  left: -1,
		  width: '100%',
		  height: '100%',
		  borderRadius: '100%',
		  animation: '$ripple 2s infinite ease-in-out',
		  border: '1px solid currentColor',
		  content: '""',
		},
	},
	'@keyframes ripple': {
		'0%': {
		transform: 'scale(.8)',
		opacity: 1,
		},
		'100%': {
		transform: 'scale(2.4)',
		opacity: 0,
		},
	},
	}))(Badge);

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		'& > *': {
		margin: theme.spacing(1),
		},
		transition: '0.2s ease-in-out',
		'&:hover': {
		filter: 'drop-shadow(0px 0px 5px grey)',
		}
	},
	avatar: {
		width: theme.spacing(4),
		height: theme.spacing(4),
		filter: 'drop-shadow(0px 0px 2px black)',
	},
}));

export default function UserAvatar(props) {
	const classes = useStyles();
	const photoURL = props.user.picture?.length > 0 ? props.user.picture : avatarImg
	return (
		<div className={classes.root}>
		<StyledBadge
			overlap="circle"
			anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
			variant="dot"
		>
			<Avatar 
				alt={props.user.name} 
				className={classes.avatar} 
				src={photoURL}
			/>
		</StyledBadge>
		</div>
	);
}