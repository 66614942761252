import React from 'react';
import { makeStyles } from '@material-ui/styles';
import ErrorAwarning from '../../components/ErrorAwarning';
import { Grid } from '@material-ui/core';






const useStyles = makeStyles(theme => ({
    root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		padding: 10,
		overflow: 'auto',/* 'overlay', */
	},
    formControl: {
        marginBottom: theme.spacing(2),
    },
    table: {
		minWidth: 650,
	},
}));

export default function ErrorPage () {
    const classes = useStyles();
   

    return (
	<div className={classes.root}>
      <Grid container spacing={3}>
	  	<Grid item xs={12} md={12} lg={12}  >
		  <ErrorAwarning />
		  </Grid>
	  </Grid>
	  
      
     
    </div>
    );
};