import React /*, { useState }  */from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
/* import { useMediaQuery } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography'; */
import View from './components/modal'
//TODO: Melhorar as mensagens de validação.

const useStyles = makeStyles((theme) => ({
    main: {
       flex:1,
        height:'100%',
        width:'100%',
       
    } ,
    root: {
        padding:0
      
    } ,
    paper: {
        
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
       
      },
    position:{
        position:"relative",
        fontFamily: theme.typography.fontFamily,
        textTransform: 'none',
        '&:hover':{
            backgroundColor: 'unset'
        }
    },
    
    underline:{
        textDecoration: "underline"

    },
    flip:{
        transform: "rotateY(360deg)",
        transition: "transform 0.7s",
    },
    backFlip:{
        transform: "rotateY(-360deg)",
        transition: "transform 0.7s",
    },
    divRegister:{
        textAlign: "center",
        marginTop: theme.spacing(2)
    },
    divRegisterMobile:{
        textAlign: "center",
        margin: "0vh"
    },

}));

/* function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {' © '}
        <Link color="inherit" href="https://easywork.com.br/">
          Sistema de Pesquisa
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  } */


export default function PolicyAndTerms(props) {

    /* const [isSignUp, setSignUp] = useState(false); */
    const classes = useStyles();
    /* const matches = useMediaQuery('(max-width:600px)'); */
    
   /*  const changeContent = () => {
        setSignUp(!isSignUp);
    } */

    return (
        <div className={classes.main}>
            <Container className={classes.root} maxWidth="xs">
                <CssBaseline />
                <View selection={props.value} />
            </Container>
        </div>
    );
}