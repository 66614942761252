import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      fontWeight: 700,
      margin: "auto"
    },
}))

export default function Title(props) {
    const classes = useStyles();

    return (
        <Typography component="h2" variant="h6" color="textSecondary" gutterBottom className={`${classes.root} ${props.classes}`}>
            {props.children}
        </Typography>
    );
}

Title.propTypes = {
  children: PropTypes.node,
};
