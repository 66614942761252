import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewStreamIcon from '@material-ui/icons/ViewStream';
import Title from '../../components/Title';
import Label from './components/label';
import SurveyCard from './components/surveyCard';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getAllResearchesPublished, getAllResearchesNotExcluded } from '../../services/research';
import { toast } from 'react-toastify';
import { getAllResponses } from '../../services/response';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip } from '@material-ui/core';
import Row from '../../components/Row';
import { UserContext } from '../../providers/UserProvider';

//const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%'
  },
  toolbar: {
    paddingRight: 24,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
    overflow: 'auto',/* 'overlay', */
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: '100%',
    height: '100%',
    maxHeight: '100%'
  },
  containerTable: {
    minHeight: 440,
  },
  paper: {
    padding: theme.spacing(1),
    display: 'flex',
    overflow: 'inherit',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  boxData: {
    backgroundColor: '#fafafa',
    overflow: 'auto',

    '&::-webkit-scrollbar': {
      width: '0.4em',
      display: 'block!important'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }
}));

export default function Dashboard(props) {

  const { userState, setUserState } = useContext(UserContext);
  const matchesCard = useMediaQuery('(max-width:960px)');
  const classes = useStyles();
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [viewModeRow, setViewModeRow] = useState(userState.viewModeRow);
  const [page, setPage] = useState(0);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const user = userState
  const status = useParams().status

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleViewMode = (event) => {
    let user = userState
    user.viewModeRow = !userState.viewModeRow
    setUserState({ ...user })
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleChangeItems = (obj) => {
    setItems(obj)
  }

  async function loadItems() {
    if (user?.isResearcher) {
      await getAllResearchesNotExcluded().then(async (resp) => {
        // if (isMountedRef.current) {
        handleChangeItems(resp.data.content)
        // }
        if (resp.data.content.length === 0) {
          history.push('/create-survey')
          toast.info('Você ainda não possui nenhuma pesquisa criada, aproveite e faça agora.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch(error => {
        if (error.response?.data?.status !== 400) {
          toast.error('Algo inesperado ocorreu. Por favor, tente atualizar a página em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    } else {
      if (status === 'ALL') {
        await getAllResponses().then(async (resp) => {
          // if (isMountedRef.current) {
          handleChangeItems(resp.data.content)
          // }
        }).catch(error => {
          if (error.response?.data?.status !== 400) {
            toast.error('Algo inesperado ocorreu. Por favor, tente atualizar a página em alguns minutos.!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      } else {
        if (status === 'PUBLISHED') {
          await getAllResearchesPublished().then(async (resp) => {
            // if (isMountedRef.current) {
            handleChangeItems(resp.data.content)
            // }
          }).catch(error => {
            if (error.response?.data?.status !== 400) {
              toast.error('Algo inesperado ocorreu. Por favor, tente atualizar a página em alguns minutos.!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
        }
      }
    }
  };
  // const isMountedRef = useIsMountedRef();

  // function useIsMountedRef() {
  //   const isMountedRef = useRef(null);
  //   useEffect(() => {
  //     isMountedRef.current = true;
  //     return () => isMountedRef.current = false;
  //   });
  //   return isMountedRef;
  // }

  useEffect(() => {
    if (user && user.isAuthenticated)
      loadItems()
  }, [status, user])

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  const arrayStatus = user.isResearcher ? [
    { title: 'Sua tarefa aguardando a confirmação de pagamento para ser distribuída entre os respondentes.', text: 'Pendentes', type: 'CREATED' },
    { title: 'Seu pagamento foi confirmado e sua tarefa foi disponibilizada entre os respondentes.', text: 'Publicadas', type: 'PUBLISHED' },
    { title: 'Esta tarefa foi interrompida temporariamente pelo pesquisador.', text: 'Pausadas', type: 'STOPPED' },
    { title: 'Esta tarefa foi finalizada pelo pesquisador.', text: 'Finalizadas', type: 'FINISHED' }
  ] : [
    { title: 'Sua tarefa foi iniciada.', text: 'Iniciadas', type: 'STARTED' },
    { title: 'Sua tarefa foi submetida para avaliação.', text: 'Pendentes', type: 'PENDING' },
    { title: 'Sua tarefa foi aprovada.', text: 'Aprovadas', type: 'APPROVED' },
    { title: 'Sua tarefa foi reprovada devido alguma falha ou inconsistência nos dados apresentados.', text: 'Recusadas', type: 'REFUSED' },
  ]

  const columns = [
    { field: 'status', align: 'left', headerName: 'Status', headerClassName: 'reasearchDataGrid', width: 180 },
    { field: 'name', align: 'left', headerName: 'Nome', headerClassName: 'reasearchDataGrid', width: 300, },
    !user.isResearcher ? { field: 'researcherName', align: 'left', headerName: 'Solicitante', headerClassName: 'reasearchDataGrid', width: 300 } : { headerClassName: 'd-none' },
    { field: 'maximumResponseTime', numeric: true, align: 'left', headerName: 'Tempo', headerClassName: 'reasearchDataGrid', width: 180 },
    { field: 'publishPrice', numeric: true, align: 'left', headerName: 'Valor', headerClassName: 'reasearchDataGrid', width: 160 },
    { field: 'endDate', align: 'left', headerName: 'Prazo', headerClassName: 'reasearchDataGrid', width: 180 },
    { field: 'maxRespondents', numeric: true, align: 'left', headerName: 'Ocupação', headerClassName: 'reasearchDataGrid', width: 180 },
    { field: 'id', align: 'left', headerName: 'Ações', headerClassName: 'reasearchDataGrid', width: 300 }
  ];

  if (!user || !user.isAuthenticated)
    return null

  return (
    <div className={classes.root}>
      <main className={classes.content}>

        <Container className={classes.container}>
          <Grid container spacing={3}>
            <Grid
              item
              container
              className={classes.itemsGrid}
              direction="row"
              justify='space-between'
              alignItems="center" xs={12} md={12} lg={12} sm={12}
            >
              <Box display="flex">
                <Title children={!user.isResearcher && status === 'PUBLISHED' ? "PUBLICADAS " : "PESQUISAS"} />
                <Grid>
                  <Tooltip title='Alternar visualização' placement="top">
                    <IconButton onClick={handleViewMode}>
                      {!viewModeRow ? <ViewModuleIcon /> : <ViewStreamIcon />}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Box>
              {status === 'ALL' ? <Label handleChangeItems={handleChangeItems} loadItems={loadItems} items={items} status={status} arrayStatus={arrayStatus} filterProps={user.isResearcher ?
                { PUBLISHED: true, CREATED: true, STOPPED: true, FINISHED: true } :
                { PENDING: true, STARTED: true, APPROVED: true, REFUSED: true }} /> : ''}

              <Box width={'169px'} />
            </Grid>
            <Grid
              item
              container
              direction="row"
              justify='center'
              alignItems="center" xs={12} md={12} lg={12} sm={12}
            >
              <Grid
                container
                direction="row"
                justify={matchesCard ? 'center' : 'flex-start'}
                alignItems="flex-start"
              >
                {!user.viewModeRow ? (
                  <Paper style={{ width: '100%' }}>
                    <TableContainer component={Paper} className={classes.containerTable}>
                      <Table aria-label="collapsible table" stickyHeader={true}>
                        <TableHead>
                          <TableRow>
                            {columns.map((column, index) => (
                              <TableCell
                                key={index}
                                align={column.align}
                                className={column.headerClassName}
                                style={{ minWidth: column.width, maxWidth: column.width }}
                                sortDirection={orderBy === column.field ? order : false}
                              >
                                {column.headerName}
                                <TableSortLabel
                                  active={orderBy === column.field}
                                  direction={orderBy === column.field ? order : 'asc'}
                                  onClick={createSortHandler(column.field)}
                                >
                                  {column.label}
                                  {orderBy === column.field ? (
                                    <span className={classes.visuallyHidden}>
                                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                  ) : null}
                                </TableSortLabel>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stableSort(items, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                              return (
                                <Row key={index} item={row} user={user} status={row.responseStatus || row.status} />
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={items.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Paper>
                )
                  :
                  (
                    items.map((item, index) =>
                    (
                      <SurveyCard
                        marginAuto={false}
                        key={item.id}
                        id={item.researchId || item.id}
                        responseId={item.id}
                        title={item.name || item.researchName}
                        time={item.maximumResponseTime}
                        endDate={item.endDate}
                        createdAt={item.createdAt}
                        accessed={item.accessed}
                        budget={item.payAmount}
                        researcher={item.researcherName}
                        instructions={item.description}
                        status={item.status || item.responseStatus}
                        pathTypo={props.user.isResearcher}
                        email={item.email || ""}
                        reason={item.reason || ""}
                      />
                    )
                    )
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
