import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  leftCard: {
    position: 'absolute',
    top: 20,
    left: 20,
    fontSize: '18px',
    lineHeight: '18px',
    fontWeight: 400,
    color: '#000',
  },
  safeText: {
    fontSize: '25px',
    lineHeight: '25px',
    fontWeight: 600
  },
  rightCard: {
    position: 'absolute',
    top: 20,
    right: 20,
    fontSize: 12,
    color: '#000',
  },
  creditCard: {
    fontWeight: 400
  },
  brand: {
    width: '25%',
    display: 'inline-block',
    marginTop: '10px',
    marginRight: '10px'
  },
  master: {
    width: '15%',
    display: 'inline-block',
    marginTop: '10px',
    marginRight: '10px'
  },
  elo: {
    width: '15%',
    display: 'inline-block',
    marginTop: '10px',
    marginRight: '10px'
  }
}));

export default function SagePayment() {

  const classes = useStyles();

  return <Fragment>
    <div className={classes.leftCard}>
      <label><b>SITE 100%</b></label>
      <br />
      <label className={classes.safeText}>Seguro</label>
      <div>
        <svg style={{
          marginTop: '5px',
          width: '65%',
          aspectRatio: 1,
          height: '65%'
        }} viewBox="-0.126 -0.025 141.901 35.6" preserveAspectRatio="none" data-bbox="-0.126 -0.025 141.901 35.6" xmlns="http://www.w3.org/2000/svg" height="35.55" width="141.73" data-type="color" role="presentation" aria-hidden="true" aria-labelledby="svgcid-4wqmck5ghrdm"><defs><style>#comp-iyu6h1qw svg [data-color="1"] fill: #0092F8;</style></defs><title id="svgcid-4wqmck5ghrdm"></title>
          <g>
            <g>
              <path d="M11.79 33.61a1.53 1.53 0 0 0 3 .82l1.45-5.23H13l-1.21 4.41z" fill="#224099" data-color="1"></path>
              <path d="M1.59 29.2h18.58a7.3 7.3 0 0 0 7-5.34 7.3 7.3 0 0 0-7-9.26h-3.1l-.88 3.18h4a4.13 4.13 0 0 1 4 5.23 4.13 4.13 0 0 1-4 3H1.59a1.59 1.59 0 1 0 0 3.17v.02z" fill="#224099" data-color="1"></path>
              <path fill="#224099" d="m17.07 26.03 2.29-8.25h-3.17l-2.29 8.25h3.17z" data-color="1"></path>
              <path d="M18 1.94a1.53 1.53 0 0 0-3-.82l-1.42 5.23h3.17L18 1.94z" fill="#224099" data-color="1"></path>
              <path d="M28.18 6.35H9.61a7.31 7.31 0 0 0-7 5.35 7.3 7.3 0 0 0 7 9.25h3.09l.88-3.17h-4a4.13 4.13 0 0 1 0-8.26h18.6a1.59 1.59 0 1 0 0-3.17z" fill="#224099" data-color="1"></path>
              <path fill="#224099" d="m12.7 9.52-2.3 8.26h3.18l2.29-8.26H12.7z" data-color="1"></path>
              <path d="M73 26.22c-.94-1.13-1.4-2.93-1.4-5.39q0-4 1.5-5.9A5.5 5.5 0 0 1 77.72 13q5.84 0 5.84 6.63l-.17 1.85a.09.09 0 0 1-.1.09h-9a6.18 6.18 0 0 0 .4 2.39 2.27 2.27 0 0 0 1.2 1.22 5.83 5.83 0 0 0 2.34.37h2c.74 0 1.41-.08 2-.13l.69-.08a.11.11 0 0 1 .12.1v1.86a.12.12 0 0 1-.09.11 33.29 33.29 0 0 1-5.31.55c-2.16-.04-3.73-.6-4.64-1.74zm7.92-6.73a5.59 5.59 0 0 0-.73-3.29 2.86 2.86 0 0 0-2.45-1 3.23 3.23 0 0 0-2.63 1 5.27 5.27 0 0 0-.85 3.25l6.66.04z" fill="#224099" data-color="1"></path>
              <path d="M60.72 25.45a1.31 1.31 0 0 1-.38-.87v-6.89a4.79 4.79 0 0 0-1.15-3.56A5 5 0 0 0 55.53 13a24.48 24.48 0 0 0-5.46.67.11.11 0 0 0-.08.11v1.76a.1.1 0 0 0 .11.1 51.59 51.59 0 0 1 5.43-.33 2.17 2.17 0 0 1 1.61.56 2.55 2.55 0 0 1 .54 1.82v1.11l-4.06.37a6 6 0 0 0-3.46 1.22 4 4 0 0 0-1.09 3.1 4.7 4.7 0 0 0 1.06 3.29 4 4 0 0 0 3.09 1.14 11.61 11.61 0 0 0 4.86-1.11 4.23 4.23 0 0 0 1.51.84 7.09 7.09 0 0 0 2 .27.11.11 0 0 0 .1-.1v-1.89a.1.1 0 0 0-.08-.1 2 2 0 0 1-.89-.38zm-3-.5-.6.2a10.68 10.68 0 0 1-3.38.54c-1.27 0-1.91-.76-1.91-2.29a2.26 2.26 0 0 1 .53-1.64 2.57 2.57 0 0 1 1.64-.67l3.72-.34v4.2z" fill="#224099" data-color="1"></path>
              <path d="M128.08 25.45a1.26 1.26 0 0 1-.38-.87v-6.89a4.83 4.83 0 0 0-1.15-3.56 5 5 0 0 0-3.66-1.13 24.41 24.41 0 0 0-5.46.67.11.11 0 0 0-.08.11v1.76a.1.1 0 0 0 .11.1 51.59 51.59 0 0 1 5.43-.33 2.13 2.13 0 0 1 1.6.56 2.51 2.51 0 0 1 .55 1.82v1.11l-4.06.37a5.94 5.94 0 0 0-3.46 1.22 4 4 0 0 0-1.09 3.1 4.65 4.65 0 0 0 1.06 3.29 4 4 0 0 0 3.09 1.14 11.61 11.61 0 0 0 4.86-1.11 4.23 4.23 0 0 0 1.51.84 7.07 7.07 0 0 0 2 .27.11.11 0 0 0 .11-.1v-1.89a.11.11 0 0 0-.08-.1 1.9 1.9 0 0 1-.9-.38zm-3-.5-.6.2a10.68 10.68 0 0 1-3.38.54c-1.27 0-1.91-.76-1.91-2.29a2.26 2.26 0 0 1 .53-1.64 2.57 2.57 0 0 1 1.64-.67l3.72-.34v4.2z" fill="#224099" data-color="1"></path>
              <path d="M66.72 12.25a5.34 5.34 0 0 1 .36-2.18 1.25 1.25 0 0 1 1-.77 1.78 1.78 0 0 1 .36 0c.33 0 1.15 0 2.46.07a.1.1 0 0 0 .11-.1v-2a.11.11 0 0 0-.09-.11L68 6.94a4.81 4.81 0 0 0-2.36.48A2.79 2.79 0 0 0 64.43 9a10.87 10.87 0 0 0-.37 3.18v1.09h-1.48a.1.1 0 0 0-.1.1v2.13a.1.1 0 0 0 .1.1h1.48v11.9a.11.11 0 0 0 .11.11h2.45a.1.1 0 0 0 .1-.11V15.6h3.76a.11.11 0 0 0 .11-.1v-2.09a.11.11 0 0 0-.11-.1h-3.76v-1.06z" fill="#224099" data-color="1"></path>
              <path d="M47 19.14a4.07 4.07 0 0 0-1.68-1.63 14.05 14.05 0 0 0-3.2-1.14 22.35 22.35 0 0 1-2.94-.84 3.21 3.21 0 0 1-1.42-1 2.77 2.77 0 0 1-.44-1.66c0-1.9 1.3-2.86 3.91-2.86a53.61 53.61 0 0 1 5.55.45.11.11 0 0 0 .12-.1V8.3a.11.11 0 0 0-.09-.1c-1.34-.22-2.43-.38-3.27-.46A23.22 23.22 0 0 0 41 7.59 7.28 7.28 0 0 0 36.16 9a5 5 0 0 0-1.63 4.08 5.62 5.62 0 0 0 .6 2.78 4.5 4.5 0 0 0 1.87 1.7 15.75 15.75 0 0 0 3.6 1.21 16.49 16.49 0 0 1 2.45.7 2.78 2.78 0 0 1 1.27 1 2.68 2.68 0 0 1 .4 1.55q0 3.53-3.86 3.52a46.1 46.1 0 0 1-5.77-.5.11.11 0 0 0-.12.1v2.05a.11.11 0 0 0 .09.1l.65.11a35.34 35.34 0 0 0 5.32.54 6.74 6.74 0 0 0 4.79-1.54 6 6 0 0 0 1.65-4.58 5.69 5.69 0 0 0-.47-2.68z" fill="#224099" data-color="1"></path>
              <path d="M101.54 8a.1.1 0 0 1 .1-.1h7.11a6.65 6.65 0 0 1 4.89 1.6 6.64 6.64 0 0 1 1.6 4.89 7.15 7.15 0 0 1-1.64 5.1 6.41 6.41 0 0 1-4.85 1.73h-4.46v6.25a.11.11 0 0 1-.1.1h-2.55a.11.11 0 0 1-.1-.1V8zm7.18 10.82q3.72 0 3.72-4.43a4.59 4.59 0 0 0-.89-3.13 3.61 3.61 0 0 0-2.83-1h-4.43v8.55l4.43.01z" fill="#224099" data-color="1"></path>
              <path d="M135.24 27.61H133a.1.1 0 0 1-.1-.08l-3.84-14.09a.11.11 0 0 1 .1-.13h2.45a.1.1 0 0 1 .1.08L135 25.32h.86l3.26-11.93a.11.11 0 0 1 .1-.08h2.45a.1.1 0 0 1 .1.13l-5.61 20.33a.1.1 0 0 1-.09.07h-2.47a.1.1 0 0 1-.09-.13l1.73-6.1z" fill="#224099" data-color="1"></path>
              <path d="m89.39 25.23 4.49-4.46a21.47 21.47 0 0 0 2.42-2.56A8.13 8.13 0 0 0 97.53 16a7.35 7.35 0 0 0 .38-2.44 4.89 4.89 0 0 0-1.46-3.91 6.58 6.58 0 0 0-4.4-1.27 21 21 0 0 0-5.41.73.1.1 0 0 0-.08.1v2.08a.11.11 0 0 0 .12.1l.6-.12a21.9 21.9 0 0 1 4.14-.43 4.48 4.48 0 0 1 2.8.7 2.81 2.81 0 0 1 .89 2.33 4.35 4.35 0 0 1-.46 2 8.18 8.18 0 0 1-1.2 1.82c-.49.58-1.21 1.35-2.14 2.3l-5 5.26a.19.19 0 0 0 0 .07v2.18a.1.1 0 0 0 .1.1h11.86a.1.1 0 0 0 .1-.1v-2.17a.1.1 0 0 0-.1-.1h-8.88z" fill="#224099" data-color="1"></path>
            </g>
          </g>
        </svg>
      </div>
    </div>

    <div className={classes.rightCard}>
      <label><b>Opções de pagamento seguro</b></label>
      <br />
      <label className={classes.creditCard}>Cartão de crédito</label>
      <div>
        <img className={classes.brand} src="https://safe2pay.com.br/_nuxt/img/logo-visa.5e287ec.svg"></img>
        <img className={classes.master} src="https://safe2pay.com.br/_nuxt/img/logo-mastercard.96d7f4a.svg"></img>
        <img className={classes.brand} src="https://safe2pay.com.br/_nuxt/img/logo-hipercard.f3ed262.svg"></img>
      </div>
      <div>
        <img className={classes.brand} src="https://safe2pay.com.br/_nuxt/img/logo-american-express.4d0ea9d.svg"></img>
        <img className={classes.elo} src="https://safe2pay.com.br/_nuxt/img/logo-elo.f0eed1b.svg"></img>
        <img className={classes.brand} src="https://safe2pay.com.br/_nuxt/img/logo-diners-club.2705817.svg"></img>
      </div>
      <div>
        <img className={classes.brand} src="https://safe2pay.com.br/_nuxt/img/logo-discover.6a6793c.svg"></img>
      </div>
    </div>
  </Fragment>
}