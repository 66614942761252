import { Box, Button, makeStyles } from '@material-ui/core';
import React from 'react';

//const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    buttonStyle: props => ({
        textTransform: 'none',
        color: '#fafafa',
        backgroundColor: props.bgcolor,
        minWidth:  props.minWidth || '100px',
        padding: '3px',
        
        '&:hover': {
            backgroundColor: props.bgcolor,
        }
    })
}));

export default function FakeButtonStatus(props) {
    const classes = useStyles(props);

    return (
        <Box component="div">
            <Button disableRipple disableElevation disableFocusRipple className={classes.buttonStyle} >
                {props.status}
            </Button>
        </Box>
    )
}