import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box, Paper, AppBar, Tab, Tabs, Button } from '@material-ui/core';
import StatementTab from './components/reports/statement';
import { myResearcherReport } from '../../services/research';
import moment from 'moment';
import { useContext } from 'react';
import { UserContext } from '../../providers/UserProvider';
import { useHistory } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography component={'div'} >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,

};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,

  };
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: 600,
    color: '#111640',
    marginBottom: '1em'
  },
  alertCustom: {
    minWidth: '100%',
    marginBottom: '35px',
    textAlign: 'justify',

    '& .MuiAlert-message': {
      fontSize: '14px',
      fontWeight: 500
    },
    '& .MuiAlert-icon': {
      margin: 'auto 10px auto 0'
    }
  },
  button: {
    margin: '0 auto',
    fontWeight: 600,
    width: 350
  }
}));

export default function FinancialInfoPage(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0)
  const history = useHistory()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { userState } = useContext(UserContext)

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      xs={12} md={12} lg={12} sm={12}
    >
      <Box component="div" className={'relatorioContent'}>
        <Grid direction="row" justify="center" alignItems="center" xs={12} md={12} lg={12} sm={12}>
          <Paper elevate={6} style={{ boxShadow: 'none', maringBottom: '16px', borderRadius: '10px', backgroundColor: 'transparent' }}>
            <AppBar className={'borderAppBar'} style={{ backgroundColor: '#FFFFFF', boxShadow: 'none', padding: '16px 32px 0 32px' }} position="static">
              <Grid container direction="row" xs={12} md={12} lg={12} sm={12}>
                <Grid md='6'>
                  <Typography component="h3" className={classes.title} color="secondary" >
                    Sua Carteira
                  </Typography>
                </Grid>
                {userState.isResearcher && <Grid md='6' justify='flex-end' alignItems='flex-end' style={{
                  textAlign: 'end'
                }}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    style={{
                      marginRight: '15px'
                    }}
                    onClick={() => { history.push('/insert-credits') }}
                  >
                    Inserir créditos
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={() => {

                      myResearcherReport()
                        .then(res => {

                          var textData = 'Data;Descrição;Créditos;Débitos;Saldo\n'

                          let result = res.data.content.map(item => {
                            const data_original = moment.utc(item.financialDate)
                            return {
                              ...item,
                              data_original
                            }
                          })

                          result = result.sort(function (a, b) {
                            return a.data_original.valueOf() - b.data_original.valueOf()
                          })

                          textData += result.map(item => {
                            const creditos = item.credit ? `R$ ${item.credit.toLocaleString('pt-br', { minimumFractionDigits: 2 })}` : ''
                            const debitos = item.debit ? `R$ ${item.debit.toLocaleString('pt-br', { minimumFractionDigits: 2 })}` : ''
                            const saldo = item.balance ? `R$ ${item.balance.toLocaleString('pt-br', { minimumFractionDigits: 2 })}` : ''
                            return `${moment(item.financialDate).format('DD/MM/YYYY')}; ${item.description}; ${creditos}; ${debitos}; ${saldo}`
                          }).join("\n")

                          const uInt = new Uint8Array([0xEF,0xBB,0xBF]);
                          const element = document.createElement("a");
                          const file = new Blob([uInt, textData], { type: 'text/plain;charset=utf8' });
                          element.href = URL.createObjectURL(file);
                          element.download = "financeiro.csv";
                          document.body.appendChild(element); // Required for this to work in FireFox
                          element.click();

                        })
                        .catch(err => {
                        })

                    }}
                  >
                    Download
                  </Button>
                </Grid>}
              </Grid>
              <Tabs value={value} onChange={handleChange} variant='scrollable' aria-label="profile tabs">
                <Tab label="Extrato" {...a11yProps(0)} />
              </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
              <StatementTab />
            </TabPanel>
          </Paper>
        </Grid>
      </Box >
    </Grid >
  );
}
