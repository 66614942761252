import React, {useState} from "react";
import { Box, Button, Collapse, Grid, makeStyles, TableCell, TableRow, Tooltip } from "@material-ui/core";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'; 
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from "moment";
import FakeButtonStatus from "../../pages/dashboard/components/FakeButtonStatus";
import { useHistory } from "react-router";

const useRowStyles = makeStyles({
    rootCollapse: {
      minWidth: '100%',
      backgroundColor: '#FFF5E5'
    },
    paddingCollapse: {
      paddingBottom: 0,
      paddingTop: 0
    },
    textDescription: {
      maxWidth: '1746px',
      wordWrap: 'break-word',
      whiteSpace: 'initial',
      fontWeight: 400
    },
    spanDescription: {
      color: '#333333',
      display: 'inline-block',
      height: 'fit-content'
    },
    button: {
      padding: '3px 12px',
      fontWeight: 600
    },
    width300 :{
      minWidth: '300px',
      maxWidth: '300px'
    },
    width180 :{
      minWidth: '180px',
      maxWidth: '180px'
    },
    width160 :{
      minWidth: '160px',
      maxWidth: '160px'
    }
});

export default function Row(props) {
    const { item, user, status } = props;
    const classes = useRowStyles();
    const history = useHistory();
    const [open, setOpen] = useState(false);

    const handleQuizSelected = (e, quiz_id) => {
      e.preventDefault();

      if(user.isResearcher){
        history.push( `/survey/${quiz_id}`, { quiz_id: quiz_id, stepId: 1 });
      }else{
        history.push( `/survey/${quiz_id}`, status === "PUBLISHED" ? { quiz_id: quiz_id, statusResponse: status, stepId: 0, emailResearcher: props.email, reason: props.reason, created_at: props.createdAt } : status === "STARTED" ? { quiz_id: quiz_id, responseId: props.responseId, statusResponse: status, stepId: props.accessed ? 2 : 1, emailResearcher: props.email, reason: props.reason, created_at: props.createdAt } : status === "PENDING" ? { quiz_id: quiz_id, statusResponse: status, stepId: 3, emailResearcher: props.email, reason: props.reason, created_at: props.createdAt } : status === "EXPIRED" ? {quiz_id: quiz_id, statusResponse: status, stepId: 3, emailResearcher: props.email, created_at: props.createdAt} : {quiz_id: quiz_id, statusResponse: status, stepId: 3, emailResearcher: props.email, reason: props.reason, created_at: props.createdAt});
      }
    };
  
    return (
      <React.Fragment>
        <TableRow hover role="checkbox" tabIndex={-1} key={item.id}>
          <TableCell className={classes.width180}>
            { (item.status || item.responseStatus)  === 'CREATED' ? (
              <React.Fragment>
                  <FakeButtonStatus status="Pendente" bgcolor="#515BA0" />
              </React.Fragment>
            ) : (item.status || item.responseStatus) === 'PUBLISHED' ? (
              <React.Fragment>
                  <FakeButtonStatus status="Publicada" bgcolor="#30B6BC" />
              </React.Fragment>
            ) : (item.status || item.responseStatus) === 'FINISHED' ? (
              <React.Fragment>
                  <FakeButtonStatus status="Finalizada" bgcolor="#199D65" />
              </React.Fragment>
            ) : (item.status || item.responseStatus) === 'STOPPED' ? (
              <React.Fragment>
                  <FakeButtonStatus status="Pausada" bgcolor="#FFB743"  />
              </React.Fragment>
            ) : (item.status || item.responseStatus) === 'STARTED' ? (
              <React.Fragment>
                  <FakeButtonStatus status="Iniciada" bgcolor="#A3A4A5" />
              </React.Fragment>
            ) : (item.status || item.responseStatus) === 'PENDING' ? (
              <React.Fragment>
                  <FakeButtonStatus status="Pendente" bgcolor="#8088BA" />
              </React.Fragment>
            ) : (item.status || item.responseStatus) === 'APPROVED' ? (
              <React.Fragment>
                  <FakeButtonStatus status="Aprovada" bgcolor="#199D65" />
              </React.Fragment>
            ) : (item.status || item.responseStatus) === 'REFUSED' ? (
              <React.Fragment>
                  <FakeButtonStatus status="Recusada" bgcolor="#D16354"  />
              </React.Fragment>
            ) : '' }  
          </TableCell>
          <TableCell className={classes.width300}>
            <Tooltip title={item.name || item.researchName} placement="top">
              <Box className="">{item.name || item.researchName}</Box>
            </Tooltip>
          </TableCell>
          {!user.isResearcher ? <TableCell className={classes.width300}>
            {item.researcherName}
          </TableCell> : ''}
          <TableCell className={classes.width180}>
            {item.maximumResponseTime} min
          </TableCell>
          <TableCell className={classes.width160}>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.payAmount)}
          </TableCell>
          <TableCell className={classes.width180}>
           {moment(item.endDate).format('DD/MM/YYYY')}
          </TableCell>
          <TableCell className={classes.width180}>
           {item.numRespondents}/{item.maxRespondents}
          </TableCell>
          <TableCell className={classes.width300}>
            <React.Fragment>
              <Grid container direction="row" justify="space-between" alignItems="center">
              <Button
                className={classes.button}
                type="button"
                variant="contained"
                endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                onClick={() => setOpen(!open)}
              >
                Ver mais
              </Button>
              <Button
                className={classes.button}
                type="button"
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIosIcon style={{fontSize: 16 }} />}
                onClick={ (e) => handleQuizSelected(e, item.researchId || item.id) }
              >
                Acessar
              </Button>
              </Grid>
            </React.Fragment>
          </TableCell>
        </TableRow>
        <TableRow className={classes.rootCollapse}>
          <TableCell className={classes.paddingCollapse} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={2} className={classes.textDescription}>
                <span className={classes.spanDescription}>Instruções: </span>
                &nbsp; {item.description}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }