import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { UpdateOutlined, TodayOutlined, NavigateNext, NavigateBefore, Link, Close, Check, LinkOutlined, RecordVoiceOverOutlined, MonetizationOnOutlined, GroupOutlined } from '@material-ui/icons';
import { Grid, Box, Button, Dialog, TextareaAutosize, TextField, Typography, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { getByIdResearchResponses, putResponseApprovaRefused, putResponsesMultiple } from '../../../services/response';
import Alert from '@material-ui/lab/Alert';
import { DataGrid } from '@material-ui/data-grid';
import FakeButtonStatus from '../../dashboard/components/FakeButtonStatus';
import { toast } from 'react-toastify';
import moment from 'moment';
import { UserContext } from '../../../providers/UserProvider';
import { Doughnut, defaults } from 'react-chartjs-2';
import Countdown from 'react-countdown';
import { researchReport } from '../../../services/research';
import { apiResponse } from '../../../services/api';

defaults.animation = false;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: 10,
    overflow: 'auto',/* 'overlay', */
  },
  countDownTime: {
    color: '#111640',
    fontSize: '48px',
    fontWeight: 600,
    maxWidth: '100%',
    wordWrap: 'break-word'
  },
  textLinkColor: {
    color: '#1EADB5',
    fontWeight: 500,
    fontSize: '15px',
    cursor: 'pointer',
    userSelect: 'none'
  },
  linkStyle: {
    color: '#1EADB5',
    marginRight: '10px',
  },
  colorGray: {
    color: '#A3A4A5'
  },
  alertCustom: {
    minWidth: '100%',

    '& .MuiAlert-message': {
      fontSize: '14px',
      fontWeight: 500
    },
    '& .MuiAlert-icon': {
      margin: 'auto 10px auto 0'
    }
  },
  titleTextDados: {
    color: '#111640',
    fontSize: '16px',
    fontWeight: 600,
    maxWidth: 'fit-content',
    margin: '0 auto 5px 0'
  },
  iconPattern: {
    color: '#576B77',
    fontWeight: 400,
    fontSize: '18px',
    maxWidth: '100%',
    wordWrap: 'break-word',
    margin: '0 10px 0 0'
  },
  textPattern: {
    textAlign: 'justify',
    color: '#576B77',
    fontWeight: 400,
    fontSize: '14px',
    maxWidth: '100%',
    wordWrap: 'break-word',
    margin: '0 auto 5px 0'
  },
  button: {
    margin: '24px 0 ',
    width: 'fit-content',
    boxShadow: 'none',
    fontWeight: 600,
  },
  buttonGreen: {
    backgroundColor: '#199D65',
    color: '#FFFFFF',
    margin: '24px 0 ',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#199D65',
      color: '#FFFFFF',
    }
  },
  buttonRed: {
    backgroundColor: '#d36a5c',
    color: '#FFFFFF',
    margin: '24px 0 24px 12px ',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#d36a5c',
      color: '#FFFFFF',
    }
  },
  rootDataGrid: {
    width: '100%',
    minHeight: 265,
    maxHeight: 265,
    margin: '0 0 36px 0'
  },
  gridIcon: {
    marginBottom: '12px',
    maxWidth: 'fit-content'
  },
  containerTable: {
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    minHeight: 265,
    maxHeight: 265,

    '& div .MuiDataGrid-footer': {
      display: 'none'
    },

    '& .MuiCheckbox-root svg path': {
      color: '#30b6bc',
    },

    '& .MuiDataGrid-root .MuiDataGrid-iconSeparator': {
      display: 'none'
    },

    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },

    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderRight: '1px solid #e0e0e0'
    },

    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: '1px solid #e0e0e0'
    },

    '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
      borderColor: '#30b6bc',
    }
  },
}));

export function StepTwoFromUserType(props) {
  const classes = useStyles();
  const { userState } = useContext(UserContext);
  const research = props.research
  const [items, setItems] = useState([]);
  const [filtrados, setFiltrados] = useState([]);
  const [selects, setSelections] = useState([]);
  const [open, setOpen] = useState(false);
  const [refusedResponse, setRefusedResponse] = useState(-1);
  const [justification, setJustification] = useState("");
  const ref = useRef();
  let limitCharsJustification = 15
  const [remainCharsJustification, setRemainCharsJustification] = useState(limitCharsJustification);

  useEffect(() => {
    if (justification.length < 16) {
      setRemainCharsJustification(limitCharsJustification - justification.length)
    }
  }, [justification, limitCharsJustification])

  useEffect(() => {
    if (userState.isResearcher) loadItems()
  }, [userState.isResearcher])

  const handleClickOpen = (responseId) => {
    setRefusedResponse(responseId)
    setOpen(true);
  };

  const handleLoadLink = (event) => {
    event.preventDefault()

    window.open(research?.link, '_blank', 'noopener,noreferrer')
  };

  const handleClose = () => {
    setRefusedResponse(-1)
    setOpen(false);
  };

  const loadItems = async () => {
    await getByIdResearchResponses(research?.id).then(async (resp) => {
      setItems(resp.data.content);
      setFiltrados(resp.data.content);
    }).catch(error => {
      if (error.response?.data?.status !== 400) {
        toast.error('Algo inesperado ocorreu. Por favor, tente atualizar a página em alguns minutos.!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }

  const handleCheckApproved = async (e, responseId) => {
    e.preventDefault();

    const data = {
      pattern: true,
      responseId: responseId
    }

    await putResponseApprovaRefused(data)
      .then(response => {
        toast.success('Resposta aprovada!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        loadItems();
      }).catch(error => {
        if (error.response?.data?.status !== 400) {
          toast.error('Algo inesperado ocorreu. Por favor, tente atualizar a página em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
  }

  const handleCheckRefused = async (e) => {
    e.preventDefault();

    const data = {
      pattern: false,
      responseId: refusedResponse,
      reason: justification
    }

    if (justification.length >= 15) {
      await putResponseApprovaRefused(data)
        .then(response => {
          toast.success('Resposta reprovada!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setJustification("")
          loadItems();
          handleClose()
        }).catch(error => {
          if (error.response?.data?.status !== 400) {
            toast.error('Algo inesperado ocorreu. Por favor, tente atualizar a página em alguns minutos.!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
    } else {
      toast.error('Mínimo de 15 caracteres na justificativa.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const handleAction = async (e, pattern) => {
    e.preventDefault();

    const data = {
      pattern: pattern,
      batchResponses: selects,
      reason: justification
    }

    // if(justification.length >= 15) {
    await putResponsesMultiple(data)
      .then(response => {
        if (response.data.success) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setJustification("")
          loadItems();
          handleClose()
        }
      }).catch(error => {
        if (error.response?.data?.status !== 400) {
          toast.error('Algo inesperado ocorreu. Por favor, tente atualizar a página em alguns minutos.!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
    // }else {
    //   toast.error('Mínimo de 15 caracteres na justificativa.', {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  }

  const vagasDisponiveis = research?.maxRespondents - research?.numRespondents

  const state = {
    labels: ['Vagas ocupadas', 'Vagas disponíveis'],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        labels: {
          textAlign: 'start',
          backgroundColor: "blue"
        }
      }
    },
    datasets: [
      {
        textAlign: 'left',
        borderWidth: 0,
        backgroundColor: [
          '#1eadb5',
          '#1EADB53E'
        ],
        hoverBackgroundColor: [
          '#f0f0f0',
          '#f0f0f0'
        ],
        data: [research?.numRespondents, vagasDisponiveis]
      }
    ]
  }

  const columns = [
    {
      field: 'status', align: 'left', headerName: 'Status', headerClassName: 'reasearchDataGridSmall', width: 120,
      renderCell: (params) =>
      (params.value === 'STARTED' ? (
        <React.Fragment>
          <FakeButtonStatus status="Iniciada" bgcolor="#A3A4A5" minWidth="90px" />
        </React.Fragment>
      ) : params.value === 'PENDING' ? (
        <React.Fragment>
          <FakeButtonStatus status="Pendente" bgcolor="#8088BA" minWidth="90px" />
        </React.Fragment>
      ) : params.value === 'APPROVED' ? (
        <React.Fragment>
          <FakeButtonStatus status="Aprovada" bgcolor="#199D65" minWidth="90px" />
        </React.Fragment>
      ) : params.value === 'REFUSED' ? (
        <React.Fragment>
          <FakeButtonStatus status="Recusada" bgcolor="#D16354" minWidth="90px" />
        </React.Fragment>
      ) : '')
    },
    { field: 'respondentId', align: 'left', headerName: 'Respondente', headerClassName: 'reasearchDataGridSmall', width: 180, },
    { field: 'code', align: 'left', headerName: 'Código', headerClassName: 'reasearchDataGridSmall', width: 180 },
    {
      field: 'acoes', align: 'center', headerName: 'Ações', headerClassName: 'reasearchDataGridSmall', width: 104,
      renderCell: (params) =>
        <React.Fragment>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Check style={params.row.status === 'APPROVED' || params.row.status === 'APPROVED' ? { color: '#e0e0e0' } : { color: '#199d65', cursor: 'pointer' }} onClick={params.row.status === 'APPROVED' ? () => ({}) : (e) => handleCheckApproved(e, params.row.id)} />
            <Close style={params.row.status === 'REFUSED' || params.row.status === 'APPROVED' ? { color: '#e0e0e0' } : { color: '#d36a5c', cursor: 'pointer' }} onClick={params.row.status === 'REFUSED' || params.row.status === 'APPROVED' ? () => ({}) : () => handleClickOpen(params.row.id)} />
          </Grid>
        </React.Fragment>
    }
  ];

  return (
    <React.Fragment>

      {/* Modal */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <Box component={'h3'} style={{ color: '#111640', padding: '24px 24px 8px 24px' }}>Justificativa</Box>
        <DialogContent>
          <DialogContentText style={{ color: '#576B77', fontSize: '16px' }}>
            Utilize o campo abaixo para justificar o motivo da recusa das respostas. O respondente receberá essa informação e poderá retornar em seu e-mail.
          </DialogContentText>

          <TextareaAutosize className="textAreaSize" rowsMin={6} minLength={0} maxLength={400} onChange={(e) => setJustification(e.target.value)} />

          <Typography className={classes.colorGray} component="p">
            Necessários {remainCharsJustification} caracteres.
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: "8px 24px 24px" }}>
          <Button
            className={`buttonCancel buttonModal`}
            type="button"
            size='large'
            color="secondary"
            startIcon={<NavigateBefore />}
            onClick={handleClose}
          >
            Voltar
          </Button>
          <Button
            className={`buttonModal`}
            type="button"
            size='large'
            variant="contained"
            color="primary"
            endIcon={<NavigateNext />}
            onClick={refusedResponse === -1 ? e => handleAction(e, false) : e => handleCheckRefused(e)}
            disabled={justification.length === 0}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Modal */}

      {userState?.isResearcher ? (

        <React.Fragment>
          <Grid
            container
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Box component="div" width="100%" marginBottom={'36px'} display="flex">
                {research.status === "STOPPED" ? <Alert className={classes.alertCustom} severity="warning">Esta pesquisa está pausada e não está aceitando respostas.</Alert>
                  : research.status === "FINISHED" ? <Alert className={classes.alertCustom} severity="success">Esta pesquisa já foi finalizada, mas você ainda pode avaliar as respostas abaixo.</Alert> : ''}
              </Box>

              <Box component="div" width="100%" display="flex">
                <Grid item style={{ minWidth: '40%', maxWidth: '40%', marginRight: '1em' }}>
                  <Doughnut
                    data={state}
                    ref={ref}
                    options={{
                      plugins: {
                        legend: false,
                      }
                    }}
                  />
                </Grid>

                <Grid
                  container
                  style={{ minWidth: '60%', maxWidth: '60%' }}
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <Typography className={classes.titleTextDados}>
                    Dados
                  </Typography>

                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.gridIcon}
                  >
                    <RecordVoiceOverOutlined className={classes.iconPattern} />
                    <Typography component="h3" className={classes.textPattern} >
                      {research?.researcherName}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.gridIcon}
                  >
                    <LinkOutlined className={classes.iconPattern} />
                    <Typography component="h3" className={classes.textPattern} >
                      {research?.link}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.gridIcon}
                  >
                    <TodayOutlined className={classes.iconPattern} />
                    <Typography component="h3" className={classes.textPattern} >
                      {moment(research?.endDate).format('DD/MM/YYYY')}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.gridIcon}
                  >
                    <GroupOutlined className={classes.iconPattern} />
                    <Typography component="h3" className={classes.textPattern} >
                      {research?.numRespondents}/{research?.maxRespondents}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.gridIcon}
                  >
                    <MonetizationOnOutlined className={classes.iconPattern} />
                    <Typography component="h3" className={classes.textPattern}>
                      {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(research?.payAmount)}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.gridIcon}
                  >
                    <UpdateOutlined className={classes.iconPattern} />
                    <Typography component="h3" className={classes.textPattern} >
                      {research?.maximumResponseTime} min
                    </Typography>
                  </Grid>

                </Grid>
              </Box>

            </Grid>

            <Grid md='12' justify='flex-end' alignItems='flex-end' style={{
              textAlign: 'end'
            }}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={() => {

                  researchReport(research.id)
                    .then(res => {

                      var textData = 'Data;Descrição;Créditos;Débitos;Saldo;Status\n'

                      let result = res.data.content.map(item => {
                        const data_original = moment.utc(item.financialDate)
                        return {
                          ...item,
                          data_original
                        }
                      })

                      result = result.sort(function (a, b) {
                        return a.data_original.valueOf() - b.data_original.valueOf()
                      })

                      textData += result.map(item => {
                        const creditos = item.credit ? `R$ ${item.credit.toLocaleString('pt-br', { minimumFractionDigits: 2 })}` : ''
                        const debitos = item.debit ? `R$ ${item.debit.toLocaleString('pt-br', { minimumFractionDigits: 2 })}` : ''
                        const saldo = item.balance ? `R$ ${item.balance.toLocaleString('pt-br', { minimumFractionDigits: 2 })}` : ''
                        return `${moment(item.financialDate).format('DD/MM/YYYY')}; ${item.description}; ${creditos}; ${debitos}; ${saldo}; ${item.status}`
                      }).join("\n")

                      const uInt = new Uint8Array([0xEF, 0xBB, 0xBF]);
                      const element = document.createElement("a");
                      const file = new Blob([uInt, textData], { type: 'text/plain;charset=utf8' });
                      element.href = URL.createObjectURL(file);
                      element.download = "financeiro.csv";
                      document.body.appendChild(element); // Required for this to work in FireFox
                      element.click();

                    })
                    .catch(err => {
                    })

                }}
              >
                Baixar relatório
              </Button>
            </Grid>

            <Box width='100%' display='flex' component='div'>
              <Button
                className={classes.buttonGreen}
                type="button"
                size='large'
                fullWidth
                variant="contained"
                onClick={e => handleAction(e, true)}
                disabled={selects.length > 0 ? false : true}
              >
                Aprovar selecionados
              </Button>
              <Button
                className={classes.buttonRed}
                type="button"
                size='large'
                fullWidth
                variant="contained"
                onClick={() => handleClickOpen(-1)}
                disabled={selects.length > 0 ? false : true}
              >
                Reprovar selecionados
              </Button>
            </Box>

            <Typography className={classes.titleTextDados}>
              Filtrar
            </Typography>
            <TextField
              className={classes.input}
              variant="outlined"
              margin="normal"
              inputProps={{ maxLength: 70 }}
              required
              fullWidth
              id="filtrar"
              label={<Typography className={classes.textPattern}>Respondente</Typography>}
              name="filtrar"
              onChange={(e) => {

                if (items && items.length > 0) {
                  let resultados = items.filter(x => x.respondentId.toString().includes(e.target.value));
                  setFiltrados(resultados);
                }

              }}
            />

            <Box width='100%'>
              <Box className={classes.rootDataGrid}>
                <Box style={{ width: '100%' }}>
                  <DataGrid
                    className={classes.containerTable}
                    rows={filtrados}
                    columns={columns}
                    hideFooterRowCount={true}
                    hideFooterPagination={false}
                    hideFooterSelectedRowCount={true}
                    checkboxSelection
                    disableSelectionOnClick
                    disableColumnMenu
                    autoHeight={true}
                    getRowClassName={() =>
                      `row-actions`
                    }
                    localeText={{
                      noRowsLabel: "Sem respostas."
                    }}
                    onSelectionModelChange={(selections) => {
                      setSelections(selections.selectionModel);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment>

          <Grid
            direction="column"
            justify="center"
            alignItems="center"
            container
          >
            <Grid item >
              {props.countdownDate && <Countdown
                date={props.countdownDate}
                daysInHours={true}
                className={classes.countDownTime}
              />
              }
            </Grid>

            <Grid item style={{ margin: '20px auto 16px auto' }}>
              <Typography variant="body2" color="textSecondary" className={classes.textPattern}>
                Insira abaixo o código recebido ao final da pesquisa. Ele irá servir para validar a sua participação para com o pesquisador.
              </Typography>
            </Grid>

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              className={classes.gridIcon}
            >
              <Link className={classes.linkStyle} />
              <Typography variant="body2" color="textSecondary" className={classes.textLinkColor} onClick={(event) => handleLoadLink(event)}>
                Acessar pesquisa novamente
              </Typography>
            </Grid>


            <Grid item className="w-100">
              <TextField
                style={{ margin: '25px 0' }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={props.code}
                label={<Typography className={classes.textPattern}>Código</Typography>}
                onChange={e => props.handleChangeCode(e.target.value)}
              />
            </Grid>

          </Grid>

        </React.Fragment>
      )}
    </React.Fragment>
  )
}