import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { AppBar, Box, Paper, Typography, Grid, Tab, Tabs } from '@material-ui/core'
import CreditCardTab from './creditCard'
import CreditPixTab from './pix';
import { makeStyles } from '@material-ui/core/styles';
import SafePayment from '../safePayment'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography component={'div'} >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,

};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,

  };
}

const useStyles = makeStyles((theme) => ({
  leftCard: {
    position: 'absolute',
    top: 20,
    left: 20,
    fontSize: '18px',
    lineHeight: '18px',
    fontWeight: 400
  },
  safeText: {
    fontSize: '25px',
    lineHeight: '25px',
    fontWeight: 600
  },
  rightCard: {
    position: 'absolute',
    top: 20,
    right: 20,
    fontSize: 12,
  },
  creditCard: {
    fontWeight: 400
  },
  brand: {
    width: '25%',
    display: 'inline-block',
    marginTop: '10px',
    marginRight: '10px'
  },
  master: {
    width: '15%',
    display: 'inline-block',
    marginTop: '10px',
    marginRight: '10px'
  },
  elo: {
    width: '15%',
    display: 'inline-block',
    marginTop: '10px',
    marginRight: '10px'
  }
}));

export default function InsertCredits(props) {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const classes = useStyles()

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      xs={12} md={12} lg={12} sm={12}
    >

      <SafePayment />

      <Box component="div" className={'divPaperContent'}>
        <Paper elevate={6} style={{ boxShadow: 'none', maringBottom: '16px', borderRadius: '10px', backgroundColor: 'transparent' }}>
          <AppBar className={'borderAppBar'} style={{ backgroundColor: '#FFFFFF', boxShadow: 'none', padding: '16px 32px 0 32px' }} position="static">
            <Tabs value={value} variant='scrollable' onChange={handleChange} aria-label="profile tabs">
              <Tab label="Cartão de Crédito" {...a11yProps(0)} />
              <Tab label="Pix" {...a11yProps(1)} />
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0}>
            <CreditCardTab />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CreditPixTab />
          </TabPanel>
        </Paper>
      </Box>
    </Grid>
  );
}
